/*----------------------------------------------------------------------------*/
/*	HOME
/*----------------------------------------------------------------------------*/
body.home main {
	padding-top: 60px;
}
.main-logo-dark {
	opacity: 0;
	animation: mainLogoDark 3s linear;
}
@keyframes mainLogoDark {
	0%   {opacity: 1;}
	19%  {opacity: 1;}
	20%  {opacity: 0;}
	23%  {opacity: 0;}
	24%  {opacity: 1;}
	25%  {opacity: 0;}
	26%  {opacity: 1;}
	27%  {opacity: 1;}
	44%  {opacity: 1;}
	45%  {opacity: 0;}
	59%  {opacity: 0;}
	60%  {opacity: 1;}
	63%  {opacity: 1;}
	64%  {opacity: 0;}
	66%  {opacity: 0;}
	67%  {opacity: 1;}
	69%  {opacity: 1;}
	70%  {opacity: 0;}
	100% {opacity: 0;}
}
.main-logo-half {
	opacity: 0;
	animation: mainLogoHalf 3s linear;
}
@keyframes mainLogoHalf {
	0%   {opacity: 1;}
	44%  {opacity: 1;}
	70%  {opacity: 0;}
	71%  {opacity: 1;}
	95%  {opacity: 0;}
	100% {opacity: 0;}
}
.main-logo-lit {
	opacity: 1;
	animation: mainLogoLit 3s linear;
}
@keyframes mainLogoLit {
	0%   {opacity: 1;}
	100% {opacity: 1;}
}

.home.crushy #hero {
	height: 40vh;
	#logo-home {
		height: 30vh;
		&.logo-main-animated {
			//width: 250px;
			//max-width: 250px;
		}
	}
}
.home #hero {
	position: relative;
	width: 100%;
	height: 95vh;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	background-attachment: scroll;
	-webkit-transition: height 300ms ease;
			transition: height 300ms ease;
	@media all and ( max-width: 700px ) {
		background-attachment: scroll;
		height: 500px;
	}
	#logo-home {
		position: relative;
		text-align: center;
		height: 95vh;
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;
		width: 1000px;
		max-width: 100%;
		margin: 0px auto;
		-webkit-transition: opacity 2000ms linear, height 300ms ease;
				transition: opacity 2000ms linear, height 300ms ease;
		@media all and ( max-width: 700px ) {
			background-attachment: scroll;
			height: 500px;
		}
		&.logo-main-animated {
			//max-width: 500px;
			-webkit-transition: opacity 0ms linear, width 300ms ease;
					transition: opacity 0ms linear, width 300ms ease;
			#logo-lit {
				content: '';
				position: absolute;
				top:0px;left:0;right:0;bottom:0px;
				background-image: url('img/msg-logo-lisa-c1r1.jpg');
				background-position: center;
				background-repeat: no-repeat;
				background-size: contain;
				-webkit-transition: opacity 0ms linear;
						transition: opacity 0ms linear;
			}
			#logo-half {
				content: '';
				position: absolute;
				top:0px;left:0;right:0;bottom:0px;
				background-image: url('img/msg-logo-lisa-c0r1.jpg');
				background-position: center;
				background-repeat: no-repeat;
				background-size: contain;
				-webkit-transition: opacity 0ms linear;
						transition: opacity 0ms linear;
			}
			#logo-dark {
				content: '';
				position: absolute;
				top:0px;left:0;right:0;bottom:0px;
				background-image: url('img/msg-logo-lisa-c0r0.jpg');
				background-position: center;
				background-repeat: no-repeat;
				background-size: contain;
				-webkit-transition: opacity 0ms linear;
						transition: opacity 0ms linear;
			}
		}
		a {
			position: absolute;
			top:0px;left:0;right:0;bottom:0px;
		}
	}

	@-webkit-keyframes goCircle {
		0% { top: 20%; }
		80% { top: 70%; @include transformRotate(0deg); }
		100% { top: 20%; @include transformRotate(360deg); }
	}
	@keyframes goCircle {
		0% { top: 20%; }
		80% { top: 70%; @include transformRotate(0deg); }
		100% { top: 20%; @include transformRotate(360deg); }
	}
	#goCircle {
		cursor: pointer;
	}
	.go-circle {
		position: absolute;
		bottom: 20px;
		left: 50%;
		width: 48px;
		height: 48px;
		margin-left: -24px;
		border: 2px solid $clr_pri1;
		border-radius: 50%;
		background-color: transparent;
		background-color: rgba(0,0,0,0.35);
		@media screen and (max-width: $brk_mobile_lg) {
			display: none;
		}
		&:after {
			content: '';
			height: 9px;
			width: 18px;
			position: absolute;
			top: 40%;
			left: 50%;
			margin-left: -9px;
			background-image: url(img/arrow_dn_blue.png);
			background-position: center center;
			background-repeat: no-repeat;
			background-size: contain;
			-webkit-animation-name: goCircle;
			-webkit-animation-duration: 1.5s;
			-webkit-animation-iteration-count: infinite;
			-webkit-animation-timing-function: ease-out;
			-webkit-animation-fill-mode: forwards;
			animation-name: goCircle;
			animation-duration: 1.5s;
			animation-iteration-count: infinite;
			animation-timing-function: ease-out;
			animation-fill-mode: forwards;
		}
	}
}




.home #about, .home #products, .home #process, .home #signs, .home #socialfeed {
	padding: 60px 0 60px;
	margin: 0;
	.sectionContainer {
		max-width: 100%;
		padding: 0;
		margin: 0;
		.section-arrow {
			text-align: center;
			height: 30px;
			width: 50px;
			display: block;
			margin: 0 auto 12px;
			background-image: url('img/ms_arrow.png');
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
		}
		.section-title {
			max-width: 700px;
			padding: 12px;
			margin: 0 auto;
			text-align: center;
			font: 36px/42px $fnt_heading;
		}
		.section-overview {
			max-width: 700px;
			padding: 12px;
			margin: 0 auto;
			font: 18px/24px $fnt_body;
		}
	}
}
.home #about {
	padding: 60px 0 0;
}
.home #history {
	margin-bottom: 60px;
}
.home #products, .home #signs, .home #socialfeed {
	.blocks-wrapper {
		text-align: center;
		background-color: $clr_white;
		padding: 0.5% 0.5%;
		@media screen and (max-width: 500px) {
			padding: 1% 1%;
		}
		.block-item {
			display: inline-block;
			vertical-align: top;
			position: relative;
			height: 0;
			margin: 0.5%;
			overflow: hidden;
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
			@media screen and (max-width: 500px) {
				margin: 1%;
			}
			.item-overlay {
				position: absolute;
				top:auto;left:0;right:0;bottom:0;
				background-color: rgba(26,117,249,0.85);
				text-decoration: none;
				padding: 6px 12px 6px;
				-webkit-transition: all 300ms ease;
						transition: all 300ms ease;
				@media screen and (max-width: 1600px) {
					padding: 0.375vw 0.75vw 0.375vw;
				}
				@media screen and (max-width: 900px) {
					padding: 0.6666vw 1.3333vw 0.6666vw;
				}
				@media screen and (max-width: 500px) {
					padding: 1.2vw 2.4vw 1.2vw;
				}
				.item-title {
					color: $clr_white;
					text-align: center;
					font-size: 21px;
					line-height: 30px;
					font-weight: 300;
					letter-spacing: 0.05em;
					-webkit-transition: all 300ms ease;
							transition: all 300ms ease;
					@media screen and (max-width: 1600px) {
						font-size: 1.3125vw;
						line-height: 1.875vw;
					}
					@media screen and (max-width: 900px) {
						font-size: 2.3333vw;
						line-height: 3.3333vw;
					}
					@media screen and (max-width: 500px) {
						font-size: 4.2vw;
						line-height: 6vw;
					}
				}
			}
			&:hover {
				.item-overlay {
					.item-title {
						text-shadow: 0 0 6px rgba(0,0,0, 0.5);
					}
				}
			}
		}
	}
}
.home #products {
	.blocks-wrapper {
		.block-item {
			width: 32.333%;
			padding-bottom: 20%;
			@media screen and (max-width: 900px) {
				width: 49%;
				padding-bottom: 30%;
			}
			@media screen and (max-width: 500px) {
				width: 98%;
				padding-bottom: 60%;
			}
			.item-overlay {
				.item-title {
					.item-title-first {
						font-size: 36px;
						line-height: 42px;
						@media screen and (max-width: 1600px) {
							font-size: 2.25vw;
							line-height: 2.625vw;
						}
						@media screen and (max-width: 900px) {
							font-size: 4vw;
							line-height: 4.66666vw;
						}
						@media screen and (max-width: 500px) {
							font-size: 7.2vw;
							line-height: 8.4vw;
						}
					}
					.item-title-rest {
						font-size: 21px;
						line-height: 27px;
						@media screen and (max-width: 1600px) {
							font-size: 1.3125vw;
							line-height: 1.6875vw;
						}
						@media screen and (max-width: 900px) {
							font-size: 2.33333vw;
							line-height: 3vw;
						}
						@media screen and (max-width: 500px) {
							font-size: 4.2vw;
							line-height: 5.4vw;
						}
					}
				}
			}
			&:hover {
				.item-overlay {
					padding: 9px 12px 15px;
					@media screen and (max-width: 1600px) {
						padding: 0.5625vw 0.75vw 0.9375vw;
					}
					@media screen and (max-width: 900px) {
						padding: 1vw 1.33333vw 1.66666vw;
					}
					@media screen and (max-width: 500px) {
						padding: 1.8vw 2.4vw 3vw;
					}
				}
			}
		}
	}
}
.home #signs {
	.blocks-wrapper {
		.block-item {
			width: 24%;
			padding-bottom: 17%;
			@media screen and (max-width: 900px) {
				width: 49%;
				padding-bottom: 30%;
			}
			@media screen and (max-width: 500px) {
				width: 98%;
				padding-bottom: 60%;
			}
			.item-overlay {
				.item-title {
					min-height: 60px;
					@media screen and (max-width: 1600px) {
						min-height: 3.75vw;
					}
					@media screen and (max-width: 900px) {
						min-height: 6.6666vw;
					}
					@media screen and (max-width: 500px) {
						min-height: 12vw;
					}
				}
			}
			&:hover {
				.item-overlay {
					padding: 9px 12px 15px;
					@media screen and (max-width: 1600px) {
						padding: 0.5625vw 0.75vw 0.9375vw;
					}
					@media screen and (max-width: 900px) {
						padding: 1vw 1.33333vw 1.66666vw;
					}
					@media screen and (max-width: 500px) {
						padding: 1.8vw 2.4vw 3vw;
					}
				}
			}
		}
	}
}
.home #socialfeed {
	.blocks-wrapper {
		.block-item {
			width: 24%;
			padding-bottom: 17%;
			@media screen and (max-width: 900px) {
				width: 49%;
				padding-bottom: 30%;
			}
			@media screen and (max-width: 500px) {
				width: 98%;
				padding-bottom: 60%;
			}
			.item-overlay {
				transform: translateY(100%);
				.item-title {

				}
			}
			&:hover {
				.item-overlay {
					transform: translateY(0);
				}
			}
		}
	}
}
.home #process {
	.process-wrapper {
		max-width: 1200px;
		margin: 0 auto;
		padding: 0.5% 4.5%;
		.process-item {
			display: inline-block;
			vertical-align: top;
			text-decoration: none;
			position: relative;
			width: 25.333%;
			margin: 4%;
			@media screen and (max-width: 600px) {
				width: 42%;
			}
			.item-image-wrap {
			    margin: 0 auto;
				width: 240px;
				@media screen and (max-width: 1000px) {
					width: 100%;
				}
				.item-image {
					display: block;
					height: 0;
					padding-bottom: 100%;
					background-position: center;
					background-repeat: no-repeat;
					background-size: cover;
				}
			}
			.item-title {
				text-align: center;
				text-transform: uppercase;
				color: $clr_text;
				font: 30px/42px $fnt_heading;
				text-decoration: none;
				-webkit-transition: all 200ms ease;
						transition: all 200ms ease;
				@media screen and (max-width: 1000px) {
					font: 3vw/4.2vw $fnt_heading;
				}
				@media screen and (max-width: 600px) {
					font: 6vw/8.4vw $fnt_heading;
				}
			}
			&:hover {
				text-decoration: none;
				.item-title {
					text-decoration: none;
					color: $clr_pri1;
				}
			}
		}
	}
}
