/*----------------------------------------------------------------------------*/
/*	POPUP STYLING
/*----------------------------------------------------------------------------*/
.popup-shadow {
	display: none;
	position: fixed;
	top: 0; left: 0;
	width: 100%; height: 100%;
	background: rgb(0,0,0);
	background: rgba(0,0,0,0.75);
	z-index: 7000;
}
.popup-form {
	display: none;
	position: fixed;
	top: 5vh;
	left: 50%;
	max-height: 90vh;
	overflow-x: auto;
	-webkit-transform: translateX(-50%);
	-moz-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	transform: translateX(-50%);
	width: 100%;
	max-width: 700px;
	border: 2px solid $clr_text;
	background: $clr_grey2;
	z-index: 7030;
	box-sizing: border-box;
	padding: 60px 48px;
	text-align: center;
	@media screen and (max-width: $brk_mobile_lg) {
		padding: 60px 24px;
	}
	@media screen and (max-width: 500px) {
		padding: 60px 6px;
	}
	.popup-form-close {
		position: absolute;
		top:12px;
		right:12px;
		cursor: pointer;
		height: 32px;
		width: 32px;
		background-image: url('img/icon-close-64.png');
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;
	}
	.popup-form-logo {
		text-indent: -9999px;
		height: 100px;
		//width: 150px;
		margin: 0 auto 36px;
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;
	}
	.popup-form-intro {
		border-top: 2px solid $clr_grey3;
		border-bottom: 2px solid $clr_grey3;
		padding: 12px 24px;
		margin: 0 24px 24px;
		@media screen and (max-width: $brk_mobile) {
			padding: 12px 6px;
			margin: 0 6px 24px;
		}
		.popup-form-title {
			font: 400 36px/42px $fnt_heading;
			color: $clr_text;
			margin-bottom: 12px;
			@media screen and (max-width: $brk_mobile) {
				font: 400 24px/30px $fnt_heading;
				margin-bottom: 6px;
			}
		}
		.popup-form-subtitle {
			font: 400 21px/27px $fnt_heading;
			color: $clr_text;
			@media screen and (max-width: $brk_mobile) {
				font: 400 18px/24px $fnt_heading;
			}
		}
	}
	.popup-form-pre-content {
		padding: 12px 24px;
		margin: 0 0 24px;
		@media screen and (max-width: $brk_mobile) {
			padding: 12px 6px;
			margin: 0 0 24px;
		}
	}
	.popup-form-content {
		padding: 0 24px;
		margin: 0;
		@media screen and (max-width: $brk_mobile) {
			padding: 0 6px;
			margin: 0;
		}
	}
	.popup-form-extro {
		padding: 12px 24px;
		margin: 24px 0 0;
		@media screen and (max-width: $brk_mobile) {
			padding: 12px 6px;
			margin: 24px 0 0;
		}
	}
	.popup-form-content, .popup-form-pre-content {
		font: $fnt_body_weight 15px/21px $fnt_body;
		color: $clr_text;
		h1 {
			font: 400 26px/30px $fnt_heading;
			margin-bottom: 24px;
		}
		h2 {
			font: 400 22px/26px $fnt_heading;
			margin-bottom: 18px;
		}
		h3 {
			font: 400 18px/22px $fnt_heading;
			margin-bottom: 12px;
		}
		h4 {
			font: 600 22px/30px $fnt_body;
			text-transform: uppercase;
			color: $clr_text;
		}
		h5 {
			font: 600 18px/27px $fnt_body;
			text-transform: uppercase;
			color: $clr_text;
		}
		h6 {
			font: 600 14px/22px $fnt_body;
			text-transform: uppercase;
			color: $clr_text;
			margin-bottom: 6px;
		}
		a {
			color: $clr_text;
			&:hover {
				text-decoration: underline;
			}
		}
		strong {
			font-weight: 700;
		}
		em, i {
			font-family: $fnt_heading;
			font-style: italic;
		}
		ol, ul {
			margin-left: 20px;
			margin-bottom: 18px;
			li {
				margin-bottom: 6px;
			}
		}
		ol {
			list-style: decimal;
			ol {
				list-style: lower-roman;
				ol {
					list-style: lower-alpha;
				}
			}
		}
		ul {
			list-style: square;
			ul {
				list-style: circle;
				ul {
					list-style: disc;

				}
			}
		}
		img {
			max-width: 100%;
			height: auto;
		}
		iframe {
			max-width: 100%;
		}
		.wp-caption {
			max-width: 100%;
		}
	}

}
