/*----------------------------------------------------------------------------*/
/*	CONTENT SECTION
/*----------------------------------------------------------------------------*/
.contents {
	padding: 30px;
	margin: 0 auto;
	max-width: 900px;
	position: relative;
	@media all and ( max-width: $brk_mobile ) {
		padding: 12px;
	}
	img {
		max-width: 50%;
		@media all and ( max-width: 600px ) {
			max-width: 100%;
		}
		&.alignleft { max-width: 30%; margin: 0 .25em .25em 0; }
		&.alignright { max-width: 30%; margin: 0 0 .25em .25em; }
		&.aligncenter { max-width: 30%; }
	}
}
.pre_content, .post_content {
	padding: 30px;
	margin: 0 auto;
	max-width: 900px;
	img {
		max-width: 50%;
		@media all and ( max-width: 600px ) {
			max-width: 100%;
		}
		&.alignleft { max-width: 30%; margin: 0 .25em .25em 0; }
		&.alignright { max-width: 30%; margin: 0 0 .25em .25em; }
		&.aligncenter { max-width: 30%; }
	}
}
.content-cols-wrap {
	text-align: left;
	font: $fnt_body_weight 18px/24px $fnt_body;
	@media all and ( max-width: $brk_desktop ) {
		font: $fnt_body_weight 1.5vw/2vw $fnt_body;
	}
	@media all and ( max-width: $brk_tablet ) {
		font: $fnt_body_weight 14px/18px $fnt_body;
	}
	.content-col-img {
		width: 40%;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
	}
	.content-col-img-left {
		float: left;
		@media all and ( max-width: $brk_mobile_lg ) {
			width: 100%;
			float: none;
		}
	}
	.content-col-img-right {
		float: right;
		@media all and ( max-width: $brk_mobile_lg ) {
			width: 100%;
			float: none;
		}
	}
	.content-col-map {
		width: 40%;
	}
	.content-col-map-lmap {
		float: left;
		@media all and ( max-width: $brk_mobile_lg ) {
			width: 100%;
			float: none;
		}
	}
	.content-col-map-rmap {
		float: right;
		@media all and ( max-width: $brk_mobile_lg ) {
			width: 100%;
			float: none;
		}
	}

	.content-col-info {
		width: 58%;
		padding: 60px 42px 42px;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		overflow: hidden;
		position: relative;
		@media all and ( max-width: $brk_desktop ) {
			padding: 60px 3.5vw 42px;
		}
		@media all and ( max-width: $brk_tablet ) {
			width: 57%;
		}
		@media all and ( max-width: $brk_mobile_lg ) {
			padding: 60px 21px 42px;
			width: 100%;
		}
		&.visibility_dim {
			color: $clr_white;
			a,p{
				color: $clr_white;
			}
			&::before {
				content: '';
				position: absolute;
				top:0;left:0;right:0;bottom:0;
				background: rgba(0,0,0,0.5);
			}
			.container {
				color: $clr_white;
			}
		}
		&.visibility_light {
			&::before {
				content: '';
				position: absolute;
				top:0;left:0;right:0;bottom:0;
				background: rgba(255,255,255,0.65);
			}
		}
		&.motion_fixed {
			background-attachment: fixed;
		}
	}
	.content-col-info-left, .content-col-info-lmap {
		float: right;
	}
	.content-col-info-right, .content-col-info-rmap {
		float: left;
	}
}


/*----------------------------------------------------------------------------*/
/*	COLUMN BLOCKS SECTION
/*----------------------------------------------------------------------------*/
.col-blocks {
	padding: 30px;
	margin: 0 auto;
	max-width: 1100px;
	@media (max-width: 1224px) {
		padding: 0 12px;
	}
}
.col-block {
	width: 100%;
	.col-block-imgs {
		display: inline-block;
		vertical-align: top;
		width: 40%;
		@media (max-width: 700px) {
			width: 100%;
		}
		.col-block-img {
			width: 100%;
			height: auto;
			margin-bottom: 6px;
		}
	}
	.col-block-content {
		display: inline-block;
		vertical-align: top;
		width: 60%;
		padding: 0 12px 0 36px;
		@media (max-width: 700px) {
			width: 100%;
		}
	}
}


/*----------------------------------------------------------------------------*/
/*	GALLERY SECTION
/*----------------------------------------------------------------------------*/
.gallery {

}
.gallery-image {
	margin: 1%;
	height: 0;
	padding-bottom: 23%;
	position: relative;
	width: 23%;
	display: inline-block;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	@media all and ( max-width: 900px ) {
		width: 31%;
		padding-bottom: 31%;
	}
	@media all and ( max-width: 600px ) {
		width: 46%;
		padding-bottom: 46%;
		margin: 2%;
	}
	@media all and ( max-width: 400px ) {
		width: 98%;
		padding-bottom: 98%;
		margin: 1%;
	}
}


/*----------------------------------------------------------------------------*/
/*	SLIDESHOW SECTION
/*----------------------------------------------------------------------------*/
#slideshow {
	height: 600px;
	position: relative;
	overflow: hidden;
	@media screen and (max-width: $brk_desktop) {
		height: 50vw;
	}
	@media screen and (max-width: $brk_mobile) {
		height: 300px;
	}
	#slides {
		position:absolute;
		top:0;left:0;right:0;bottom:0;
		background-color: #444;
		.slide {
			position:absolute;
			top:0;left:0;right:0;bottom:0;
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;
			z-index: 0;
			opacity: 0;
			-webkit-transition: all 800ms ease;
			transition: all 800ms ease;
			&.active {
				z-index: 1;
				opacity: 1;
				-webkit-transition: all 800ms ease;
				transition: all 800ms ease;
			}
		}
	}
	#slide_nav {
		position: absolute;
		z-index: 2;
		bottom: 30px;
		left: 50%;
		margin-left: -540px;
		@media screen and ( max-width: $brk_desktop ) {
			bottom: 2.5vw;
			left: 5vw;
			margin-left: 0;
		}
		@media all and ( max-width: $brk_mobile ) {
			display: none;
		}
		.radio_wrap {
			display: inline-block;
			width: 21px;
			height: 21px;
			border-radius: 50%;
			margin-right: 6px;
			cursor: pointer;
			box-sizing: border-box;
			-webkit-transition: all 200ms ease;
			transition: all 200ms ease;
			border: solid 2px $clr_white;
			background-color: transparent;
			background-color: rgba(0,0,0,0.15);
			&:hover {
				border: solid 2px $clr_white;
				background-color: $clr_pri2;
			}
			&.checked {
				border: solid 2px $clr_white;
				background-color: $clr_pri1;
				&:hover {
					border: solid 2px $clr_white;
					background-color: $clr_pri2;
				}
			}
		}
	}
}
.slide_text {
	position: absolute;
	bottom: 84px;
	text-align: left;
	left: 50%;
	margin-left: -540px;
	max-width: 600px;
	@media screen and ( max-width: $brk_desktop ) {
		bottom: 7vw;
		left: 5vw;
		margin-left: 0;
		max-width: 50vw;
	}
	@media screen and ( max-width: $brk_mobile ) {
		max-width: 300px;
	}
	.slide_title {
		font: 60px/60px $fnt_heading;
		letter-spacing: .025em;
		color: $clr_white;
		text-shadow: 0 0 18px rgba(0,0,0,0.75);
		margin-bottom: 18px;
		@media screen and ( max-width: $brk_desktop ) {
			line-height: 5vw;
			font-size: 5vw;
			text-shadow: 0 0 1.5vw rgba(0,0,0,0.75);
			margin-bottom: 2.5vw;
		}
		@media screen and ( max-width: $brk_mobile ) {
			line-height: 30px;
			font-size: 30px;
			text-shadow: 0 0 9px rgba(0,0,0,0.75);
			margin-bottom: 15px;
		}
	}
	.slide_subtitle {
		font: 30px/36px $fnt_heading;
		letter-spacing: .025em;
		color: #fff;
		text-shadow: 0 0 12px rgba(0,0,0,0.75);
		@media screen and ( max-width: $brk_desktop ) {
			line-height: 3vw;
			font-size: 2.5vw;
			text-shadow: 0 0 1vw rgba(0,0,0,0.75);
		}
		@media screen and ( max-width: $brk_mobile ) {
			line-height: 18px;
			font-size: 15px;
			text-shadow: 0 0 6px rgba(0,0,0,0.75);
		}
		p {
			font: 30px/36px $fnt_heading;
			letter-spacing: .025em;
			color: #fff;
			text-shadow: 0 0 12px rgba(0,0,0,0.75);
			margin-bottom: 18px;
			@media screen and ( max-width: $brk_desktop ) {
				line-height: 3vw;
				font-size: 2.5vw;
				text-shadow: 0 0 1vw rgba(0,0,0,0.75);
				margin-bottom: 1.5vw;
			}
			@media screen and ( max-width: $brk_mobile ) {
				line-height: 18px;
				font-size: 15px;
				text-shadow: 0 0 6px rgba(0,0,0,0.75);
				margin-bottom: 9px;
			}
		}
		a {
			display: inline-block;
			padding: 6px 18px;
			border-radius: 0px;
			line-height: 36px;
			font-size: 30px;
			letter-spacing: .025em;
			font-weight: 700;
			text-decoration: none;
			color: $clr_white;
			background-color: $clr_pri1;
			border: 2px solid $clr_pri2;
			cursor: pointer;
			text-shadow: none;
			-webkit-transition: all 200ms ease;
			transition: all 200ms ease;
			@media screen and ( max-width: $brk_desktop ) {
				padding: 0.5vw 1.5vw;
				border-radius: 4px;
				line-height: 3vw;
				font-size: 2.5vw;
			}
			@media screen and ( max-width: $brk_mobile ) {
				padding: 3px 9px;
				border-radius: 3px;
				line-height: 18px;
				font-size: 15px;
			}
			&:hover {
				color: $clr_pri1;
				background-color: $clr_white;
				text-decoration: none;
			}
		}

	}
	&.use_dark {
		.slide_title, .slide_subtitle, .slide_subtitle p {
			color: $clr_sec1;
			text-shadow: 0 0 12px rgba(255,255,255,0.35);
			@media screen and ( max-width: $brk_desktop ) {
				text-shadow: 0 0 1vw rgba(255,255,255,0.35);
			}
			@media screen and ( max-width: $brk_mobile ) {
				text-shadow: 0 0 6px rgba(255,255,255,0.35);
			}
		}
	}
}
.slide_credit {
	position: absolute;
	bottom: 0px;
	right: 0px;
	margin-left: 200px;
	padding: 18px 18px 18px 38px;
	text-align: right;
	border-top: 1px solid $clr_white;
	background: -moz-linear-gradient(left, rgba(0,0,0,0) 0%, rgba(0,0,0,0.01) 4%, rgba(0,0,0,0.6) 30%, rgba(0,0,0,0.8) 100%);
	background: -webkit-linear-gradient(left, rgba(0,0,0,0) 0%, rgba(0,0,0,0.01) 4%, rgba(0,0,0,0.6) 30%, rgba(0,0,0,0.8) 100%);
	background: linear-gradient(to right, rgba(0,0,0,0) 0%, rgba(0,0,0,0.01) 4%, rgba(0,0,0,0.6) 30%, rgba(0,0,0,0.8) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#cc000000',GradientType=1 );
	@media all and ( max-width: $brk_desktop ) {
		padding: 1.5vw 3vw 1.5vw 1.5vw;
	}
	@media all and ( max-width: $brk_tablet ) {
		padding: 14px 30px 14px 14px;
	}
	@media all and ( max-width: $brk_mobile ) {
		min-width: 200px;
		max-width: 100%;
		margin-right: 0px;
	}
	.slide_credit_text {
		font: $fnt_body_weight 14px/18px $fnt_body;
		color: $clr_white;
		text-align: left;
		text-shadow: 0 1px 12px rgba(0,0,0,.8);
		@media all and ( max-width: $brk_desktop ) {
			font: $fnt_body_weight 1.6666vw/1.5vw $fnt_body;
		}
		@media all and ( max-width: $brk_tablet ) {
			font: $fnt_body_weight 11px/14px $fnt_body;
		}
		a {
			color: $clr_pri1;
			&:hover {
				text-decoration: underline;
			}
		}
		strong {
			font-weight: 700;
		}
		em, i {
			font-family: $fnt_heading;
			font-style: italic;
		}
		ol, ul {
			margin-left: 20px;
			margin-bottom: 18px;
			li {
				margin-bottom: 6px;
			}
		}
		ol {
			list-style: decimal;
			ol {
				list-style: lower-roman;
				ol {
					list-style: lower-alpha;
				}
			}
		}
		ul {
			list-style: square;
			ul {
				list-style: circle;
				ul {
					list-style: disc;

				}
			}
		}
	}
}


/*----------------------------------------------------------------------------*/
/*	GALLERY-SLIDER SECTION
/*----------------------------------------------------------------------------*/
.hero_gallery {
	.slider_for {
		background-color: #444;
		position:relative;
		.slide_img_wrap {
			height: 600px;
			@media all and ( max-width: $brk_desktop ) {
				height: 50vw;
			}
			@media all and ( max-width: $brk_mobile ) {
				height: 300px;
			}
			.slide_img {
				background-position:center;
				background-repeat:no-repeat;
				background-size:cover; // cover or contain
				position:absolute;
				top:0;left:0;right:0;bottom:0;
			}

		}
	}
	.slider_nav {
		background-color: $clr_black;
		position:relative;
		.thumb_img_wrap {
			position:relative;
			height:120px;
			.thumb_img {
				background-position:center;
				background-repeat:no-repeat;
				background-size:cover;
				position:absolute;
				top:0;left:0;right:0;bottom:0;
			}
		}
	}
	.thumb_img {
		-webkit-transition: all 200ms ease;
		transition: all 200ms ease;
		opacity: 0.5;
		&:hover {
			opacity: 1;
		}
	}
	.slick-current .thumb_img {
		opacity: 1;
	}
	.slick-list {
		margin: 0;
	}
	.slick-prev, .slick-next {
		cursor: pointer;
		background-color: rgba(0,0,0,0.5);
		height: 120px;
		width: 40px;
		border-radius: 0%;
		position: absolute;
		top: 50%;
		text-indent: -9999px;
		border: none;
		z-index: 20;
		&.slick-disabled {
			opacity: 0.7;
			cursor: auto;
		}
		&::after {
			font: $fnt_body_weight 24px/20px $fnt_body;
			color: $clr_white;
			position: absolute;
			top: 53%;
			text-indent: 0;
			-webkit-transform: translateY(-50%);
			   -moz-transform: translateY(-50%);
				-ms-transform: translateY(-50%);
					transform: translateY(-50%);
		}
		@media all and ( max-width: $brk_desktop ) {
			height: 6vw;
			width: 6vw;
			&::after {
				font: $fnt_body_weight 2vw/1.66666vw $fnt_body;
			}
		}
		@media all and ( max-width: $brk_tablet ) {
			height: 54px;
			width: 54px;
			&:after {
				font: $fnt_body_weight 18px/15px $fnt_body;
			}
		}
	}
	.slick-prev {
		left: 20px;
		-webkit-transform: translateX(-50%) translateY(-50%);
		   -moz-transform: translateX(-50%) translateY(-50%);
			-ms-transform: translateX(-50%) translateY(-50%);
				transform: translateX(-50%) translateY(-50%);
		&::after {
			content: '–';
			right: 15px;
			@media all and ( max-width: $brk_desktop ) {
				right: 1.25vw;
			}
		}
	}
	.slick-next {
		right: 20px;
		-webkit-transform: translateX(+50%) translateY(-50%);
		   -moz-transform: translateX(+50%) translateY(-50%);
			-ms-transform: translateX(+50%) translateY(-50%);
				transform: translateX(+50%) translateY(-50%);
		&::after {
			content: '+';
			left: 15px;
			@media all and ( max-width: $brk_desktop ) {
				left: 1.25vw;
			}
		}
	}
}


/*----------------------------------------------------------------------------*/
/*	HERO SLIM
/*----------------------------------------------------------------------------*/
.hero_slim {
	position: relative;
	.hero_slim_image {
		width: 100%;
		height: 300px;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		@media all and ( max-width: $brk_tablet ) {
			height: 200px;
		}
	}
	.titleContainer {
		max-width: $brk_desktop;
		margin: 0 auto;
		height: 100%;
		position: relative;
	}
	.hero_slim_title {
		position: absolute;
		top: 70%;
		-webkit-transform: translateY(-50%);
		   -moz-transform: translateY(-50%);
			-ms-transform: translateY(-50%);
				transform: translateY(-50%);
		left: 150px;
		//font: 400 36px/42px $fnt_heading;
		color: $clr_white;
		text-shadow: 0px 0px 12px rgba(0,0,0,0.5);
		text-transform: capitalize;
		@media all and ( max-width: $brk_desktop ) {
			//font: 400 3vw/3.5vw $fnt_heading;
			left: 12.5vw;
			text-shadow: 0 0 1vw rgba(0,0,0,0.25);
		}
		@media all and ( max-width: $brk_tablet ) {
			//font: 400 27px/32px $fnt_heading;
		}
		@media all and ( max-width: $brk_mobile ) {
			left: 5vw;
			text-shadow: 0 0 6px rgba(0,0,0,0.25);
		}
		&.use_dark {
			color: $clr_text_alt;
			text-shadow: 0 0 12px rgba(255,255,255,0.35);
			@media screen and ( max-width: $brk_desktop ) {
				text-shadow: 0 0 1vw rgba(255,255,255,0.35);
			}
			@media screen and ( max-width: $brk_mobile ) {
				text-shadow: 0 0 6px rgba(255,255,255,0.35);
			}
		}
	}
}


/*----------------------------------------------------------------------------*/
/*	VIDEO SECTION
/*----------------------------------------------------------------------------*/
.videos {
	text-align: center;
	.video_wrap {
		margin: 1%;
		height: 0;
		position: relative;
		//width: 31.333%;
		//padding-bottom: 17.625%;
		width: 48%;
		padding-bottom: 27%;
		display: inline-block;
		@media all and ( max-width: $brk_tablet ) {
			width: 48%;
			padding-bottom: 27%;
		}
		@media all and ( max-width: $brk_mobile ) {
			width: 98%;
			padding-bottom: 56.25%;
		}
		.video {
			display: block;
			position: absolute;
			top:0;left:0;right:0;bottom:0;
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
			.video_title {
				position: absolute;
				top: 24px;
				left: 24px;
				right: 24px;
				text-align: center;
				color: #fff;
				font: 18px/24px $fnt_heading;
				text-transform: uppercase;
				letter-spacing: 0.1em;
				text-shadow: 0 0 12px rgba(0,0,0,0.85);
				@media all and ( max-width: $brk_desktop ) {
					top: 2vw;
					left: 2vw;
					right: 2vw;
					font: 1.5vw/2vw $fnt_heading;
				}
				@media all and ( max-width: $brk_tablet ) {
					top: 3vw;
					left: 3vw;
					right: 3vw;
					font: 2.5vw/3vw $fnt_heading;
				}
				@media all and ( max-width: $brk_mobile ) {
					top: 24px;
					left: 24px;
					right: 24px;
					font: 18px/24px $fnt_heading;
				}
			}
		}
	}
	.video_icon {
		position: absolute;
		height: 82px;
		width: 82px;
		bottom: 12px;
		right: 12px;
		border-radius: 50%;
		background-color: $clr_black;
		background-image: url('img/icon_play.png');
		background-position: center;
		background-repeat: no-repeat;
		background-size: 84px 84px;
		cursor: pointer;
		opacity: 0.5;
		-webkit-transition: all 400ms ease;
		transition: all 400ms ease;
		@media all and ( max-width: $brk_desktop ) {
			height: 7vw;
			width: 7vw;
			bottom: 1vw;
			right: 1vw;
			background-size: 7vw 7vw;
		}
		@media all and ( max-width: $brk_tablet ) {
			height: 64px;
			width: 64px;
			bottom: 9px;
			right: 9px;
			background-size: 64px 64px;
		}
	}
	.video.play_video:hover .video_icon {
		opacity: 0.9;
		background-color: $clr_black;
		-webkit-transition: all 600ms ease;
		transition: all 600ms ease;
	}
	&.testimonial-showcase-video {
		max-width: 500px;
		margin: 0 auto 30px;
		.video_wrap {
			width: 98%;
			padding-bottom: 56.25%;

			@media all and ( max-width: $brk_tablet ) {
				width: 98%;
				padding-bottom: 56.25%;
			}
			@media all and ( max-width: $brk_mobile ) {
				width: 98%;
				padding-bottom: 56.25%;
			}
			.video {

				.video_title {
					position: absolute;
					top: 24px;
					left: 24px;
					right: 24px;
					text-align: center;
					color: #fff;
					font: 24px/36px $fnt_heading;
					text-transform: uppercase;
					letter-spacing: 0.1em;
					text-shadow: 0 0 12px rgba(0,0,0,0.85);
					@media all and ( max-width: $brk_desktop ) {
						top: 2vw;
						left: 2vw;
						right: 2vw;
						font: 2vw/3vw $fnt_heading;
					}
					@media all and ( max-width: $brk_mobile ) {
						top: 24px;
						left: 24px;
						right: 24px;
						font: 18px/24px $fnt_heading;
					}
				}
			}
		}
	}
}


/*----------------------------------------------------------------------------*/
/*	FAQ SECTION
/*----------------------------------------------------------------------------*/
.faqs {
	@media all and ( max-width: 1224px ) {
		padding: 0 12px;
	}
	.faq-set {
		text-align: left;
		min-height: 160px;
		position: relative;
		margin-bottom: 24px;
		.faq-thumb-wrap {
			position: absolute;
			top:0; left:0;
			height: 160px;
			width: 200px;
			border: 3px solid $clr_pri1;
			@media all and ( max-width: $brk_mobile_lg ) {
				height: 80px;
				width: 100px;
			}
			.faq-thumb {
				position: absolute;
				top:0; left:0; right:0; bottom:0;
				background-position: center;
				background-repeat: no-repeat;
				background-size: cover;
				box-shadow: inset 0px 0px 36px rgba(0,0,0,0.5);
			}
		}
		.faq-letter {
			display: table-cell;
			font: $fnt_body_weight 24px/30px $fnt_body;
			color: $clr_pri1;
			padding-right: 10px;
		}
		.faq-detail {
			display: table-cell;
		}
		.faq-question {
			padding-left: 220px;
			padding-right: 220px;
			@media all and ( max-width: $brk_tablet ) {
				padding-right: 0px;
			}
			@media all and ( max-width: $brk_mobile_lg ) {
				padding-left: 110px;
			}
		}
		.faq-answer {
			padding-left: 220px;
			padding-right: 220px;
			@media all and ( max-width: $brk_tablet ) {
				padding-right: 0px;
			}
			@media all and ( max-width: $brk_mobile_lg ) {
				padding-left: 110px;
			}
			a {
				color: $clr_pri1;
				&:hover {
					text-decoration: underline;
				}
			}
			strong {
				font-weight: 700;
			}
			em, i {
				font-family: $fnt_heading;
				font-style: italic;
			}
			ol, ul {
				margin-left: 20px;
				margin-bottom: 18px;
				li {
					margin-bottom: 6px;
				}
			}
			ol {
				list-style: decimal;
				ol {
					list-style: lower-roman;
					ol {
						list-style: lower-alpha;
					}
				}
			}
			ul {
				list-style: square;
				ul {
					list-style: circle;
					ul {
						list-style: disc;

					}
				}
			}
		}
	}
}


/*----------------------------------------------------------------------------*/
/*	INFO REPEATER SECTION
/*----------------------------------------------------------------------------*/
.infos {
	max-width: 900px;
	margin: 0 auto;
	@media all and ( max-width: 1224px ) {
		padding: 0 12px;
	}
	.info-set {
		text-align: left;
		position: relative;
		margin-bottom: 24px;
		.info-set-left {
			display: inline-block;
			vertical-align: top;
			width: 30%;
			padding: 0 12px;
			text-align: right;
			.info-title {
				font-weight: 700;
			}
			.info-thumb-wrap {
				position: relative;
				height: 160px;
				width: 100%;
				max-width: 200px;
				border: 3px solid $clr_pri1;
			    margin-right: 0;
				margin-left: auto;
				@media all and ( max-width: $brk_mobile_lg ) {
					height: 80px;
					max-width: 100px;
				}
				.info-thumb {
					position: absolute;
					top:0; left:0; right:0; bottom:0;
					background-position: center;
					background-repeat: no-repeat;
					background-size: cover;
					box-shadow: inset 0px 0px 36px rgba(0,0,0,0.5);
				}
			}
		}
		.info-set-right {
			display: inline-block;
			vertical-align: top;
			width: 70%;
			padding: 0 12px;
			.info-content {
				
				@media all and ( max-width: $brk_tablet ) {
					
				}
				@media all and ( max-width: $brk_mobile_lg ) {
					
				}
				a {
					color: $clr_pri1;
					&:hover {
						text-decoration: underline;
					}
				}
				strong {
					font-weight: 700;
				}
				em, i {
					font-family: $fnt_heading;
					font-style: italic;
				}
				ol, ul {
					margin-left: 20px;
					margin-bottom: 18px;
					li {
						margin-bottom: 6px;
					}
				}
				ol {
					list-style: decimal;
					ol {
						list-style: lower-roman;
						ol {
							list-style: lower-alpha;
						}
					}
				}
				ul {
					list-style: square;
					ul {
						list-style: circle;
						ul {
							list-style: disc;

						}
					}
				}
			}
		}
	}
}


/*----------------------------------------------------------------------------*/
/*	QUOTE SECTION
/*----------------------------------------------------------------------------*/
.quote_img_start{
	background:url('img/quote-long.png') center no-repeat;
	height: 43px;
	margin: 20px auto 0;
}
.quote_img_end{
	background:url('img/quote-end.png') center no-repeat;
	height: 27px;
	margin: -27px auto 0;
}
.quote_content {
	padding: 60px 0 75px;
	//max-width: 900px;
	margin: 0 auto;
	box-sizing: content-box;
	overflow: hidden;
	@media screen and (max-width: $brk_mobile_lg) {
		padding: 40px 0 115px 0;
	}
	.quote_slide {
		padding: 0 16%;
		@media screen and (max-width: $brk_mobile_lg) {
			padding: 0 30px;
		}
	}
	.quote_copy {
		font: $fnt_body_weight 16px/25px $fnt_body;
		font-style: italic;
		letter-spacing: 0.02em;
		margin-bottom: 20px;
		//text-shadow: 0px 6px 6px rgba(0,0,0,0.4);
	}
	.quote_name {
		font: 400 16px/25px $fnt_heading;
		letter-spacing: 0.02em;
		text-transform: uppercase;
		margin-bottom: 3px;
		text-align: right;
		//text-shadow: 0px 6px 6px rgba(0,0,0,0.4);
	}
}
.quote_slides {
	position: relative;
	.quote_slide {
		position: absolute;
		left: 100%;
		width: 100%;
		&.current {
			left: 0;
			-webkit-transition: all 300ms ease;
			transition: all 300ms ease;
		}
		&.prev-left {
			left: -100%;
			-webkit-transition: all 300ms ease;
			transition: all 300ms ease;
		}
		&.prev-right {
			left: 100%;
			-webkit-transition: all 300ms ease;
			transition: all 300ms ease;
		}
		&.before-left {
			left: -100%;
		}
	}
	.quote_prev, .quote_next {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 27px;
		background-repeat: no-repeat;
		background-size: 28px 48px;
		cursor:pointer;
		@media screen and (max-width: $brk_mobile_lg) {
			bottom: 40px;
		}
		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-repeat: no-repeat;
			background-size: 28px 48px;
			opacity: 1;
			-webkit-transition: all 300ms ease;
			transition: all 300ms ease;
			@media screen and (max-width: $brk_mobile_lg) {
				opacity: 0;
			}
		}
		&:hover:after {
			opacity: 0;
		}
	}
	.quote_prev {
		right: 50%;
		background-image: url('img/arrow_left-hov.png');
		background-position: 12% center;
		@media screen and (max-width: $brk_mobile_lg) {
			background-position: 80% 100%;
		}
		&:after {
			background-image: url('img/arrow_left.png');
			background-position: 12% center;
			@media screen and (max-width: $brk_mobile_lg) {
				background-position: 80% 100%;
			}
		}
	}
	.quote_next {
		left: 50%;
		background-image: url('img/arrow_right-hov.png');
		background-position: 88% center;
		@media screen and (max-width: $brk_mobile_lg) {
			background-position: 20% 100%;
		}
		&:after {
			background-image: url('img/arrow_right.png');
			background-position: 88% center;
			@media screen and (max-width: $brk_mobile_lg) {
				background-position: 20% 100%;
			}
		}
	}
}


/*----------------------------------------------------------------------------*/
/*	SHOP SECTION
/*----------------------------------------------------------------------------*/
.store-group {
	margin-bottom: 24px;
}
.store-item {
	list-style: none;
	display: inline-block;
	width: 25%;
	padding: 0 12px;
	margin-bottom: 24px;
	box-sizing: border-box;
	@media (max-width: $brk_tablet) {
		width: 33.3333%;
	}
	@media screen and (max-width: $brk_mobile){
		width: 50%;
	}
	@media screen and (max-width: 400px){
		width: 100%;
	}
}


/*----------------------------------------------------------------------------*/
/*	FLIP CARDS SECTION
/*----------------------------------------------------------------------------*/
.benefit-cards {
	max-width: 1000px;
	margin: 0 auto;
}
.benefit {
	display: inline-block;
	vertical-align: top;
	width: 21%;
	height: 264px;
	margin: 2%;
	position: relative;
	text-align: center;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	@media screen and (max-width: 950px) {
		width: 29.333%;
		margin: 2%;
	}
	@media screen and (max-width: 750px) {
		width: 46%;
		margin: 4% 2%;
	}
	@media screen and (max-width: 450px) {
		width: 98%;
		margin: 4% 1%;
	}
	.card-front {
		position: absolute;
		top:0;left:0;right:0;bottom:0;
		height: 264px;
		width: 98%;
		max-width: 240px;
		margin: 0 auto;
		border-radius: 0px;
		padding: 216px 0 0 0;
		vertical-align: top;
		color: $clr_text;
		font: 400 21px/24px $fnt_heading;
		letter-spacing: 0.025em;
		text-transform: uppercase;
		-webkit-transition: all 150ms ease 150ms;
				transition: all 150ms ease 150ms;
		.benefit-icon {
			width: 200px;
			height: 200px;
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
			background-color: $clr_white;
			border-radius: 20px;
			border: 2px solid $clr_grey1;
			position: absolute;
			top: 4px;
			left: 50%;
			box-sizing: border-box;
			-webkit-box-shadow: inset 0 0 30px rgba(0,0,0,0.5);
			   -moz-box-shadow: inset 0 0 30px rgba(0,0,0,0.5);
					box-shadow: inset 0 0 30px rgba(0,0,0,0.5);
			-webkit-transform: translateX(-50%);
			   -moz-transform: translateX(-50%);
				-ms-transform: translateX(-50%);
					transform: translateX(-50%);
		}
		&::before {
			//content: '';
			width: 208px;
			height: 208px;
			border-radius: 25px;
			border: 4px solid $clr_white;
			position: absolute;
			top: 0px;
			left: 50%;
			box-sizing: border-box;
			-webkit-transform: translateX(-50%);
			   -moz-transform: translateX(-50%);
				-ms-transform: translateX(-50%);
					transform: translateX(-50%);
		}
	}
	.card-back {
		opacity: 0;
		border: 2px solid #3a3a3a;
		position: absolute;
		top:0;left:0;right:0;bottom:0;
		height: 264px;
		width: 98%;
		max-width: 240px;
		margin: 0 auto;
		border-radius: 10px;
		padding: 12px;
		vertical-align: top;
		background-color: $clr_white;
		color: $clr_text_alt;
		letter-spacing: 0.025em;
		-webkit-transition: all 150ms ease 150ms;
				transition: all 150ms ease 150ms;
		.card-back-title {
			font: 700 18px/21px $fnt_heading;

		}
		.card-back-desc {
			//text-align: left;
			font: $fnt_body_weight 13px/17px $fnt_body;
		}
		a {
			font: 400 14px/18px $fnt_body;
			display: block;
			letter-spacing: 0.05em;
			color: $clr_sec1;
			text-transform: uppercase;
			margin-top: 10px;
			text-decoration: none;
			//text-align: right;
			-webkit-transition: all 200ms ease;
			transition: all 200ms ease;
			&:hover {
				color: $clr_black;
				text-decoration: underline;
			}
		}
	}
}
.flipcard {
	-webkit-perspective: 1000;
	-moz-perspective: 1000;
	-o-perspective: 1000;
	perspective: 1000;
}
.flipcard:hover .flipper, .flipcard.hover .flipper {
	-webkit-transform: rotateY(-180deg);
	-moz-transform: rotateY(-180deg);
	-o-transform: rotateY(-180deg);
	transform: rotateY(-180deg);
	.card-front {
		opacity: 0;
		visibility: hidden;
		-webkit-transition: all 150ms ease 150ms;
				transition: all 150ms ease 150ms;
	}
	.card-back {
		opacity: 1;
		z-index: 100;
	}
}
.flipper {
	position: relative;
	-webkit-transition: 0.6s;
	-moz-transition: 0.6s;
	-o-transition: 0.6s;
	transition: 0.6s;
	-webkit-transform-style: preserve-3d;
	-moz-transform-style: preserve-3d;
	-o-transform-style: preserve-3d;
	transform-style: preserve-3d;
}
.card-front, .card-back {
	-webkit-backface-visibility: visible;
	-moz-backface-visibility: visible;
	-ms-backface-visibility: visible;
	-o-backface-visibility: visible;
	backface-visibility: visible;
}
.card-front {
	z-index: 2;
	-webkit-transform: rotate(0deg);
	-moz-transform: rotate(0deg);
	-ms-transform: rotate(0deg);
	-o-transform: rotate(0deg);
	transform: rotate(0deg);
}
.card-back {
	-webkit-transform: rotateY(180deg);
	-moz-transform: rotateY(180deg);
	-ms-transform: rotateY(180deg);
	-o-transform: rotateY(180deg);
	transform: rotateY(180deg);
}


/*----------------------------------------------------------------------------*/
/*	MAP SECTION
/*----------------------------------------------------------------------------*/
#mapWrapper {
	border: 1px double $clr_grey3;
}
#googleMap {
	height: 300px;
}
#googleInfo {
	font: $fnt_body_weight 15px/24px $fnt_body;
	color: $clr_text;
	line-height: 23px;
	text-align: center;
	padding: 25px 5px 10px 5px;
	width: 200px;
}
#googleInfo span {
	display: block;
	font: 400 15px/24px $fnt_heading;
}
#googleInfo a {
	display: block;
	font: 400 15px/24px $fnt_body;
	text-transform: uppercase;
	text-decoration: none;
	color: $clr_pri1;
	margin-top: 10px;
}
#googleMap img {
	-webkit-filter: grayscale(1);
	filter: grayscale(1);
	-webkit-transition: all .3s ease;
	transition: all .3s ease;
	margin-bottom: 1%;
}
#address-block {
	height: 40px;
	text-align: center;
	background-color: $clr_sec1;
	color: $clr_white;
	font: $fnt_body_weight 18px/24px $fnt_body;
	padding: 8px 20px;
}
section.map-section {
	padding: 0;
}


/*----------------------------------------------------------------------------*/
/*	TESTIMONIAL SECTION
/*----------------------------------------------------------------------------*/
#testimonials {
	overflow: hidden;
	transition: all .2s ease;
	.test_img_start{
		background:url('img/quote-long.png') center no-repeat;
		height: 43px;
		margin: 20px auto 0;
	}
	.test_img_end{
		background:url('img/quote-end.png') center no-repeat;
		height: 27px;
		margin: -27px auto 0;
	}
	.content {
		padding: 60px 0 75px;
		max-width: 900px;
		margin: 0 auto;
		box-sizing: content-box;
		overflow: hidden;
		@media screen and (max-width: $brk_mobile_lg) {
			padding: 40px 0 115px 0;
		}
		.test_slide {
			padding: 0 16%;
			@media screen and (max-width: $brk_mobile_lg) {
				padding: 0 30px;
			}
		}
		.test_copy {
			font-style: italic;
			margin-bottom: 20px;
			font-weight: $fnt_body_weight;
		}
		.test_name {
			font: 400 16px/25px $fnt_body;
			letter-spacing: 0.02em;
			text-transform: uppercase;
			margin-bottom: 3px;
			text-align: right;
		}
	}
}
.test_slides {
	position: relative;
	.test_slide {
		position: absolute;
		left: 100%;
		width: 100%;
		&.current {
			left: 0;
			-webkit-transition: all 300ms ease;
			transition: all 300ms ease;
		}
		&.prev-left {
			left: -100%;
			-webkit-transition: all 300ms ease;
			transition: all 300ms ease;
		}
		&.prev-right {
			left: 100%;
			-webkit-transition: all 300ms ease;
			transition: all 300ms ease;
		}
		&.before-left {
			left: -100%;
		}
	}
	.test_prev, .test_next {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 27px;
		background-repeat: no-repeat;
		background-size: 28px 48px;
		cursor:pointer;
		@media screen and (max-width: $brk_mobile_lg) {
			bottom: 40px;
		}
		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-repeat: no-repeat;
			background-size: 28px 48px;
			opacity: 1;
			-webkit-transition: all 300ms ease;
			transition: all 300ms ease;
			@media screen and (max-width: $brk_mobile_lg) {
				opacity: 0;
			}
		}
		&:hover:after {
			opacity: 0;
		}
	}
	.test_prev {
		right: 50%;
		background-image: url('img/arrow_left-hov.png');
		background-position: 12% center;
		@media screen and (max-width: $brk_mobile_lg) {
			background-position: 80% 100%;
		}
		&:after {
			background-image: url('img/arrow_left.png');
			background-position: 12% center;
			@media screen and (max-width: $brk_mobile_lg) {
				background-position: 80% 100%;
			}
		}
	}
	.test_next {
		left: 50%;
		background-image: url('img/arrow_right-hov.png');
		background-position: 88% center;
		@media screen and (max-width: $brk_mobile_lg) {
			background-position: 20% 100%;

		}
		&:after {
			background-image: url('img/arrow_right.png');
			background-position: 88% center;
			@media screen and (max-width: $brk_mobile_lg) {
				background-position: 20% 100%;
			}
		}
	}
}


/*----------------------------------------------------------------------------*/
/*	LINK-LIST SECTION
/*----------------------------------------------------------------------------*/
.page-link-list-wrap {
	padding: 0 30px;
	.link-list {

		.link-list-title {
			text-align: center;
			font: 400 24px/36px $fnt_heading;
			margin-bottom: 12px;
		}
		ul {
			list-style: none;
			margin: 0;
			padding: 0;
			-webkit-column-count: 3; /* Chrome, Safari, Opera */
			-moz-column-count: 3; /* Firefox */
			column-count: 3;
			@media screen and (max-width: $brk_mobile_lg) {
				-webkit-column-count: 2; /* Chrome, Safari, Opera */
				-moz-column-count: 2; /* Firefox */
				column-count: 2;
			}
			@media screen and (max-width: $brk_mobile) {
				-webkit-column-count: 1; /* Chrome, Safari, Opera */
				-moz-column-count: 1; /* Firefox */
				column-count: 1;
			}
			li {
				margin: 0;
				padding: 0;
				a {
					font: 400 16px/36px $fnt_heading;
					display: block;
					padding: 0 12px;
					color: $clr_text;
					background-color: transparent;
					text-decoration: none;
					-webkit-transition: all 200ms ease;
					transition: all 200ms ease;
					&:hover {
						background-color: $clr_white;
						color: $clr_pri1;
					}
				}
			}
		}
	}
}


