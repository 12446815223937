/*----------------------------------------------------------------------------*/
/*	HEADER
/*----------------------------------------------------------------------------*/
.home header {
	//position: relative;
	//top: auto;
	// @media screen and (max-width: $brk_mobile_lg) {
	// 	position: fixed;
	// 	top: 0;
	// }
}
.home.stickNav header {
	//position: fixed;
	//top: 0;
}
header {
	position: fixed;
	top:0;
	height: 60px;
	width: 100%;
	text-align: center;
	background-color: $clr_sitebg;
	z-index: 1000;
	@media screen and (max-width: $brk_mobile_lg) {
		background-color: rgba(0,0,0,0.65);
		height: 60px;
	}
	.headerContainer {
		position: relative;
		max-width: $brk_desktop;
		margin: 0 auto;
		overflow: hidden;
		padding: 0 24px;
		@media screen and (max-width: $brk_desktop) {
			padding: 0 2vw;
		}
		@media screen and (max-width: $brk_tablet) {
			padding: 0;
		}
	}
}
#nav {
	display: inline-block;
	vertical-align: top;
	list-style: none;
	margin-left: 0;
	margin-bottom: 0;
	text-align: center;
	@media screen and (max-width: $brk_mobile_lg) {
		display: none;
	}
	.menu-item {
		display: inline-block;
		a {
			color: $clr_white;
			display: block;
			font-family: $fnt_heading;
			font-size: 18px;
			line-height: 60px;
			padding: 0 30px;
			text-decoration: none;
			@media screen and (max-width: $brk_desktop) {
				padding: 0 20px;
			}
			@media screen and (max-width: $brk_tablet) {
				padding: 0 9px;
			}
			&:hover {
				color: $clr_pri1;
				text-decoration: underline;
				text-shadow: none;
			}
		}
		// &.current-menu-item {
		// 	a {
		// 		color: $clr_pri2;
		// 		text-shadow: none;
		// 	}
		// }
	}
}
#mobile-logo {
	display: inline-block;
	vertical-align: top;
	margin-left: 0px;
	margin-top: 5px;
	height: 50px;
	width: 88px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	-webkit-transition: all 200ms ease;
			transition: all 200ms ease;
	@media screen and (max-width: $brk_mobile_lg) {
		display: block;
		margin-left: 24px;
	}
}
.home #mobile-logo {
	-webkit-transform: translateY(-100px);
	   -moz-transform: translateY(-100px);
		-ms-transform: translateY(-100px);
			transform: translateY(-100px);
}
.home.homeLogo #mobile-logo {
	-webkit-transform: translateY(0px);
	   -moz-transform: translateY(0px);
		-ms-transform: translateY(0px);
			transform: translateY(0px);
}
