/*----------------------------------------------------------------------------*/
/*	FOOTER
/*----------------------------------------------------------------------------*/
footer {
	width: 100%;
	font-size: 12px;
	background-color: $clr_black;
	.footerContainer {
		position: relative;
		max-width: $brk_desktop;
		margin: 0 auto;
		overflow: hidden;
		padding: 24px;
		@media screen and (max-width: $brk_desktop) {
			padding: 24px 2vw;
		}
	}
	.footer-logo {
		width: 100%;
		max-width: 500px;
		height: 295px;
		margin: 30px auto 12px;
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;
	}
	.footer-info {
		text-align: center;
		margin-bottom: 24px;
		a {
			font: 400 14px/24px $fnt_body;
			padding: 3px 12px;
			display: inline-block;
			text-decoration: none;
			color: $clr_white;
			-webkit-transition: all 200ms ease;
			transition: all 200ms ease;
			&:hover {
				color: $clr_pri1;
			}
		}
	}
	.footer-left {
		width: 50%;
		float: left;
		text-align: left;
		padding-top: 5px;
		color: $clr_grey3;
		@media screen and (max-width: $brk_mobile_lg) {
			width: 100%;
			float: none;
			text-align: center;
		}
	}
	.footer-right {
		width: 50%;
		float: right;
		text-align: right;
		@media screen and (max-width: $brk_mobile_lg) {
			width: 100%;
			float: none;
			text-align: center;
			margin-bottom: 12px;
		}
	}
}

