/*----------------------------------------------------------------------------*/
/*	BLOG
/*----------------------------------------------------------------------------*/
body.blog #content,
body.archive #content {
	border-bottom: 1px solid $clr_sec1;
}
body.blog #content,
body.archive #content,
body.search #content,
body.single-post #content {
	padding-top: 0px;
}
.blog-hero-wrap {
	width: 100%;
	height: 400px;
	position: relative;
	z-index: 0;
	@media only screen and (max-width: 768px) {
		height: 300px;
	}
	.blog-hero-image {
		position: absolute;
		top:0;left:0;right:0;bottom:0;
		background-position: center top;
		background-repeat: no-repeat;
		background-size: 100% auto;
		background-attachment: fixed;
		z-index: 1;
		@media only screen and (max-width: 1224px) {
			background-size: auto 400px;
		}
		@media only screen and (max-width: 768px) {
			background-size: auto 300px;
		}
	}
	.blog-hero-logo {
		display: block;
		position: relative;
		width: 750px;
		height: 400px;
		margin: 0 auto;
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;
		z-index: 2;
		@media only screen and (max-width: 768px) {
			width: 500px;
			height: 300px;
		}
		@media only screen and (max-width: 500px) {
			width: 100%;
			height: 300px;
		}
	}
}

#blog_archive {
	width: 100%;
	float: left;
	position: relative;
	padding-bottom: 72px;
	@media only screen and (max-width: 768px) {
		width: 100%;
		float: none;
	}
	#next {
		position: absolute;
		bottom: 12px;
		right: 12px;
		display: inline-block;
		line-height: 36px;
		padding: 0 20px;
		background: $clr_pri1;
		color: $clr_white;
		border: solid 2px $clr_pri1;
		border-radius: 4px;
		-webkit-transition: all 200ms ease;
		transition: all 200ms ease;
		a {
			color: $clr_white;
			text-decoration: none;
			-webkit-transition: all 200ms ease;
			transition: all 200ms ease;
		}
		&:hover {
			background: $clr_white;
			color: $clr_pri1;
			a {
				color: $clr_pri1;
			}
		}
	}
	.blog-posts-content {
		width: 33.33333%;
		display: inline-block;
		padding: 12px;
		box-sizing: border-box;
		vertical-align: top;
		@media only screen and (max-width: 1000px) {
			width: 50%;
		}
		@media only screen and (max-width: 768px) {
			width: 33.33333%;
		}
		@media only screen and (max-width: 650px) {
			width: 50%;
		}
		@media only screen and (max-width: 450px) {
			width: 100%;
		}
		.blog-posts-content-inner {
			text-decoration: none;
			text-align: left;
			display: block;
			padding:0;
			margin:0;
			box-sizing: border-box;
			border-radius: 4px;
			background-color: $clr_white;
			box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.35);
			.blog-posts-image-outer {
				position: relative;
				height: 0;
				width: 100%;
				padding-bottom: 80%;
				.blog-posts-image {
					position: absolute;
					top:0;left:0;right:0;bottom:0;
					background-position: center;
					background-repeat: no-repeat;
					background-size: cover;
					border-top-left-radius: 4px;
					border-top-right-radius: 4px;
					-moz-box-shadow: inset 0 0 40px rgba(0,0,0,0.25);
					-webkit-box-shadow: inset 0 0 40px rgba(0,0,0,0.25);
					box-shadow: inset 0 0 40px rgba(0,0,0,0.25);
				}
			}
			.blog-posts-info {
				padding: 18px;
				color: $clr_text_alt;
				.blog-posts-meta {
					color: $clr_grey3;
					font-style: italic;
					font-weight: $fnt_body_weight;
					font-size: 14px;
					margin-bottom: 0px;
					.blog-posts-date {

					}
					.blog-posts-author {

					}
				}
				.blog-posts-title {
					font: 800 21px/24px $fnt_heading;
					padding-bottom: 9px;
					border-bottom: 1px solid $clr_sec1;
					margin-bottom: 9px;
					text-transform: uppercase;
				}
				.blog-posts-excerpt {
					font: $fnt_body_weight 15px/21px $fnt_body;
				}
			}
		}
	}
}
#blog_aside {
	width: 100%;
	float: left;
	padding: 0px 24px 24px;
	text-align: left;
	@media only screen and (max-width: 768px) {
		width: 100%;
		float: none;
	}
	.widget {
		padding-bottom: 24px;
		border-bottom: 1px solid $clr_sec1;
		margin-bottom: 24px;
	}
	.title {
		font: 800 18px/21px $fnt_heading;
		color: $clr_text_alt;
		text-transform: uppercase;
		margin-bottom: 18px;
		letter-spacing: 0.05em;
	}
	ul {
		list-style: none;
		li {
			font: $fnt_body_weight 14px/21px $fnt_body;
			a {
				text-decoration: none;
				&:hover {
					text-decoration: underline;
				}
			}
			.post-date {
				color: $clr_sec1;
				font: $fnt_body_weight 14px/24px $fnt_body;
			}
		}
	}
}
#blog_post_outer {
	width: 100%;
	float: left;
	@media only screen and (max-width: 768px) {
		width: 100%;
		float: none;
	}
}
#blog_post {
	font: $fnt_body_weight 16px/24px $fnt_body;
	letter-spacing: 0;
	text-align: left;
	width: 97.22222222%;
	margin-left: 1.38888889%;
	margin-right: 1.38888889%;
	padding: 60px 60px;
	margin-bottom: 24px;
	border-radius: 4px;
	color: $clr_text_alt;
	background-color: $clr_white;
	box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.35);
	@media only screen and (max-width: 1000px) {
		padding: 60px 24px;
	}
	@media only screen and (max-width: 600px) {
		//padding: 60px 30px;
	}
	img.alignright { float: right; margin: 0 0 1em 1em; }
	img.alignleft { float: left; margin: 0 1em 1em 0; }
	img.aligncenter { display: block; margin-left: auto; margin-right: auto; }
	.alignright { float: right; }
	.alignleft { float: left; }
	.aligncenter { display: block; margin-left: auto; margin-right: auto; }
	.blog-post-header {
		h1 {
			display: block;
			color: $clr_text_alt;
		}
	}
	ol, ul {
		margin-left: 20px;
		margin-bottom: 18px;
		li {
			margin-bottom: 6px;
		}
	}
	p {
		margin-bottom: 18px;
	}
	a {
		color: $clr_pri1;
		&:hover {
			color: $clr_text_alt;
		}
	}
	img {
		max-width: 100%;
		height: auto;
	}
	iframe {
		max-width: 100%;
	}
	.wp-caption {
		max-width: 100%;
	}
	strong {
		font-weight: 700;
	}
	em, i {
		font-style: italic;
	}
}

.comment-box {
	font: $fnt_body_weight 16px/24px $fnt_body;
	letter-spacing: 0;
	text-align: left;
	width: 97.22222222%;
	margin-left: 1.38888889%;
	margin-right: 1.38888889%;
	padding: 60px 60px;
	margin-bottom: 50px;
	border-radius: 4px;
	background-color: $clr_white;
	box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.35);
	@media only screen and (max-width: 768px) {
		padding: 60px 6%;
	}
}
.commentlist {
	//padding-left: 200px;
	width: 100%;
	box-sizing: border-box;
	font-size: 14px;
	@media only screen and (max-width: 600px) {
		padding: 20px 2vw;
	}
	.comment {
		//padding-left: 72px;
		.comment-body {
			position: relative;
			width: 100%;
			margin: 0;
			margin-bottom: 30px;
			.reply {
				a.comment-reply-link {
					display: inline-block;
					text-decoration: none;
					padding: 4px 12px;
					font-size: 14px;
					font-weight: $fnt_body_weight;
					letter-spacing: 0.05em;
					background: $clr_sec1;
					color: $clr_white;
					-webkit-transition: all 200ms ease;
					transition: all 200ms ease;
					&:hover {
						background: $clr_text;
						color: $clr_white;
					}
				}
			}
		}
	}
}
#blog_post .blog-post-content {
	img {
		//max-width: 50%;
		@media all and ( max-width: 600px ) {
			max-width: 100%;
		}
		&.alignleft {
			max-width: 50%;
			margin: 0 .5em .5em 0;
			@media only screen and (max-width: $brk_mobile_lg) {
				//max-width: 30%;
				margin: 0 .25em .25em 0;
			}
		}
		&.alignright {
			max-width: 50%;
			margin: 0 0 .5em .5em;
			@media only screen and (max-width: $brk_mobile_lg) {
				//max-width: 30%;
				margin: 0 0 .25em .25em;
			}
		}
		&.aligncenter { }
	}
}
// #comment {
// 	background: $clr_grey2;
// 	color: $clr_sec1;
// 	font-size: 1em;
// 	margin: 5px 0px;
// 	padding: 8px 2%;
// 	border: 1px solid rgba(0, 0, 0, .08);
// 	border-radius: 3px;
// 	box-shadow: inset 1px 1px 1px rgba(0, 0, 0, .12);
// }
#respond {
	//padding-left: 200px;
	width: 100%;
	box-sizing: border-box;
	font-size: 14px;
	@media only screen and (max-width: 600px) {
		padding: 20px 2vw;
	}
	// #commentform {
	// 	input[type="submit"] {
	// 		//padding: 4px 12px;
	// 		background: $clr_sec1;
	// 		font-size: 14px;
	// 		font-weight: 300;
	// 		letter-spacing: 0.05em;
	// 		color: $clr_white;
	// 		border: solid 2px $clr_sec1;
	// 		-webkit-transition: all 200ms ease;
	// 		transition: all 200ms ease;
	// 		&:hover {
	// 			background: $clr_text;
	// 			color: $clr_white;
	// 			border-color: $clr_text;
	// 		}
	// 	}
	// }
}
#other_stories {
	border-top: 1px solid $clr_grey3;
	border-bottom: 1px solid $clr_grey3;
	padding: 20px 20px;
	@media only screen and (max-width: 600px) {
		background-color: $clr_grey2;
	}
	.other-story {
		width: 31.3333%;
		margin: 0 1% 0;
		display: inline-block;
		position: relative;
		min-height: 66px;
		vertical-align: top;
		box-sizing: border-box;
		@media only screen and (max-width: 600px) {
			width: 100%;
			margin: 3vw 1.5vw;
		}
		.other-image {
			position: absolute;
			top: 0;
			left: 0;
			height: 66px;
			width: 66px;
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
			border: 2px solid $clr_pri1;
			border-radius: 3px;
			box-sizing: border-box;
		}
		.other-title {
			width: 100%;
			padding-left: 78px;
			font-size: 18px;
			font-weight: 700;
			color: $clr_black;
			box-sizing: border-box;
			&:hover {
				text-decoration: underline;
			}
		}
	}
}
#content-auth {
	max-width: 900px;
    margin: 0 auto 0px;
	#main-auth {
		display: block;
		max-width: 900px;
		width: 97.22222222%;
		margin-left: 1.38888889%;
		margin-right: 1.38888889%;
		float: none;
		font-family: $fnt_body;
		font-weight: $fnt_body_weight;
		font-size: 16px;
		line-height: 24px;
		position: relative;
		#blog-post-author {
			margin-bottom: 60px;
			.profile-image {
				width: 25%;
				float: left;
				max-width: 200px;
				height: auto;
				margin-right: 20px;
				margin-bottom: 10px;
				img {
					width: 100%;
					height: auto;
				}
			}
			h2 {

			}
			.profile-content {

			}
			.auth-connect {
				a {

				}
			}
		}
		h2 {

		}
	}
}
#searchform {
	.screen-reader-text {
		display: none;
	}
	input[type='text'] {
		width: 65%;
		display: inline-block;
		vertical-align: top;
	}
	input[type='submit'] {
		width: 30%;
		display: inline-block;
		vertical-align: top;
	}
}

.clearfix {
	clear:both;
}




#blog_post ul.article-columnized-list {
	list-style: none;
	margin-left: 0;
	-webkit-column-count: 3;
       -moz-column-count: 3;
            column-count: 3;
    @media only screen and (max-width: 900px) {
		-webkit-column-count: 2;
	       -moz-column-count: 2;
	            column-count: 2;
	}
	@media only screen and (max-width: 600px) {
		-webkit-column-count: 1;
	       -moz-column-count: 1;
	            column-count: 1;
	}
	li {
		
	}
}

#blog_post ul.article-checkbox-list {
	list-style: none;
	margin-left: 0;
	-webkit-column-count: 2;
       -moz-column-count: 2;
            column-count: 2;
    @media only screen and (max-width: 900px) {
		-webkit-column-count: 1;
	       -moz-column-count: 1;
	            column-count: 1;
	}
	li {
		position: relative;
		padding-left: 20px;
		font: 400 16px/18px $fnt_heading;
		margin-bottom: 18px;
		i.fa {
			position: absolute;
			left: 0px;
			font-style: normal;
			line-height: 18px;
		}
		span {

		}
	}
}

#blog_post ul.article-qna-list {
	list-style: none;
	margin-left: 0;
	li {
		position: relative;
		padding-left: 20px;
		&.qna-q {
			margin-bottom: 0px;
			span {
				font: 400 16px/18px $fnt_heading;
			}
		}
		&.qna-a {
			margin-bottom: 24px;
			span {
				
			}
		}
		i.letter {
			position: absolute;
			left: 0px;
			font-style: normal;
			line-height: 24px;
			font-size: 16px;
			color: $clr_pri1;
		}
	}
}

#blog_post .quoteBlock {
	max-width: 600px;
	padding: 30px 30px 0;
	margin: 0 auto 30px;
	position: relative;
	.quoteBlock-open, .quoteBlock-close {
		position: absolute;
		height: 30px;
		width: 50px;
		opacity: 0.25;
		font-size: 50px;
	}
	.quoteBlock-open {
		top: 0;
		left: 0;
	}
	.quoteBlock-close {
		bottom: 0;
		right: 0;
	}
	.quoteBlock-quote {
		font: italic 300 15px/21px $fnt_body;
		margin-bottom: 6px;
	}
	.quoteBlock-author {
		text-align: right;
		font: 700 14px/18px $fnt_body;
	}

}

#blog_post {
	.benefit {
		width: 46%;
		height: 264px;
		margin: 2%;
		@media screen and (max-width: 750px) {
			width: 46%;
			margin: 4% 2%;
		}
		@media screen and (max-width: 450px) {
			width: 98%;
			margin: 4% 1%;
		}
	}
}




