/*
Theme Name: MeyerSign 2018
Version: 1.0.2
Author: Rich McGee, PNG Media, Adams Publishing Group, LLC.
Author URI: http://cocoapawn.me/
*/

@mixin transition($vars){
-webkit-transition: $vars;
        transition: $vars;
}
@mixin transformRotate($vars){
-webkit-transform: rotate($vars);
    -ms-transform: rotate($vars);
        transform: rotate($vars);
}
@mixin transformScale($left, $right){
-webkit-transform: scale($left,$right);
    -ms-transform: scale($left,$right);
        transform: scale($left,$right);
}
@mixin input-placeholder($font_color, $transform){
    ::-webkit-input-placeholder{color:$font_color;letter-spacing: .04em;text-transform: $transform;}
    :-moz-placeholder{color:$font_color;letter-spacing: .04em;text-transform: $transform;}
    ::-moz-placeholder{color:$font_color;letter-spacing: .04em;text-transform: $transform;}
    :-ms-input-placeholder{color:$font_color;letter-spacing: .04em;text-transform: $transform;}
}
@mixin input-placeholder-focus($font_color){
    input:focus::-webkit-input-placeholder{color:$font_color;}
    input:focus:-moz-input-placeholder{color:$font_color;}
    input:focus::-moz-input-placeholder{color:$font_color;}
    input:focus::-ms-input-placeholder{color:$font_color;}
}
@mixin textarea-placeholder-focus($font_color){
    textarea:focus::-webkit-input-placeholder{color:$font_color;}
    textarea:focus:-moz-input-placeholder{color:$font_color;}
    textarea:focus::-moz-input-placeholder{color:$font_color;}
    textarea:focus::-ms-input-placeholder{color:$font_color;}
}

/*----------------------------------------------------------------------------*/
/*	RESET
/*----------------------------------------------------------------------------*/
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote,
pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s,
samp, small, strike, strong, sub, sup, tt, var, b, u, i, center,dl, dt, dd, ol, ul, li,
fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,  figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary, time, mark, audio, video
{margin: 0;padding: 0;border: 0;font-size: 100%;font: inherit;vertical-align: baseline;}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, main,
footer, header, hgroup, menu, nav, section {display:block;}
body {line-height:1;height:100%;background-color:#fff;color:#000;text-align:center;}
ol, ul {list-style: none;}
blockquote, q {quotes: none;}
blockquote:before, blockquote:after, q:before, q:after {content:'';content:none;}
table {border-collapse:collapse;border-spacing:0;}
input {margin: 0;}
/* Special-Case resets */
body, header, main, footer, div, section, article, aside, details, figcaption,
figure, hgroup, menu, nav, span, p, a, input, textarea, select {box-sizing:border-box; /* background-color:rgba(255,0,0,0.1) */ }
a {text-decoration:none;color:#000;}
iframe {width: 1px;min-width: 100%;*width: 100%;}
input, select, textarea {-webkit-appearance:none;-webkit-border-radius:0;outline:none;}
input[type="radio"] {-webkit-appearance: radio;}
input[type="submit"]:hover {cursor: pointer;}
textarea, input, button, :focus {outline:none;}
select {-moz-appearance: none;}
svg{width:100%;height:100%;}
/* END CSS RESET */


/*----------------------------------------------------------------------------*/
/*	BASE
/*----------------------------------------------------------------------------*/
/* COLORS */
$clr_black: #000;
$clr_white: #fff;
$clr_off_white: #fdfdfd;
$clr_sitebg: #000;
$clr_text: #eee;
$clr_text_alt: #000;
$clr_darkyo: #333;

$clr_grey0: #f8f8f8;
$clr_grey1: #e8e8e8;
$clr_grey2: #c8c8c8;
$clr_grey3: #a8a8a8;
$clr_grey4: #8a8a8a;

$clr_off1: #f9f5f2;
$clr_off2: #e4dfdb;

$clr_pri1: #1ab1f9; // electric blue
$clr_pri2: #8CD7FF; // lt electric blue
$clr_sec1: #0776ab; // blue
$clr_sec2: #013f5d; // dk blue
$clr_sec3: #2d56a7; // banner blue

$clr_white_tr: rgba(255,255,255,0.65);
$clr_black_tr: rgba(0,0,0,0.65);


/* FONTS */
$fnt_heading: 'Oswald', sans-serif;
$fnt_subhead: 'Open Sans', sans-serif;
$fnt_body: 'Open Sans', sans-serif; // 300, 300i, 400, 400i, 700, 700i
$fnt_body_size: 16px;
$fnt_body_lineheight: 24px;
$fnt_body_weight: 300;
$fnt_body_spacing: 0em;

/* BREAKPOINTS */
$brk_desktop: 1200px;
$brk_tablet: 900px;
$brk_mobile_lg: 700px;
$brk_mobile: 600px;
$brk_small: 400px;


/*----------------------------------------------------------------------------*/
/*	GENERAL
/*----------------------------------------------------------------------------*/
body {
	text-align: left;
	color: $clr_text;
	background-color: $clr_sitebg;
	font-family: $fnt_body;
	font-size: $fnt_body_size;
	line-height: $fnt_body_lineheight;
	font-weight: $fnt_body_weight;
	letter-spacing: $fnt_body_spacing;
}

img.alignright { float: right; margin: 0 0 1em 1em; }
img.alignleft { float: left; margin: 0 1em 1em 0; }
img.aligncenter { display: block; margin-left: auto; margin-right: auto; }
.alignright { float: right; }
.alignleft { float: left; }
.aligncenter { display: block; margin-left: auto; margin-right: auto; }

.mobile-show {
	display: none;
	@media all and ( max-width: $brk_mobile ) {
		display: block;
	}
}
.mobile-hide {
	display: block;
	@media all and ( max-width: $brk_mobile ) {
		display: none;
	}
}
.mobile-show-inline {
	display: none;
	@media all and ( max-width: $brk_mobile ) {
		display: inline-block;
	}
}
.mobile-hide-inline {
	display: inline-block;
	@media all and ( max-width: $brk_mobile ) {
		display: none;
	}
}
.clearFix {
	clear: both;
}
p {
	margin-bottom: 18px;
}
svg{
	width:100%;
	height:100%;
}
img {
	max-width: 100%;
	height: auto;
}
h1 {
	font-family: $fnt_heading;
	font-size: 48px;
	line-height: 54px;
	margin-bottom: 24px;
	a {
		text-decoration: none;
	}
}
h2 {
	font-family: $fnt_heading;
	font-size: 36px;
	line-height: 42px;
	margin-bottom: 18px;
	a {
		text-decoration: none;
	}
}
h3 {
	font-family: $fnt_heading;
	font-size: 27px;
	line-height: 33px;
	margin-bottom: 12px;
	a {
		text-decoration: none;
	}
}
h4 {
	font-family: $fnt_heading;
	font-size: 24px;
	line-height: 30px;
	margin-bottom: 0px;
	a {
		text-decoration: none;
	}
}
h5 {
	font-family: $fnt_heading;
	font-size: 21px;
	line-height: 27px;
	margin-bottom: 12px;
	color: $clr_pri1;
	a {
		text-decoration: none;
		color: $clr_pri1;
	}
}
h6 {
	font-family: $fnt_heading;
	font-size: 18px;
	line-height: 24px;
	margin-bottom: 0px;
	color: $clr_pri1;
	a {
		text-decoration: none;
		color: $clr_pri1;
	}
}
strong, b {
	font-weight: 700;
	color: $clr_black;
}
em {
	font-style: italic;
}
ol, ul {
	margin-left: 20px;
	margin-bottom: 18px;
	li {
		margin-bottom: 6px;
	}
}
ul#nav {
	li {
		margin-bottom: 0;
	}
}
ol {
	list-style: decimal;
	ol {
		list-style: lower-roman;
		ol {
			list-style: lower-alpha;
		}
	}
}
ul {
	list-style: square;
	ul {
		list-style: circle;
		ul {
			list-style: disc;

		}
	}
}
a {
	color: $clr_text;
	text-decoration: underline;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	&:hover {
		color: $clr_pri1;
		text-decoration: underline;
	}
}
.tagcloud a {
	text-decoration: none;
	&:hover {
		text-decoration: underline;
	}
}
.contact-table td {
	padding: 6px 24px 6px 0;
}
.dark-text {
	color: #333;
}


/*----------------------------------------------------------------------------*/
/*	MAIN
/*----------------------------------------------------------------------------*/
main {
	position: relative;
	width: 100%;
	padding-top: 60px;
	@media screen and (max-width: $brk_mobile_lg) {
		padding-top: 60px;
	}
}
.home main {
	padding-top: 0px;
}
.home.stickNav main {
	//padding-top: 50px;
}
section {
	width: 100%;
	.sectionContainer {
		position: relative;
		max-width: $brk_desktop;
		margin: 0 auto;
		overflow: hidden;
		// padding: 0 24px;
		// @media screen and (max-width: $brk_desktop) {
		// 	padding: 0 2vw;
		// }
		// @media screen and (max-width: $brk_tablet) {
		// 	padding: 0 3vw;
		// }
		// @media screen and (max-width: $brk_mobile_lg) {
		// 	padding: 0 4vw;
		// }
	}
}
.page_section, .generic-content-section {
	padding: 60px 0.5% 60px;
	margin: 0;
	.sectionContainer {
	}
}

/*----------------------------------------------------------------------------*/
/*	PAGE SECTION
/*----------------------------------------------------------------------------*/
.page_section_standard_wp {
	margin: 0 auto;
	padding: 0px 30px 30px;
	overflow: hidden;
	position: relative;
	@media all and ( max-width: $brk_desktop ) {
		padding: 60px 2.5vw;
	}
	.sectionContainer {
		max-width: 1000px;
	}
}
.page_section {
	margin: 0 auto;
	padding: 0px 0 30px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	overflow: hidden;
	position: relative;
	&.confine_section {
		max-width: $brk_desktop;
	}
	.sectionContainer {
		//max-width: 1000px;
	}
	&.visibility_dim {
		color: $clr_white;
		a,p{
			color: $clr_white;
		}
		&::before {
			content: '';
			position: absolute;
			top:0;left:0;right:0;bottom:0;
			background: rgba(0,0,0,0.8);
		}
		.container {
			color: $clr_white;
		}
	}
	&.visibility_light {
		&::before {
			content: '';
			position: absolute;
			top:0;left:0;right:0;bottom:0;
			background: rgba(255,255,255,0.8);
		}
	}
	&.motion_fixed {
		background-attachment: fixed;
	}
	&.no-padding-bottom {
		padding-bottom: 0;
	}
	&.no-padding-top {
		padding-top: 0;
	}
	&.no-padding-bottom-top {
		padding-top: 0;
		padding-bottom: 0;
	}
	&.content_columns {
		padding-top: 0.5%;
		padding-bottom: 1%;
		@media (max-width: 900px) {
			padding-top: 1%;
		}
	}
}
.pre_content, .post_content {
	padding: 0px;
	margin: 0 auto;
	max-width: 1200px;
}














#page_overview {
	max-width: 900px;
	margin: 60px auto 30px;
	.sectionTitle {
		text-align: center;
	}
	.contentWrap {

	}
}




.carousel {
	.sectionContainer {
		background-color: $clr_grey2;
		position: relative;

		.carousel-bg-accent {
			background-color: $clr_grey1;
			position: absolute;
			top:0;left:0;right:0;
			height: 296px;
		}
		.carousel-heading {
			font: 700 15px/21px $fnt_body;
			color: $clr_text_alt;
			height: 20px;
			z-index: 1;
			text-align: left;
			position: relative;
			text-align: center;
			margin: 30px 0 24px;
			@media all and ( max-width: $brk_desktop ) {
				padding-left: 12.5vw;
			}
			@media all and ( max-width: $brk_mobile ) {
				padding-left: 5vw;
			}
		}
		.container {
			margin: 0 auto;
			text-align: left;
			position: relative;
			overflow: hidden;
			.carousel-item {
				display: block;
				padding: 0px 20px;
				width: 242px;
				text-align: center;
				border-left: 1px solid $clr_grey3;
				&:last-of-type {
					border-right: 1px solid $clr_grey3;
				}
				img {
					width: 100%;
					height: auto;
				}
				.carousel-item-image {
					height:200px;
					width: 200px;
					background-position: center;
					background-repeat: no-repeat;
					background-size: contain;
				}
				.carousel-item-title {
					font: 600 15px/21px $fnt_body;
					color: $clr_text_alt;
					text-align: left;
					margin: 36px 0 12px;
				}
				.carousel-item-desc {
					font: 300 12px/14px $fnt_body;
					color: $clr_text_alt;
					text-align: left;
					p {
						font: 300 12px/14px $fnt_body;
						color: $clr_text_alt;
						text-align: left;
						margin-bottom: 0;
					}
				}
			}
		}
	}
}





#staff .sectionTitle {
	max-width: 900px;
	margin: 60px auto 0px;
	text-align: center;
}
.staff {
	max-width: 900px;
	margin: 0px auto 30px;
	.staff-item {
		display: inline-block;
		width: 27.3333%;
		vertical-align: top;
		margin: 3%;
		.staff-item-image {
			height: 0;
			width: 100%;
			padding-bottom: 100%;
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
			margin-bottom: 12px;
		}
		.staff-item-name {
			font: 700 16px/18px $fnt_body;
			margin-bottom: 3px;
		}
		.staff-item-title {
			font: 400 14px/18px $fnt_body;
			margin-bottom: 3px;
		}
		.staff-item-email {
			font: 400 14px/18px $fnt_body;
		}
	}
}


#gallery {
	margin: 60px auto 0px;
}
.gallery-wrap {
	margin: 36px 12px;
	border-bottom: 1px solid $clr_grey2;
	.gallery-left {
		display: inline-block;
		vertical-align: top;
		width: 25%;
		margin-bottom: 36px;
		.gallery-image {
			display: inline-block;
			position: relative;
			margin: 0;
			height: 0;
			width: 100%;
			padding-bottom: 100%;
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
		}
	}
	.gallery-right {
		display: inline-block;
		vertical-align: top;
		width: 75%;
		margin-bottom: 30px;
		padding: 0 12px 0px 30px;
		.gallery-title {
			font: 700 21px/27px $fnt_body;
			margin-bottom: 6px;
		}
		.gallery-desc {
			font: 400 14px/21px $fnt_body;
			margin-bottom: 12px;
		}
		.gallery {
			a {
				text-decoration: none;
				img {
					max-width: 100px;
					height: auto;
					margin: 3px 6px 3px 0;
				}
			}
		}
	}
}


#products .contentWrap {
	//max-width: 900px;
	margin: 60px auto 0px;
	text-align: center;
	.product {
		padding: 0 0 30px;
		margin: 0px 12px 30px;
		border-bottom: 1px solid $clr_grey2;
		.product-title {
			font: 700 21px/27px $fnt_body;
			margin-bottom: 6px;
		}
		.product-desc {
			font: 400 14px/21px $fnt_body;
			margin-bottom: 12px;
		}
		.product-images {

			img.product-image {
				margin: 3px;
				vertical-align: top;
			}
		}
	}
}


body.blog main, 
body.archive main, 
body.single-post main, 
body.single-products main, 
body.single-process main {
	background-color: $clr_white;
	color: $clr_text_alt;
	a {
		color: $clr_text_alt;
		&:hover {
			color: $clr_text_alt;
		}
	}
}
body.single-products #blog_post, 
body.single-process #blog_post {
	box-shadow: none;
}




#staff {
	padding: 60px 0 60px;
	margin: 0;
	.sectionContainer {
		max-width: 100%;
		padding: 0;
		margin: 0;
		.section-arrow {
			text-align: center;
			height: 30px;
			width: 50px;
			display: block;
			margin: 0 auto 12px;
			background-image: url('img/ms_arrow.png');
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
		}
		.section-title {
			max-width: 700px;
			padding: 12px;
			margin: 0 auto;
			text-align: center;
			font: 36px/42px $fnt_heading;
			//color: $clr_pri1;
		}
		.section-overview {
			max-width: 700px;
			padding: 12px;
			margin: 0 auto;
			font: 18px/24px $fnt_body;
		}
		.staff-wrapper {
			background-color: $clr_white;
			.staff-wrapper-inner {
				max-width: $brk_desktop;
				margin: 0 auto;
				padding: 0.5% 0.5%;
			}
			.staff-item {
				display: inline-block;
				vertical-align: top;
				position: relative;
				width: 32.3333%;
				margin: 0.5%;
				height: 0;
				padding-bottom: 27%;
				.item-image {
					position: absolute;
					top:0;left:0;right:0;bottom:0;
					background-position: center;
					background-repeat: no-repeat;
					background-size: cover;
				}
				.item-overlay {
					position: absolute;
					top:auto;left:0;right:0;bottom:0;
					background-color: rgba(26,117,249,0.85);
					text-decoration: none;
					padding: 6px 12px 6px;
					-webkit-transition: all 300ms ease;
							transition: all 300ms ease;
					.item-name {
						color: $clr_white;
						text-align: center;
						font-size: 21px;
						line-height: 24px;
						letter-spacing: 0.05em;
						-webkit-transition: all 300ms ease;
								transition: all 300ms ease;
					}
					.item-title {
						color: $clr_white;
						text-align: center;
						font-size: 18px;
						line-height: 21px;
						font-weight: 300;
						letter-spacing: 0.05em;
						-webkit-transition: all 300ms ease;
								transition: all 300ms ease;
					}
					.item-email {
						display: block;
						color: $clr_white;
						text-align: center;
						font-size: 18px;
						line-height: 21px;
						font-weight: 300;
						letter-spacing: 0.05em;
						-webkit-transition: all 300ms ease;
								transition: all 300ms ease;
					}
					&:hover {
						padding: 9px 12px 15px;
						text-shadow: 0 0 6px rgba(0,0,0, 0.5);
					}
				}
			}
		}
	}
}


#singleTitle {
	padding: 60px 0px 30px;
}
#singleGenericContent {
	padding: 0px 0px 30px;
}
section.page_section.hasGallery {
	padding: 30px 0px 30px;
}

