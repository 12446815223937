/*----------------------------------------------------------------------------*/
/*	AD STYLING
/*----------------------------------------------------------------------------*/
.main-content-wrap {
	max-width: 1200px;
	margin: 0 auto;
	.main-content {
		display: flex;
		@media only screen and (max-width: 768px) {
			display: block;
		}
	}
	.place-right-wrap {
		flex-basis: 300px;
		@media only screen and (max-width: 768px) {
			flex-basis: auto;
			width: 300px;
			margin: 24px auto;
		}
		.place-right-big {
			height: 600px;
			width: 300px;
			margin: 0px auto 24px;
			background-color: #eee;
		}
		.place-right-small {
			height: 250px;
			width: 300px;
			margin: 0px auto 24px;
			background-color: #eee;
		}
	}
}
.place-top {
	height: 90px;
	width: 728px;
	margin: 24px auto;
	background-color: #eee;
	@media all and ( max-width: 728px ) {
		display: none;
	}
}
.place-bottom {
	height: 90px;
	width: 728px;
	margin: 24px auto;
	background-color: #eee;
	@media all and ( max-width: 728px ) {
		display: none;
	}
}