/*----------------------------------------------------------------------------*/
/*	SOCIAL
/*----------------------------------------------------------------------------*/
.menu-item-social {
	display: inline-block;
	vertical-align: top;
	margin-left: 4px;
}
.social_link {
	width: 30px;
	height: 30px;
	display: inline-block;
	margin: 0 10px 0 0;
	position: relative;
	vertical-align: top;
}
.social-link {
	color: $clr_white;
	text-decoration: none;
	text-shadow: none;
	margin-right: 10px;
	-webkit-transition: all 200ms ease;
			transition: all 200ms ease;
	&:last-of-type {
		margin-right: 0px;
	}
	.social-backdrop {
		display: inline-block;
		height: 30px;
		width: 30px;
		border-radius: 50%;
		background-color: $clr_sec3;
		text-align: center;
		vertical-align: top;
		margin-top: 15px;
		-webkit-transition: all 200ms ease;
				transition: all 200ms ease;
		i {
			display: block;
			font-size: 18px;
			line-height: 30px;
			color: $clr_white;
			-webkit-transition: all 200ms ease;
					transition: all 200ms ease;
		}
	}
	&:hover {
		color: $clr_white;
		text-decoration: none;
		text-shadow: none;
		.social-backdrop {
			background-color: $clr_white;
			i {
				color: $clr_sec3;
			}
		}
	}
}
.footer-action-subscribe {
	line-height: 30px;
	//text-transform: uppercase;
	text-decoration: none;
	color: $clr_sec1;
	-webkit-transition: all 200ms ease;
			transition: all 200ms ease;
	&:hover {
		text-shadow: none;
		text-decoration: none;
	}
}
