/*----------------------------------------------------------------------------*/
/*	MOBILE NAV
/*----------------------------------------------------------------------------*/
/* required mixins */
@mixin mobileNav_transition($vars){
	-webkit-transition: $vars;
			transition: $vars;
}
@mixin mobileNav_transform($vars){
	-webkit-transform: $vars;
		-ms-transform: $vars;
			transform: $vars;
}

/* general */
$mobileNav_fontFamily: $fnt_body;
$mobileNav_break_mobile: $brk_mobile_lg;

/* tab styling */
$mobileNav_tabWidth: 60px;
$mobileNav_tabHeight: 60px;
$mobileNav_tabTopOffset: 0px;
$mobileNav_tabTopSpacing: 15px;
$mobileNav_tabBottomSpacing: 15px;
$mobileNav_tabLineHeight: 2px;
$mobileNav_tabFont: 100 10px/10px $mobileNav_fontFamily;
$mobileNav_tabLetterSpacing: 0.05em;
$mobileNav_tabBkgrdColor: transparent;
$mobileNav_tabFontColor: $clr_white;
$mobileNav_tabLineColor: $clr_white;
$mobileNav_tabOpenBkgrdColor: #333;
$mobileNav_tabOpenFontColor: $clr_pri1;
$mobileNav_tabOpenLineColor: $clr_pri1;

$mobileNav_tabFontColor_home: $clr_white;
$mobileNav_tabLineColor_home: $clr_white;

/* menu items */
$mobileNav_menuWidth: 240px;
$mobileNav_menuPadLeftRight: 18px;
$mobileNav_menuTitleFont: 300 12px/12px $mobileNav_fontFamily;
$mobileNav_menuTitleLetterSpacing: 0.05em;
$mobileNav_menuItemsFont: 300 15px/30px $mobileNav_fontFamily;
$mobileNav_menuItemsLetterSpacing: 0.05em;
$mobileNav_menuBkGrdColor: #333;
$mobileNav_menuTitleFontColor: $clr_pri1;
$mobileNav_menuItemsLineColor: $clr_pri1;
$mobileNav_menuItemsFontColor: $clr_grey2;
$mobileNav_menuItemsFontColorHov: $clr_grey2;

/* submenu items */
$mobileNav_subMenuWidth: 200px;
$mobileNav_subMenuPadLeftRight: 18px;
$mobileNav_subMenuBkgrdColor: $clr_sec1;
$mobileNav_subMenuItemsBkgrdColor: $clr_sec2;
$mobileNav_subMenuItemsBkgrdColorHover: $clr_grey4;
$mobileNav_subMenuItemsFont: 300 15px/30px $mobileNav_fontFamily;
$mobileNav_subMenuItemsFontColor: $clr_white;
$mobileNav_subMenuItemsLetterSpacing: 0.05em;
$mobileNav_subMenuItemsLineColor: $clr_grey2;

.home #mobileNav .tab {
	color: $mobileNav_tabFontColor_home;
	&:before {
		border-top: $mobileNav_tabLineColor_home solid $mobileNav_tabLineHeight;
		border-bottom: $mobileNav_tabLineColor_home solid $mobileNav_tabLineHeight;
	}
	&:after {
		border-bottom: $mobileNav_tabLineColor_home solid $mobileNav_tabLineHeight;
	}
}
#mobileNav {
	display: none;
	width: $mobileNav_menuWidth;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	background-color: $mobileNav_menuBkGrdColor;
	font-family: $mobileNav_fontFamily;
	text-transform: uppercase;
	z-index: 6000;
	@include mobileNav_transform(translate($mobileNav_menuWidth, 0));
	@include mobileNav_transition(all 200ms ease);

	.tab {
		box-sizing: border-box;
		position: absolute;
		top: $mobileNav_tabTopOffset;
		left: ( $mobileNav_tabWidth * -1 );
		height: $mobileNav_tabHeight;
		width: $mobileNav_tabWidth;
		background-color: $mobileNav_tabBkgrdColor;
		color: $mobileNav_tabFontColor;
		padding-top: $mobileNav_tabTopSpacing;
		font: $mobileNav_tabFont;
		letter-spacing: $mobileNav_tabLetterSpacing;
		text-transform: uppercase;
		text-align: center;
		@include mobileNav_transform(translate(0, 0));
		@include mobileNav_transition(all 200ms ease);
		&:before {
			content: '';
			position: absolute;
			bottom: ( $mobileNav_tabBottomSpacing + ($mobileNav_tabLineHeight * 3) );
			left: ( $mobileNav_tabWidth / 5 );
			right: ( $mobileNav_tabWidth / 5 );
			height: $mobileNav_tabLineHeight * 4;
			box-sizing: border-box;
			border-top: $mobileNav_tabLineColor solid $mobileNav_tabLineHeight;
			border-bottom: $mobileNav_tabLineColor solid $mobileNav_tabLineHeight;
			@include mobileNav_transition(all 200ms ease-in-out);
		}
		&:after {
			content: '';
			position: absolute;
			bottom: $mobileNav_tabBottomSpacing;
			left: ( $mobileNav_tabWidth / 5 );
			right: ( $mobileNav_tabWidth / 5 );
			box-sizing: border-box;
			border-bottom: $mobileNav_tabLineColor solid $mobileNav_tabLineHeight;
			@include mobileNav_transition(all 200ms ease-in-out);
		}
	}
	.main {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		overflow-y: auto;
		padding: 24px 2px;
		width: ( $mobileNav_menuWidth - 4 );
		overflow-x: hidden;
	}
	.group {
		margin-bottom: 50px;
		text-align: left;
	}
	.groupTitle {
		padding: 0px $mobileNav_menuPadLeftRight;
		font: $mobileNav_menuTitleFont;
		letter-spacing: $mobileNav_menuTitleLetterSpacing;
		color: $mobileNav_menuTitleFontColor;
		margin-bottom: 10px;
	}
	.groupMenu {
		list-style: none;
		padding-left: 0;
		margin-left: 4px;
		li {
			border-bottom: 1px solid $mobileNav_menuItemsLineColor;
			&.mobile-address a {
				line-height: 20px;
			}
			&.current-menu-item a {
				color: $mobileNav_menuItemsFontColorHov;
			}
			&.menu-item-has-children {
				position: relative;
				&::after {
					content: '>';
					color: $clr_white;
					line-height: 34px;
					text-align: center;
					height: 34px;
					width: 34px;
					position: absolute;
					top: 0;
					right: 0;
					background-color: rgba(255,255,255,0.5);
				}
			}
		}
		a {
			text-decoration: none;
			display: block;
			padding: 5px $mobileNav_menuPadLeftRight;
			font: $mobileNav_menuItemsFont;
			letter-spacing: $mobileNav_menuTitleLetterSpacing;
			color: $mobileNav_menuItemsFontColor;
			@include mobileNav_transition(all 200ms ease);
			&:hover {
				color: $mobileNav_menuItemsFontColorHov;
			}
		}
		.social-icon {
			display:inline-block;
			margin-left: 15px;
			margin-top: 9px;
			width: 32px;
			height: 32px;
			color: $clr_black;
			background-color: $clr_white;
			border-radius: 50%;
			transition: all 200ms ease;
			fill: $clr_white;
			padding: 0;
			position: relative;
			&::before {
				content: '';
				height: 26px;
				width: 26px;
				border-radius: 50%;
				background-color: $clr_black;
				position: absolute;
				z-index: 0;
				top: 50%;
				left: 50%;
				-webkit-transform: translateX(-50%) translateY(-50%);
				   -moz-transform: translateX(-50%) translateY(-50%);
					-ms-transform: translateX(-50%) translateY(-50%);
						transform: translateX(-50%) translateY(-50%);
				-webkit-transition: all 200ms ease;
				transition: all 200ms ease;
			}
			svg {
				position: relative;
				z-index: 2;
			}
			&:hover {
				fill: $clr_black;
				&::before {
					background-color: $clr_white;
				}
			}
		}
	}
	.subGroup, ul.sub-menu {
		cursor: pointer;
		position: static;
	}
	.subGroupTitle {
		display: block;
		font: $mobileNav_menuItemsFont;
		letter-spacing: $mobileNav_menuTitleLetterSpacing;
		color: $mobileNav_menuItemsFontColor;
		padding: 5px $mobileNav_menuPadLeftRight;
	}
	.subGroupMenu, ul.sub-menu {
		padding: 50px 0px;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: auto;
		list-style: none;
		text-transform: uppercase;
		max-height: 100%;
		height: 100%;
		overflow: hidden;
		z-index: 2010;
		width: $mobileNav_subMenuWidth;
		background-color: $mobileNav_subMenuBkgrdColor;
		@include mobileNav_transform(translate($mobileNav_subMenuWidth, 0));
		@include mobileNav_transition(all 200ms ease);
		li {
			border-bottom: 1px solid $mobileNav_subMenuItemsLineColor;
			background-color: $mobileNav_subMenuItemsBkgrdColor;
			@include mobileNav_transition(all 200ms ease);
			&:hover {
				background-color: $mobileNav_subMenuItemsBkgrdColorHover;
			}
		}
		a {
			text-decoration: none;
			display: block;
			padding: 5px $mobileNav_subMenuPadLeftRight;
			font: $mobileNav_subMenuItemsFont;
			letter-spacing: $mobileNav_subMenuItemsLetterSpacing;
			color: $mobileNav_subMenuItemsFontColor;
		}
	}
	.subGroup.show, ul.sub-menu.show {
		.subGroupMenu {
			display:block;
			@include mobileNav_transform(translate(0, 0));
		}
		&:before {
			content:'';
			background-color:rgba(0,0,0,.7);
			position:fixed;
			top:0;left:0;right:0;bottom:0;
		}
	}
	.phone {
		-webkit-hyphens: none;
	}
	@media screen and (max-width: $mobileNav_break_mobile) {
		display: block;
	}

}
body.showMobileNav {
	overflow: hidden;
	#mobileNav {
		@include mobileNav_transform(translate(0, 0));

		.tab {
			background-color: $mobileNav_tabOpenBkgrdColor;
			color: $mobileNav_tabOpenFontColor;
			&:before {
				bottom: ( $mobileNav_tabBottomSpacing + $mobileNav_tabLineHeight + ( $mobileNav_tabLineHeight / 2 ) );
				height: 0px;
				border-top: none;
				border-bottom: $mobileNav_tabOpenLineColor solid $mobileNav_tabLineHeight;
				@include mobileNav_transform(rotate(45deg));
			}
			&:after {
				bottom: ( $mobileNav_tabBottomSpacing + $mobileNav_tabLineHeight + ( $mobileNav_tabLineHeight / 2 ) );
				border-bottom: $mobileNav_tabOpenLineColor solid $mobileNav_tabLineHeight;
				@include mobileNav_transform(rotate(-45deg));
			}
		}
	}
	&:after {
		content:'';
		background-color:rgba(0,0,0,.7);
		position:fixed;
		top:0;left:0;right:0;bottom:0;
		z-index:1000;
	}
}
