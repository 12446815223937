@charset "UTF-8";
/*
Theme Name: MeyerSign 2018
Version: 1.0.2
Author: Rich McGee, PNG Media, Adams Publishing Group, LLC.
Author URI: http://cocoapawn.me/
*/
/*----------------------------------------------------------------------------*/
/*	RESET
/*----------------------------------------------------------------------------*/
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote,
pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s,
samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li,
fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, main,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1;
  height: 100%;
  background-color: #fff;
  color: #000;
  text-align: center; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after, q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

input {
  margin: 0; }

/* Special-Case resets */
body, header, main, footer, div, section, article, aside, details, figcaption,
figure, hgroup, menu, nav, span, p, a, input, textarea, select {
  box-sizing: border-box;
  /* background-color:rgba(255,0,0,0.1) */ }

a {
  text-decoration: none;
  color: #000; }

iframe {
  width: 1px;
  min-width: 100%;
  *width: 100%; }

input, select, textarea {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  outline: none; }

input[type="radio"] {
  -webkit-appearance: radio; }

input[type="submit"]:hover {
  cursor: pointer; }

textarea, input, button, :focus {
  outline: none; }

select {
  -moz-appearance: none; }

svg {
  width: 100%;
  height: 100%; }

/* END CSS RESET */
/*----------------------------------------------------------------------------*/
/*	BASE
/*----------------------------------------------------------------------------*/
/* COLORS */
/* FONTS */
/* BREAKPOINTS */
/*----------------------------------------------------------------------------*/
/*	GENERAL
/*----------------------------------------------------------------------------*/
body {
  text-align: left;
  color: #eee;
  background-color: #000;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 300;
  letter-spacing: 0em; }

img.alignright {
  float: right;
  margin: 0 0 1em 1em; }

img.alignleft {
  float: left;
  margin: 0 1em 1em 0; }

img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto; }

.alignright {
  float: right; }

.alignleft {
  float: left; }

.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto; }

.mobile-show {
  display: none; }
  @media all and (max-width: 600px) {
    .mobile-show {
      display: block; } }

.mobile-hide {
  display: block; }
  @media all and (max-width: 600px) {
    .mobile-hide {
      display: none; } }

.mobile-show-inline {
  display: none; }
  @media all and (max-width: 600px) {
    .mobile-show-inline {
      display: inline-block; } }

.mobile-hide-inline {
  display: inline-block; }
  @media all and (max-width: 600px) {
    .mobile-hide-inline {
      display: none; } }

.clearFix {
  clear: both; }

p {
  margin-bottom: 18px; }

svg {
  width: 100%;
  height: 100%; }

img {
  max-width: 100%;
  height: auto; }

h1 {
  font-family: "Oswald", sans-serif;
  font-size: 48px;
  line-height: 54px;
  margin-bottom: 24px; }
  h1 a {
    text-decoration: none; }

h2 {
  font-family: "Oswald", sans-serif;
  font-size: 36px;
  line-height: 42px;
  margin-bottom: 18px; }
  h2 a {
    text-decoration: none; }

h3 {
  font-family: "Oswald", sans-serif;
  font-size: 27px;
  line-height: 33px;
  margin-bottom: 12px; }
  h3 a {
    text-decoration: none; }

h4 {
  font-family: "Oswald", sans-serif;
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 0px; }
  h4 a {
    text-decoration: none; }

h5 {
  font-family: "Oswald", sans-serif;
  font-size: 21px;
  line-height: 27px;
  margin-bottom: 12px;
  color: #1ab1f9; }
  h5 a {
    text-decoration: none;
    color: #1ab1f9; }

h6 {
  font-family: "Oswald", sans-serif;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 0px;
  color: #1ab1f9; }
  h6 a {
    text-decoration: none;
    color: #1ab1f9; }

strong, b {
  font-weight: 700;
  color: #000; }

em {
  font-style: italic; }

ol, ul {
  margin-left: 20px;
  margin-bottom: 18px; }
  ol li, ul li {
    margin-bottom: 6px; }

ul#nav li {
  margin-bottom: 0; }

ol {
  list-style: decimal; }
  ol ol {
    list-style: lower-roman; }
    ol ol ol {
      list-style: lower-alpha; }

ul {
  list-style: square; }
  ul ul {
    list-style: circle; }
    ul ul ul {
      list-style: disc; }

a {
  color: #eee;
  text-decoration: underline;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease; }
  a:hover {
    color: #1ab1f9;
    text-decoration: underline; }

.tagcloud a {
  text-decoration: none; }
  .tagcloud a:hover {
    text-decoration: underline; }

.contact-table td {
  padding: 6px 24px 6px 0; }

.dark-text {
  color: #333; }

/*----------------------------------------------------------------------------*/
/*	MAIN
/*----------------------------------------------------------------------------*/
main {
  position: relative;
  width: 100%;
  padding-top: 60px; }
  @media screen and (max-width: 700px) {
    main {
      padding-top: 60px; } }

.home main {
  padding-top: 0px; }

section {
  width: 100%; }
  section .sectionContainer {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
    overflow: hidden; }

.page_section, .generic-content-section {
  padding: 60px 0.5% 60px;
  margin: 0; }

/*----------------------------------------------------------------------------*/
/*	PAGE SECTION
/*----------------------------------------------------------------------------*/
.page_section_standard_wp {
  margin: 0 auto;
  padding: 0px 30px 30px;
  overflow: hidden;
  position: relative; }
  @media all and (max-width: 1200px) {
    .page_section_standard_wp {
      padding: 60px 2.5vw; } }
  .page_section_standard_wp .sectionContainer {
    max-width: 1000px; }

.page_section {
  margin: 0 auto;
  padding: 0px 0 30px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  position: relative; }
  .page_section.confine_section {
    max-width: 1200px; }
  .page_section.visibility_dim {
    color: #fff; }
    .page_section.visibility_dim a, .page_section.visibility_dim p {
      color: #fff; }
    .page_section.visibility_dim::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.8); }
    .page_section.visibility_dim .container {
      color: #fff; }
  .page_section.visibility_light::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.8); }
  .page_section.motion_fixed {
    background-attachment: fixed; }
  .page_section.no-padding-bottom {
    padding-bottom: 0; }
  .page_section.no-padding-top {
    padding-top: 0; }
  .page_section.no-padding-bottom-top {
    padding-top: 0;
    padding-bottom: 0; }
  .page_section.content_columns {
    padding-top: 0.5%;
    padding-bottom: 1%; }
    @media (max-width: 900px) {
      .page_section.content_columns {
        padding-top: 1%; } }

.pre_content, .post_content {
  padding: 0px;
  margin: 0 auto;
  max-width: 1200px; }

#page_overview {
  max-width: 900px;
  margin: 60px auto 30px; }
  #page_overview .sectionTitle {
    text-align: center; }

.carousel .sectionContainer {
  background-color: #c8c8c8;
  position: relative; }
  .carousel .sectionContainer .carousel-bg-accent {
    background-color: #e8e8e8;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 296px; }
  .carousel .sectionContainer .carousel-heading {
    font: 700 15px/21px "Open Sans", sans-serif;
    color: #000;
    height: 20px;
    z-index: 1;
    text-align: left;
    position: relative;
    text-align: center;
    margin: 30px 0 24px; }
    @media all and (max-width: 1200px) {
      .carousel .sectionContainer .carousel-heading {
        padding-left: 12.5vw; } }
    @media all and (max-width: 600px) {
      .carousel .sectionContainer .carousel-heading {
        padding-left: 5vw; } }
  .carousel .sectionContainer .container {
    margin: 0 auto;
    text-align: left;
    position: relative;
    overflow: hidden; }
    .carousel .sectionContainer .container .carousel-item {
      display: block;
      padding: 0px 20px;
      width: 242px;
      text-align: center;
      border-left: 1px solid #a8a8a8; }
      .carousel .sectionContainer .container .carousel-item:last-of-type {
        border-right: 1px solid #a8a8a8; }
      .carousel .sectionContainer .container .carousel-item img {
        width: 100%;
        height: auto; }
      .carousel .sectionContainer .container .carousel-item .carousel-item-image {
        height: 200px;
        width: 200px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain; }
      .carousel .sectionContainer .container .carousel-item .carousel-item-title {
        font: 600 15px/21px "Open Sans", sans-serif;
        color: #000;
        text-align: left;
        margin: 36px 0 12px; }
      .carousel .sectionContainer .container .carousel-item .carousel-item-desc {
        font: 300 12px/14px "Open Sans", sans-serif;
        color: #000;
        text-align: left; }
        .carousel .sectionContainer .container .carousel-item .carousel-item-desc p {
          font: 300 12px/14px "Open Sans", sans-serif;
          color: #000;
          text-align: left;
          margin-bottom: 0; }

#staff .sectionTitle {
  max-width: 900px;
  margin: 60px auto 0px;
  text-align: center; }

.staff {
  max-width: 900px;
  margin: 0px auto 30px; }
  .staff .staff-item {
    display: inline-block;
    width: 27.3333%;
    vertical-align: top;
    margin: 3%; }
    .staff .staff-item .staff-item-image {
      height: 0;
      width: 100%;
      padding-bottom: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      margin-bottom: 12px; }
    .staff .staff-item .staff-item-name {
      font: 700 16px/18px "Open Sans", sans-serif;
      margin-bottom: 3px; }
    .staff .staff-item .staff-item-title {
      font: 400 14px/18px "Open Sans", sans-serif;
      margin-bottom: 3px; }
    .staff .staff-item .staff-item-email {
      font: 400 14px/18px "Open Sans", sans-serif; }

#gallery {
  margin: 60px auto 0px; }

.gallery-wrap {
  margin: 36px 12px;
  border-bottom: 1px solid #c8c8c8; }
  .gallery-wrap .gallery-left {
    display: inline-block;
    vertical-align: top;
    width: 25%;
    margin-bottom: 36px; }
    .gallery-wrap .gallery-left .gallery-image {
      display: inline-block;
      position: relative;
      margin: 0;
      height: 0;
      width: 100%;
      padding-bottom: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover; }
  .gallery-wrap .gallery-right {
    display: inline-block;
    vertical-align: top;
    width: 75%;
    margin-bottom: 30px;
    padding: 0 12px 0px 30px; }
    .gallery-wrap .gallery-right .gallery-title {
      font: 700 21px/27px "Open Sans", sans-serif;
      margin-bottom: 6px; }
    .gallery-wrap .gallery-right .gallery-desc {
      font: 400 14px/21px "Open Sans", sans-serif;
      margin-bottom: 12px; }
    .gallery-wrap .gallery-right .gallery a {
      text-decoration: none; }
      .gallery-wrap .gallery-right .gallery a img {
        max-width: 100px;
        height: auto;
        margin: 3px 6px 3px 0; }

#products .contentWrap {
  margin: 60px auto 0px;
  text-align: center; }
  #products .contentWrap .product {
    padding: 0 0 30px;
    margin: 0px 12px 30px;
    border-bottom: 1px solid #c8c8c8; }
    #products .contentWrap .product .product-title {
      font: 700 21px/27px "Open Sans", sans-serif;
      margin-bottom: 6px; }
    #products .contentWrap .product .product-desc {
      font: 400 14px/21px "Open Sans", sans-serif;
      margin-bottom: 12px; }
    #products .contentWrap .product .product-images img.product-image {
      margin: 3px;
      vertical-align: top; }

body.blog main,
body.archive main,
body.single-post main,
body.single-products main,
body.single-process main {
  background-color: #fff;
  color: #000; }
  body.blog main a,
  body.archive main a,
  body.single-post main a,
  body.single-products main a,
  body.single-process main a {
    color: #000; }
    body.blog main a:hover,
    body.archive main a:hover,
    body.single-post main a:hover,
    body.single-products main a:hover,
    body.single-process main a:hover {
      color: #000; }

body.single-products #blog_post,
body.single-process #blog_post {
  box-shadow: none; }

#staff {
  padding: 60px 0 60px;
  margin: 0; }
  #staff .sectionContainer {
    max-width: 100%;
    padding: 0;
    margin: 0; }
    #staff .sectionContainer .section-arrow {
      text-align: center;
      height: 30px;
      width: 50px;
      display: block;
      margin: 0 auto 12px;
      background-image: url("img/ms_arrow.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain; }
    #staff .sectionContainer .section-title {
      max-width: 700px;
      padding: 12px;
      margin: 0 auto;
      text-align: center;
      font: 36px/42px "Oswald", sans-serif; }
    #staff .sectionContainer .section-overview {
      max-width: 700px;
      padding: 12px;
      margin: 0 auto;
      font: 18px/24px "Open Sans", sans-serif; }
    #staff .sectionContainer .staff-wrapper {
      background-color: #fff; }
      #staff .sectionContainer .staff-wrapper .staff-wrapper-inner {
        max-width: 1200px;
        margin: 0 auto;
        padding: 0.5% 0.5%; }
      #staff .sectionContainer .staff-wrapper .staff-item {
        display: inline-block;
        vertical-align: top;
        position: relative;
        width: 32.3333%;
        margin: 0.5%;
        height: 0;
        padding-bottom: 27%; }
        #staff .sectionContainer .staff-wrapper .staff-item .item-image {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover; }
        #staff .sectionContainer .staff-wrapper .staff-item .item-overlay {
          position: absolute;
          top: auto;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(26, 117, 249, 0.85);
          text-decoration: none;
          padding: 6px 12px 6px;
          -webkit-transition: all 300ms ease;
          transition: all 300ms ease; }
          #staff .sectionContainer .staff-wrapper .staff-item .item-overlay .item-name {
            color: #fff;
            text-align: center;
            font-size: 21px;
            line-height: 24px;
            letter-spacing: 0.05em;
            -webkit-transition: all 300ms ease;
            transition: all 300ms ease; }
          #staff .sectionContainer .staff-wrapper .staff-item .item-overlay .item-title {
            color: #fff;
            text-align: center;
            font-size: 18px;
            line-height: 21px;
            font-weight: 300;
            letter-spacing: 0.05em;
            -webkit-transition: all 300ms ease;
            transition: all 300ms ease; }
          #staff .sectionContainer .staff-wrapper .staff-item .item-overlay .item-email {
            display: block;
            color: #fff;
            text-align: center;
            font-size: 18px;
            line-height: 21px;
            font-weight: 300;
            letter-spacing: 0.05em;
            -webkit-transition: all 300ms ease;
            transition: all 300ms ease; }
          #staff .sectionContainer .staff-wrapper .staff-item .item-overlay:hover {
            padding: 9px 12px 15px;
            text-shadow: 0 0 6px rgba(0, 0, 0, 0.5); }

#singleTitle {
  padding: 60px 0px 30px; }

#singleGenericContent {
  padding: 0px 0px 30px; }

section.page_section.hasGallery {
  padding: 30px 0px 30px; }

/*----------------------------------------------------------------------------*/
/*	HEADER
/*----------------------------------------------------------------------------*/
header {
  position: fixed;
  top: 0;
  height: 60px;
  width: 100%;
  text-align: center;
  background-color: #000;
  z-index: 1000; }
  @media screen and (max-width: 700px) {
    header {
      background-color: rgba(0, 0, 0, 0.65);
      height: 60px; } }
  header .headerContainer {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
    overflow: hidden;
    padding: 0 24px; }
    @media screen and (max-width: 1200px) {
      header .headerContainer {
        padding: 0 2vw; } }
    @media screen and (max-width: 900px) {
      header .headerContainer {
        padding: 0; } }

#nav {
  display: inline-block;
  vertical-align: top;
  list-style: none;
  margin-left: 0;
  margin-bottom: 0;
  text-align: center; }
  @media screen and (max-width: 700px) {
    #nav {
      display: none; } }
  #nav .menu-item {
    display: inline-block; }
    #nav .menu-item a {
      color: #fff;
      display: block;
      font-family: "Oswald", sans-serif;
      font-size: 18px;
      line-height: 60px;
      padding: 0 30px;
      text-decoration: none; }
      @media screen and (max-width: 1200px) {
        #nav .menu-item a {
          padding: 0 20px; } }
      @media screen and (max-width: 900px) {
        #nav .menu-item a {
          padding: 0 9px; } }
      #nav .menu-item a:hover {
        color: #1ab1f9;
        text-decoration: underline;
        text-shadow: none; }

#mobile-logo {
  display: inline-block;
  vertical-align: top;
  margin-left: 0px;
  margin-top: 5px;
  height: 50px;
  width: 88px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease; }
  @media screen and (max-width: 700px) {
    #mobile-logo {
      display: block;
      margin-left: 24px; } }

.home #mobile-logo {
  -webkit-transform: translateY(-100px);
  -moz-transform: translateY(-100px);
  -ms-transform: translateY(-100px);
  transform: translateY(-100px); }

.home.homeLogo #mobile-logo {
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px); }

/*----------------------------------------------------------------------------*/
/*	HOME
/*----------------------------------------------------------------------------*/
body.home main {
  padding-top: 60px; }

.main-logo-dark {
  opacity: 0;
  animation: mainLogoDark 3s linear; }

@keyframes mainLogoDark {
  0% {
    opacity: 1; }
  19% {
    opacity: 1; }
  20% {
    opacity: 0; }
  23% {
    opacity: 0; }
  24% {
    opacity: 1; }
  25% {
    opacity: 0; }
  26% {
    opacity: 1; }
  27% {
    opacity: 1; }
  44% {
    opacity: 1; }
  45% {
    opacity: 0; }
  59% {
    opacity: 0; }
  60% {
    opacity: 1; }
  63% {
    opacity: 1; }
  64% {
    opacity: 0; }
  66% {
    opacity: 0; }
  67% {
    opacity: 1; }
  69% {
    opacity: 1; }
  70% {
    opacity: 0; }
  100% {
    opacity: 0; } }

.main-logo-half {
  opacity: 0;
  animation: mainLogoHalf 3s linear; }

@keyframes mainLogoHalf {
  0% {
    opacity: 1; }
  44% {
    opacity: 1; }
  70% {
    opacity: 0; }
  71% {
    opacity: 1; }
  95% {
    opacity: 0; }
  100% {
    opacity: 0; } }

.main-logo-lit {
  opacity: 1;
  animation: mainLogoLit 3s linear; }

@keyframes mainLogoLit {
  0% {
    opacity: 1; }
  100% {
    opacity: 1; } }

.home.crushy #hero {
  height: 40vh; }
  .home.crushy #hero #logo-home {
    height: 30vh; }

.home #hero {
  position: relative;
  width: 100%;
  height: 95vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: scroll;
  -webkit-transition: height 300ms ease;
  transition: height 300ms ease; }
  @media all and (max-width: 700px) {
    .home #hero {
      background-attachment: scroll;
      height: 500px; } }
  .home #hero #logo-home {
    position: relative;
    text-align: center;
    height: 95vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 1000px;
    max-width: 100%;
    margin: 0px auto;
    -webkit-transition: opacity 2000ms linear, height 300ms ease;
    transition: opacity 2000ms linear, height 300ms ease; }
    @media all and (max-width: 700px) {
      .home #hero #logo-home {
        background-attachment: scroll;
        height: 500px; } }
    .home #hero #logo-home.logo-main-animated {
      -webkit-transition: opacity 0ms linear, width 300ms ease;
      transition: opacity 0ms linear, width 300ms ease; }
      .home #hero #logo-home.logo-main-animated #logo-lit {
        content: '';
        position: absolute;
        top: 0px;
        left: 0;
        right: 0;
        bottom: 0px;
        background-image: url("img/msg-logo-lisa-c1r1.jpg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        -webkit-transition: opacity 0ms linear;
        transition: opacity 0ms linear; }
      .home #hero #logo-home.logo-main-animated #logo-half {
        content: '';
        position: absolute;
        top: 0px;
        left: 0;
        right: 0;
        bottom: 0px;
        background-image: url("img/msg-logo-lisa-c0r1.jpg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        -webkit-transition: opacity 0ms linear;
        transition: opacity 0ms linear; }
      .home #hero #logo-home.logo-main-animated #logo-dark {
        content: '';
        position: absolute;
        top: 0px;
        left: 0;
        right: 0;
        bottom: 0px;
        background-image: url("img/msg-logo-lisa-c0r0.jpg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        -webkit-transition: opacity 0ms linear;
        transition: opacity 0ms linear; }
    .home #hero #logo-home a {
      position: absolute;
      top: 0px;
      left: 0;
      right: 0;
      bottom: 0px; }

@-webkit-keyframes goCircle {
  0% {
    top: 20%; }
  80% {
    top: 70%;
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    top: 20%;
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes goCircle {
  0% {
    top: 20%; }
  80% {
    top: 70%;
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    top: 20%;
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg); } }
  .home #hero #goCircle {
    cursor: pointer; }
  .home #hero .go-circle {
    position: absolute;
    bottom: 20px;
    left: 50%;
    width: 48px;
    height: 48px;
    margin-left: -24px;
    border: 2px solid #1ab1f9;
    border-radius: 50%;
    background-color: transparent;
    background-color: rgba(0, 0, 0, 0.35); }
    @media screen and (max-width: 700px) {
      .home #hero .go-circle {
        display: none; } }
    .home #hero .go-circle:after {
      content: '';
      height: 9px;
      width: 18px;
      position: absolute;
      top: 40%;
      left: 50%;
      margin-left: -9px;
      background-image: url(img/arrow_dn_blue.png);
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
      -webkit-animation-name: goCircle;
      -webkit-animation-duration: 1.5s;
      -webkit-animation-iteration-count: infinite;
      -webkit-animation-timing-function: ease-out;
      -webkit-animation-fill-mode: forwards;
      animation-name: goCircle;
      animation-duration: 1.5s;
      animation-iteration-count: infinite;
      animation-timing-function: ease-out;
      animation-fill-mode: forwards; }

.home #about, .home #products, .home #process, .home #signs, .home #socialfeed {
  padding: 60px 0 60px;
  margin: 0; }
  .home #about .sectionContainer, .home #products .sectionContainer, .home #process .sectionContainer, .home #signs .sectionContainer, .home #socialfeed .sectionContainer {
    max-width: 100%;
    padding: 0;
    margin: 0; }
    .home #about .sectionContainer .section-arrow, .home #products .sectionContainer .section-arrow, .home #process .sectionContainer .section-arrow, .home #signs .sectionContainer .section-arrow, .home #socialfeed .sectionContainer .section-arrow {
      text-align: center;
      height: 30px;
      width: 50px;
      display: block;
      margin: 0 auto 12px;
      background-image: url("img/ms_arrow.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain; }
    .home #about .sectionContainer .section-title, .home #products .sectionContainer .section-title, .home #process .sectionContainer .section-title, .home #signs .sectionContainer .section-title, .home #socialfeed .sectionContainer .section-title {
      max-width: 700px;
      padding: 12px;
      margin: 0 auto;
      text-align: center;
      font: 36px/42px "Oswald", sans-serif; }
    .home #about .sectionContainer .section-overview, .home #products .sectionContainer .section-overview, .home #process .sectionContainer .section-overview, .home #signs .sectionContainer .section-overview, .home #socialfeed .sectionContainer .section-overview {
      max-width: 700px;
      padding: 12px;
      margin: 0 auto;
      font: 18px/24px "Open Sans", sans-serif; }

.home #about {
  padding: 60px 0 0; }

.home #history {
  margin-bottom: 60px; }

.home #products .blocks-wrapper, .home #signs .blocks-wrapper, .home #socialfeed .blocks-wrapper {
  text-align: center;
  background-color: #fff;
  padding: 0.5% 0.5%; }
  @media screen and (max-width: 500px) {
    .home #products .blocks-wrapper, .home #signs .blocks-wrapper, .home #socialfeed .blocks-wrapper {
      padding: 1% 1%; } }
  .home #products .blocks-wrapper .block-item, .home #signs .blocks-wrapper .block-item, .home #socialfeed .blocks-wrapper .block-item {
    display: inline-block;
    vertical-align: top;
    position: relative;
    height: 0;
    margin: 0.5%;
    overflow: hidden;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; }
    @media screen and (max-width: 500px) {
      .home #products .blocks-wrapper .block-item, .home #signs .blocks-wrapper .block-item, .home #socialfeed .blocks-wrapper .block-item {
        margin: 1%; } }
    .home #products .blocks-wrapper .block-item .item-overlay, .home #signs .blocks-wrapper .block-item .item-overlay, .home #socialfeed .blocks-wrapper .block-item .item-overlay {
      position: absolute;
      top: auto;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(26, 117, 249, 0.85);
      text-decoration: none;
      padding: 6px 12px 6px;
      -webkit-transition: all 300ms ease;
      transition: all 300ms ease; }
      @media screen and (max-width: 1600px) {
        .home #products .blocks-wrapper .block-item .item-overlay, .home #signs .blocks-wrapper .block-item .item-overlay, .home #socialfeed .blocks-wrapper .block-item .item-overlay {
          padding: 0.375vw 0.75vw 0.375vw; } }
      @media screen and (max-width: 900px) {
        .home #products .blocks-wrapper .block-item .item-overlay, .home #signs .blocks-wrapper .block-item .item-overlay, .home #socialfeed .blocks-wrapper .block-item .item-overlay {
          padding: 0.6666vw 1.3333vw 0.6666vw; } }
      @media screen and (max-width: 500px) {
        .home #products .blocks-wrapper .block-item .item-overlay, .home #signs .blocks-wrapper .block-item .item-overlay, .home #socialfeed .blocks-wrapper .block-item .item-overlay {
          padding: 1.2vw 2.4vw 1.2vw; } }
      .home #products .blocks-wrapper .block-item .item-overlay .item-title, .home #signs .blocks-wrapper .block-item .item-overlay .item-title, .home #socialfeed .blocks-wrapper .block-item .item-overlay .item-title {
        color: #fff;
        text-align: center;
        font-size: 21px;
        line-height: 30px;
        font-weight: 300;
        letter-spacing: 0.05em;
        -webkit-transition: all 300ms ease;
        transition: all 300ms ease; }
        @media screen and (max-width: 1600px) {
          .home #products .blocks-wrapper .block-item .item-overlay .item-title, .home #signs .blocks-wrapper .block-item .item-overlay .item-title, .home #socialfeed .blocks-wrapper .block-item .item-overlay .item-title {
            font-size: 1.3125vw;
            line-height: 1.875vw; } }
        @media screen and (max-width: 900px) {
          .home #products .blocks-wrapper .block-item .item-overlay .item-title, .home #signs .blocks-wrapper .block-item .item-overlay .item-title, .home #socialfeed .blocks-wrapper .block-item .item-overlay .item-title {
            font-size: 2.3333vw;
            line-height: 3.3333vw; } }
        @media screen and (max-width: 500px) {
          .home #products .blocks-wrapper .block-item .item-overlay .item-title, .home #signs .blocks-wrapper .block-item .item-overlay .item-title, .home #socialfeed .blocks-wrapper .block-item .item-overlay .item-title {
            font-size: 4.2vw;
            line-height: 6vw; } }
    .home #products .blocks-wrapper .block-item:hover .item-overlay .item-title, .home #signs .blocks-wrapper .block-item:hover .item-overlay .item-title, .home #socialfeed .blocks-wrapper .block-item:hover .item-overlay .item-title {
      text-shadow: 0 0 6px rgba(0, 0, 0, 0.5); }

.home #products .blocks-wrapper .block-item {
  width: 32.333%;
  padding-bottom: 20%; }
  @media screen and (max-width: 900px) {
    .home #products .blocks-wrapper .block-item {
      width: 49%;
      padding-bottom: 30%; } }
  @media screen and (max-width: 500px) {
    .home #products .blocks-wrapper .block-item {
      width: 98%;
      padding-bottom: 60%; } }
  .home #products .blocks-wrapper .block-item .item-overlay .item-title .item-title-first {
    font-size: 36px;
    line-height: 42px; }
    @media screen and (max-width: 1600px) {
      .home #products .blocks-wrapper .block-item .item-overlay .item-title .item-title-first {
        font-size: 2.25vw;
        line-height: 2.625vw; } }
    @media screen and (max-width: 900px) {
      .home #products .blocks-wrapper .block-item .item-overlay .item-title .item-title-first {
        font-size: 4vw;
        line-height: 4.66666vw; } }
    @media screen and (max-width: 500px) {
      .home #products .blocks-wrapper .block-item .item-overlay .item-title .item-title-first {
        font-size: 7.2vw;
        line-height: 8.4vw; } }
  .home #products .blocks-wrapper .block-item .item-overlay .item-title .item-title-rest {
    font-size: 21px;
    line-height: 27px; }
    @media screen and (max-width: 1600px) {
      .home #products .blocks-wrapper .block-item .item-overlay .item-title .item-title-rest {
        font-size: 1.3125vw;
        line-height: 1.6875vw; } }
    @media screen and (max-width: 900px) {
      .home #products .blocks-wrapper .block-item .item-overlay .item-title .item-title-rest {
        font-size: 2.33333vw;
        line-height: 3vw; } }
    @media screen and (max-width: 500px) {
      .home #products .blocks-wrapper .block-item .item-overlay .item-title .item-title-rest {
        font-size: 4.2vw;
        line-height: 5.4vw; } }
  .home #products .blocks-wrapper .block-item:hover .item-overlay {
    padding: 9px 12px 15px; }
    @media screen and (max-width: 1600px) {
      .home #products .blocks-wrapper .block-item:hover .item-overlay {
        padding: 0.5625vw 0.75vw 0.9375vw; } }
    @media screen and (max-width: 900px) {
      .home #products .blocks-wrapper .block-item:hover .item-overlay {
        padding: 1vw 1.33333vw 1.66666vw; } }
    @media screen and (max-width: 500px) {
      .home #products .blocks-wrapper .block-item:hover .item-overlay {
        padding: 1.8vw 2.4vw 3vw; } }

.home #signs .blocks-wrapper .block-item {
  width: 24%;
  padding-bottom: 17%; }
  @media screen and (max-width: 900px) {
    .home #signs .blocks-wrapper .block-item {
      width: 49%;
      padding-bottom: 30%; } }
  @media screen and (max-width: 500px) {
    .home #signs .blocks-wrapper .block-item {
      width: 98%;
      padding-bottom: 60%; } }
  .home #signs .blocks-wrapper .block-item .item-overlay .item-title {
    min-height: 60px; }
    @media screen and (max-width: 1600px) {
      .home #signs .blocks-wrapper .block-item .item-overlay .item-title {
        min-height: 3.75vw; } }
    @media screen and (max-width: 900px) {
      .home #signs .blocks-wrapper .block-item .item-overlay .item-title {
        min-height: 6.6666vw; } }
    @media screen and (max-width: 500px) {
      .home #signs .blocks-wrapper .block-item .item-overlay .item-title {
        min-height: 12vw; } }
  .home #signs .blocks-wrapper .block-item:hover .item-overlay {
    padding: 9px 12px 15px; }
    @media screen and (max-width: 1600px) {
      .home #signs .blocks-wrapper .block-item:hover .item-overlay {
        padding: 0.5625vw 0.75vw 0.9375vw; } }
    @media screen and (max-width: 900px) {
      .home #signs .blocks-wrapper .block-item:hover .item-overlay {
        padding: 1vw 1.33333vw 1.66666vw; } }
    @media screen and (max-width: 500px) {
      .home #signs .blocks-wrapper .block-item:hover .item-overlay {
        padding: 1.8vw 2.4vw 3vw; } }

.home #socialfeed .blocks-wrapper .block-item {
  width: 24%;
  padding-bottom: 17%; }
  @media screen and (max-width: 900px) {
    .home #socialfeed .blocks-wrapper .block-item {
      width: 49%;
      padding-bottom: 30%; } }
  @media screen and (max-width: 500px) {
    .home #socialfeed .blocks-wrapper .block-item {
      width: 98%;
      padding-bottom: 60%; } }
  .home #socialfeed .blocks-wrapper .block-item .item-overlay {
    transform: translateY(100%); }
  .home #socialfeed .blocks-wrapper .block-item:hover .item-overlay {
    transform: translateY(0); }

.home #process .process-wrapper {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0.5% 4.5%; }
  .home #process .process-wrapper .process-item {
    display: inline-block;
    vertical-align: top;
    text-decoration: none;
    position: relative;
    width: 25.333%;
    margin: 4%; }
    @media screen and (max-width: 600px) {
      .home #process .process-wrapper .process-item {
        width: 42%; } }
    .home #process .process-wrapper .process-item .item-image-wrap {
      margin: 0 auto;
      width: 240px; }
      @media screen and (max-width: 1000px) {
        .home #process .process-wrapper .process-item .item-image-wrap {
          width: 100%; } }
      .home #process .process-wrapper .process-item .item-image-wrap .item-image {
        display: block;
        height: 0;
        padding-bottom: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover; }
    .home #process .process-wrapper .process-item .item-title {
      text-align: center;
      text-transform: uppercase;
      color: #eee;
      font: 30px/42px "Oswald", sans-serif;
      text-decoration: none;
      -webkit-transition: all 200ms ease;
      transition: all 200ms ease; }
      @media screen and (max-width: 1000px) {
        .home #process .process-wrapper .process-item .item-title {
          font: 3vw/4.2vw "Oswald", sans-serif; } }
      @media screen and (max-width: 600px) {
        .home #process .process-wrapper .process-item .item-title {
          font: 6vw/8.4vw "Oswald", sans-serif; } }
    .home #process .process-wrapper .process-item:hover {
      text-decoration: none; }
      .home #process .process-wrapper .process-item:hover .item-title {
        text-decoration: none;
        color: #1ab1f9; }

/*----------------------------------------------------------------------------*/
/*	CONTENT SECTION
/*----------------------------------------------------------------------------*/
.contents {
  padding: 30px;
  margin: 0 auto;
  max-width: 900px;
  position: relative; }
  @media all and (max-width: 600px) {
    .contents {
      padding: 12px; } }
  .contents img {
    max-width: 50%; }
    @media all and (max-width: 600px) {
      .contents img {
        max-width: 100%; } }
    .contents img.alignleft {
      max-width: 30%;
      margin: 0 .25em .25em 0; }
    .contents img.alignright {
      max-width: 30%;
      margin: 0 0 .25em .25em; }
    .contents img.aligncenter {
      max-width: 30%; }

.pre_content, .post_content {
  padding: 30px;
  margin: 0 auto;
  max-width: 900px; }
  .pre_content img, .post_content img {
    max-width: 50%; }
    @media all and (max-width: 600px) {
      .pre_content img, .post_content img {
        max-width: 100%; } }
    .pre_content img.alignleft, .post_content img.alignleft {
      max-width: 30%;
      margin: 0 .25em .25em 0; }
    .pre_content img.alignright, .post_content img.alignright {
      max-width: 30%;
      margin: 0 0 .25em .25em; }
    .pre_content img.aligncenter, .post_content img.aligncenter {
      max-width: 30%; }

.content-cols-wrap {
  text-align: left;
  font: 300 18px/24px "Open Sans", sans-serif; }
  @media all and (max-width: 1200px) {
    .content-cols-wrap {
      font: 300 1.5vw/2vw "Open Sans", sans-serif; } }
  @media all and (max-width: 900px) {
    .content-cols-wrap {
      font: 300 14px/18px "Open Sans", sans-serif; } }
  .content-cols-wrap .content-col-img {
    width: 40%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; }
  .content-cols-wrap .content-col-img-left {
    float: left; }
    @media all and (max-width: 700px) {
      .content-cols-wrap .content-col-img-left {
        width: 100%;
        float: none; } }
  .content-cols-wrap .content-col-img-right {
    float: right; }
    @media all and (max-width: 700px) {
      .content-cols-wrap .content-col-img-right {
        width: 100%;
        float: none; } }
  .content-cols-wrap .content-col-map {
    width: 40%; }
  .content-cols-wrap .content-col-map-lmap {
    float: left; }
    @media all and (max-width: 700px) {
      .content-cols-wrap .content-col-map-lmap {
        width: 100%;
        float: none; } }
  .content-cols-wrap .content-col-map-rmap {
    float: right; }
    @media all and (max-width: 700px) {
      .content-cols-wrap .content-col-map-rmap {
        width: 100%;
        float: none; } }
  .content-cols-wrap .content-col-info {
    width: 58%;
    padding: 60px 42px 42px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    position: relative; }
    @media all and (max-width: 1200px) {
      .content-cols-wrap .content-col-info {
        padding: 60px 3.5vw 42px; } }
    @media all and (max-width: 900px) {
      .content-cols-wrap .content-col-info {
        width: 57%; } }
    @media all and (max-width: 700px) {
      .content-cols-wrap .content-col-info {
        padding: 60px 21px 42px;
        width: 100%; } }
    .content-cols-wrap .content-col-info.visibility_dim {
      color: #fff; }
      .content-cols-wrap .content-col-info.visibility_dim a, .content-cols-wrap .content-col-info.visibility_dim p {
        color: #fff; }
      .content-cols-wrap .content-col-info.visibility_dim::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.5); }
      .content-cols-wrap .content-col-info.visibility_dim .container {
        color: #fff; }
    .content-cols-wrap .content-col-info.visibility_light::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(255, 255, 255, 0.65); }
    .content-cols-wrap .content-col-info.motion_fixed {
      background-attachment: fixed; }
  .content-cols-wrap .content-col-info-left, .content-cols-wrap .content-col-info-lmap {
    float: right; }
  .content-cols-wrap .content-col-info-right, .content-cols-wrap .content-col-info-rmap {
    float: left; }

/*----------------------------------------------------------------------------*/
/*	COLUMN BLOCKS SECTION
/*----------------------------------------------------------------------------*/
.col-blocks {
  padding: 30px;
  margin: 0 auto;
  max-width: 1100px; }
  @media (max-width: 1224px) {
    .col-blocks {
      padding: 0 12px; } }

.col-block {
  width: 100%; }
  .col-block .col-block-imgs {
    display: inline-block;
    vertical-align: top;
    width: 40%; }
    @media (max-width: 700px) {
      .col-block .col-block-imgs {
        width: 100%; } }
    .col-block .col-block-imgs .col-block-img {
      width: 100%;
      height: auto;
      margin-bottom: 6px; }
  .col-block .col-block-content {
    display: inline-block;
    vertical-align: top;
    width: 60%;
    padding: 0 12px 0 36px; }
    @media (max-width: 700px) {
      .col-block .col-block-content {
        width: 100%; } }

/*----------------------------------------------------------------------------*/
/*	GALLERY SECTION
/*----------------------------------------------------------------------------*/
.gallery-image {
  margin: 1%;
  height: 0;
  padding-bottom: 23%;
  position: relative;
  width: 23%;
  display: inline-block;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; }
  @media all and (max-width: 900px) {
    .gallery-image {
      width: 31%;
      padding-bottom: 31%; } }
  @media all and (max-width: 600px) {
    .gallery-image {
      width: 46%;
      padding-bottom: 46%;
      margin: 2%; } }
  @media all and (max-width: 400px) {
    .gallery-image {
      width: 98%;
      padding-bottom: 98%;
      margin: 1%; } }

/*----------------------------------------------------------------------------*/
/*	SLIDESHOW SECTION
/*----------------------------------------------------------------------------*/
#slideshow {
  height: 600px;
  position: relative;
  overflow: hidden; }
  @media screen and (max-width: 1200px) {
    #slideshow {
      height: 50vw; } }
  @media screen and (max-width: 600px) {
    #slideshow {
      height: 300px; } }
  #slideshow #slides {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #444; }
    #slideshow #slides .slide {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      z-index: 0;
      opacity: 0;
      -webkit-transition: all 800ms ease;
      transition: all 800ms ease; }
      #slideshow #slides .slide.active {
        z-index: 1;
        opacity: 1;
        -webkit-transition: all 800ms ease;
        transition: all 800ms ease; }
  #slideshow #slide_nav {
    position: absolute;
    z-index: 2;
    bottom: 30px;
    left: 50%;
    margin-left: -540px; }
    @media screen and (max-width: 1200px) {
      #slideshow #slide_nav {
        bottom: 2.5vw;
        left: 5vw;
        margin-left: 0; } }
    @media all and (max-width: 600px) {
      #slideshow #slide_nav {
        display: none; } }
    #slideshow #slide_nav .radio_wrap {
      display: inline-block;
      width: 21px;
      height: 21px;
      border-radius: 50%;
      margin-right: 6px;
      cursor: pointer;
      box-sizing: border-box;
      -webkit-transition: all 200ms ease;
      transition: all 200ms ease;
      border: solid 2px #fff;
      background-color: transparent;
      background-color: rgba(0, 0, 0, 0.15); }
      #slideshow #slide_nav .radio_wrap:hover {
        border: solid 2px #fff;
        background-color: #8CD7FF; }
      #slideshow #slide_nav .radio_wrap.checked {
        border: solid 2px #fff;
        background-color: #1ab1f9; }
        #slideshow #slide_nav .radio_wrap.checked:hover {
          border: solid 2px #fff;
          background-color: #8CD7FF; }

.slide_text {
  position: absolute;
  bottom: 84px;
  text-align: left;
  left: 50%;
  margin-left: -540px;
  max-width: 600px; }
  @media screen and (max-width: 1200px) {
    .slide_text {
      bottom: 7vw;
      left: 5vw;
      margin-left: 0;
      max-width: 50vw; } }
  @media screen and (max-width: 600px) {
    .slide_text {
      max-width: 300px; } }
  .slide_text .slide_title {
    font: 60px/60px "Oswald", sans-serif;
    letter-spacing: .025em;
    color: #fff;
    text-shadow: 0 0 18px rgba(0, 0, 0, 0.75);
    margin-bottom: 18px; }
    @media screen and (max-width: 1200px) {
      .slide_text .slide_title {
        line-height: 5vw;
        font-size: 5vw;
        text-shadow: 0 0 1.5vw rgba(0, 0, 0, 0.75);
        margin-bottom: 2.5vw; } }
    @media screen and (max-width: 600px) {
      .slide_text .slide_title {
        line-height: 30px;
        font-size: 30px;
        text-shadow: 0 0 9px rgba(0, 0, 0, 0.75);
        margin-bottom: 15px; } }
  .slide_text .slide_subtitle {
    font: 30px/36px "Oswald", sans-serif;
    letter-spacing: .025em;
    color: #fff;
    text-shadow: 0 0 12px rgba(0, 0, 0, 0.75); }
    @media screen and (max-width: 1200px) {
      .slide_text .slide_subtitle {
        line-height: 3vw;
        font-size: 2.5vw;
        text-shadow: 0 0 1vw rgba(0, 0, 0, 0.75); } }
    @media screen and (max-width: 600px) {
      .slide_text .slide_subtitle {
        line-height: 18px;
        font-size: 15px;
        text-shadow: 0 0 6px rgba(0, 0, 0, 0.75); } }
    .slide_text .slide_subtitle p {
      font: 30px/36px "Oswald", sans-serif;
      letter-spacing: .025em;
      color: #fff;
      text-shadow: 0 0 12px rgba(0, 0, 0, 0.75);
      margin-bottom: 18px; }
      @media screen and (max-width: 1200px) {
        .slide_text .slide_subtitle p {
          line-height: 3vw;
          font-size: 2.5vw;
          text-shadow: 0 0 1vw rgba(0, 0, 0, 0.75);
          margin-bottom: 1.5vw; } }
      @media screen and (max-width: 600px) {
        .slide_text .slide_subtitle p {
          line-height: 18px;
          font-size: 15px;
          text-shadow: 0 0 6px rgba(0, 0, 0, 0.75);
          margin-bottom: 9px; } }
    .slide_text .slide_subtitle a {
      display: inline-block;
      padding: 6px 18px;
      border-radius: 0px;
      line-height: 36px;
      font-size: 30px;
      letter-spacing: .025em;
      font-weight: 700;
      text-decoration: none;
      color: #fff;
      background-color: #1ab1f9;
      border: 2px solid #8CD7FF;
      cursor: pointer;
      text-shadow: none;
      -webkit-transition: all 200ms ease;
      transition: all 200ms ease; }
      @media screen and (max-width: 1200px) {
        .slide_text .slide_subtitle a {
          padding: 0.5vw 1.5vw;
          border-radius: 4px;
          line-height: 3vw;
          font-size: 2.5vw; } }
      @media screen and (max-width: 600px) {
        .slide_text .slide_subtitle a {
          padding: 3px 9px;
          border-radius: 3px;
          line-height: 18px;
          font-size: 15px; } }
      .slide_text .slide_subtitle a:hover {
        color: #1ab1f9;
        background-color: #fff;
        text-decoration: none; }
  .slide_text.use_dark .slide_title, .slide_text.use_dark .slide_subtitle, .slide_text.use_dark .slide_subtitle p {
    color: #0776ab;
    text-shadow: 0 0 12px rgba(255, 255, 255, 0.35); }
    @media screen and (max-width: 1200px) {
      .slide_text.use_dark .slide_title, .slide_text.use_dark .slide_subtitle, .slide_text.use_dark .slide_subtitle p {
        text-shadow: 0 0 1vw rgba(255, 255, 255, 0.35); } }
    @media screen and (max-width: 600px) {
      .slide_text.use_dark .slide_title, .slide_text.use_dark .slide_subtitle, .slide_text.use_dark .slide_subtitle p {
        text-shadow: 0 0 6px rgba(255, 255, 255, 0.35); } }

.slide_credit {
  position: absolute;
  bottom: 0px;
  right: 0px;
  margin-left: 200px;
  padding: 18px 18px 18px 38px;
  text-align: right;
  border-top: 1px solid #fff;
  background: -moz-linear-gradient(left, transparent 0%, rgba(0, 0, 0, 0.01) 4%, rgba(0, 0, 0, 0.6) 30%, rgba(0, 0, 0, 0.8) 100%);
  background: -webkit-linear-gradient(left, transparent 0%, rgba(0, 0, 0, 0.01) 4%, rgba(0, 0, 0, 0.6) 30%, rgba(0, 0, 0, 0.8) 100%);
  background: linear-gradient(to right, transparent 0%, rgba(0, 0, 0, 0.01) 4%, rgba(0, 0, 0, 0.6) 30%, rgba(0, 0, 0, 0.8) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#cc000000',GradientType=1 ); }
  @media all and (max-width: 1200px) {
    .slide_credit {
      padding: 1.5vw 3vw 1.5vw 1.5vw; } }
  @media all and (max-width: 900px) {
    .slide_credit {
      padding: 14px 30px 14px 14px; } }
  @media all and (max-width: 600px) {
    .slide_credit {
      min-width: 200px;
      max-width: 100%;
      margin-right: 0px; } }
  .slide_credit .slide_credit_text {
    font: 300 14px/18px "Open Sans", sans-serif;
    color: #fff;
    text-align: left;
    text-shadow: 0 1px 12px rgba(0, 0, 0, 0.8); }
    @media all and (max-width: 1200px) {
      .slide_credit .slide_credit_text {
        font: 300 1.6666vw/1.5vw "Open Sans", sans-serif; } }
    @media all and (max-width: 900px) {
      .slide_credit .slide_credit_text {
        font: 300 11px/14px "Open Sans", sans-serif; } }
    .slide_credit .slide_credit_text a {
      color: #1ab1f9; }
      .slide_credit .slide_credit_text a:hover {
        text-decoration: underline; }
    .slide_credit .slide_credit_text strong {
      font-weight: 700; }
    .slide_credit .slide_credit_text em, .slide_credit .slide_credit_text i {
      font-family: "Oswald", sans-serif;
      font-style: italic; }
    .slide_credit .slide_credit_text ol, .slide_credit .slide_credit_text ul {
      margin-left: 20px;
      margin-bottom: 18px; }
      .slide_credit .slide_credit_text ol li, .slide_credit .slide_credit_text ul li {
        margin-bottom: 6px; }
    .slide_credit .slide_credit_text ol {
      list-style: decimal; }
      .slide_credit .slide_credit_text ol ol {
        list-style: lower-roman; }
        .slide_credit .slide_credit_text ol ol ol {
          list-style: lower-alpha; }
    .slide_credit .slide_credit_text ul {
      list-style: square; }
      .slide_credit .slide_credit_text ul ul {
        list-style: circle; }
        .slide_credit .slide_credit_text ul ul ul {
          list-style: disc; }

/*----------------------------------------------------------------------------*/
/*	GALLERY-SLIDER SECTION
/*----------------------------------------------------------------------------*/
.hero_gallery .slider_for {
  background-color: #444;
  position: relative; }
  .hero_gallery .slider_for .slide_img_wrap {
    height: 600px; }
    @media all and (max-width: 1200px) {
      .hero_gallery .slider_for .slide_img_wrap {
        height: 50vw; } }
    @media all and (max-width: 600px) {
      .hero_gallery .slider_for .slide_img_wrap {
        height: 300px; } }
    .hero_gallery .slider_for .slide_img_wrap .slide_img {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }

.hero_gallery .slider_nav {
  background-color: #000;
  position: relative; }
  .hero_gallery .slider_nav .thumb_img_wrap {
    position: relative;
    height: 120px; }
    .hero_gallery .slider_nav .thumb_img_wrap .thumb_img {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }

.hero_gallery .thumb_img {
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  opacity: 0.5; }
  .hero_gallery .thumb_img:hover {
    opacity: 1; }

.hero_gallery .slick-current .thumb_img {
  opacity: 1; }

.hero_gallery .slick-list {
  margin: 0; }

.hero_gallery .slick-prev, .hero_gallery .slick-next {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.5);
  height: 120px;
  width: 40px;
  border-radius: 0%;
  position: absolute;
  top: 50%;
  text-indent: -9999px;
  border: none;
  z-index: 20; }
  .hero_gallery .slick-prev.slick-disabled, .hero_gallery .slick-next.slick-disabled {
    opacity: 0.7;
    cursor: auto; }
  .hero_gallery .slick-prev::after, .hero_gallery .slick-next::after {
    font: 300 24px/20px "Open Sans", sans-serif;
    color: #fff;
    position: absolute;
    top: 53%;
    text-indent: 0;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%); }
  @media all and (max-width: 1200px) {
    .hero_gallery .slick-prev, .hero_gallery .slick-next {
      height: 6vw;
      width: 6vw; }
      .hero_gallery .slick-prev::after, .hero_gallery .slick-next::after {
        font: 300 2vw/1.66666vw "Open Sans", sans-serif; } }
  @media all and (max-width: 900px) {
    .hero_gallery .slick-prev, .hero_gallery .slick-next {
      height: 54px;
      width: 54px; }
      .hero_gallery .slick-prev:after, .hero_gallery .slick-next:after {
        font: 300 18px/15px "Open Sans", sans-serif; } }

.hero_gallery .slick-prev {
  left: 20px;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%); }
  .hero_gallery .slick-prev::after {
    content: '–';
    right: 15px; }
    @media all and (max-width: 1200px) {
      .hero_gallery .slick-prev::after {
        right: 1.25vw; } }

.hero_gallery .slick-next {
  right: 20px;
  -webkit-transform: translateX(50%) translateY(-50%);
  -moz-transform: translateX(50%) translateY(-50%);
  -ms-transform: translateX(50%) translateY(-50%);
  transform: translateX(50%) translateY(-50%); }
  .hero_gallery .slick-next::after {
    content: '+';
    left: 15px; }
    @media all and (max-width: 1200px) {
      .hero_gallery .slick-next::after {
        left: 1.25vw; } }

/*----------------------------------------------------------------------------*/
/*	HERO SLIM
/*----------------------------------------------------------------------------*/
.hero_slim {
  position: relative; }
  .hero_slim .hero_slim_image {
    width: 100%;
    height: 300px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; }
    @media all and (max-width: 900px) {
      .hero_slim .hero_slim_image {
        height: 200px; } }
  .hero_slim .titleContainer {
    max-width: 1200px;
    margin: 0 auto;
    height: 100%;
    position: relative; }
  .hero_slim .hero_slim_title {
    position: absolute;
    top: 70%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 150px;
    color: #fff;
    text-shadow: 0px 0px 12px rgba(0, 0, 0, 0.5);
    text-transform: capitalize; }
    @media all and (max-width: 1200px) {
      .hero_slim .hero_slim_title {
        left: 12.5vw;
        text-shadow: 0 0 1vw rgba(0, 0, 0, 0.25); } }
    @media all and (max-width: 600px) {
      .hero_slim .hero_slim_title {
        left: 5vw;
        text-shadow: 0 0 6px rgba(0, 0, 0, 0.25); } }
    .hero_slim .hero_slim_title.use_dark {
      color: #000;
      text-shadow: 0 0 12px rgba(255, 255, 255, 0.35); }
      @media screen and (max-width: 1200px) {
        .hero_slim .hero_slim_title.use_dark {
          text-shadow: 0 0 1vw rgba(255, 255, 255, 0.35); } }
      @media screen and (max-width: 600px) {
        .hero_slim .hero_slim_title.use_dark {
          text-shadow: 0 0 6px rgba(255, 255, 255, 0.35); } }

/*----------------------------------------------------------------------------*/
/*	VIDEO SECTION
/*----------------------------------------------------------------------------*/
.videos {
  text-align: center; }
  .videos .video_wrap {
    margin: 1%;
    height: 0;
    position: relative;
    width: 48%;
    padding-bottom: 27%;
    display: inline-block; }
    @media all and (max-width: 900px) {
      .videos .video_wrap {
        width: 48%;
        padding-bottom: 27%; } }
    @media all and (max-width: 600px) {
      .videos .video_wrap {
        width: 98%;
        padding-bottom: 56.25%; } }
    .videos .video_wrap .video {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover; }
      .videos .video_wrap .video .video_title {
        position: absolute;
        top: 24px;
        left: 24px;
        right: 24px;
        text-align: center;
        color: #fff;
        font: 18px/24px "Oswald", sans-serif;
        text-transform: uppercase;
        letter-spacing: 0.1em;
        text-shadow: 0 0 12px rgba(0, 0, 0, 0.85); }
        @media all and (max-width: 1200px) {
          .videos .video_wrap .video .video_title {
            top: 2vw;
            left: 2vw;
            right: 2vw;
            font: 1.5vw/2vw "Oswald", sans-serif; } }
        @media all and (max-width: 900px) {
          .videos .video_wrap .video .video_title {
            top: 3vw;
            left: 3vw;
            right: 3vw;
            font: 2.5vw/3vw "Oswald", sans-serif; } }
        @media all and (max-width: 600px) {
          .videos .video_wrap .video .video_title {
            top: 24px;
            left: 24px;
            right: 24px;
            font: 18px/24px "Oswald", sans-serif; } }
  .videos .video_icon {
    position: absolute;
    height: 82px;
    width: 82px;
    bottom: 12px;
    right: 12px;
    border-radius: 50%;
    background-color: #000;
    background-image: url("img/icon_play.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 84px 84px;
    cursor: pointer;
    opacity: 0.5;
    -webkit-transition: all 400ms ease;
    transition: all 400ms ease; }
    @media all and (max-width: 1200px) {
      .videos .video_icon {
        height: 7vw;
        width: 7vw;
        bottom: 1vw;
        right: 1vw;
        background-size: 7vw 7vw; } }
    @media all and (max-width: 900px) {
      .videos .video_icon {
        height: 64px;
        width: 64px;
        bottom: 9px;
        right: 9px;
        background-size: 64px 64px; } }
  .videos .video.play_video:hover .video_icon {
    opacity: 0.9;
    background-color: #000;
    -webkit-transition: all 600ms ease;
    transition: all 600ms ease; }
  .videos.testimonial-showcase-video {
    max-width: 500px;
    margin: 0 auto 30px; }
    .videos.testimonial-showcase-video .video_wrap {
      width: 98%;
      padding-bottom: 56.25%; }
      @media all and (max-width: 900px) {
        .videos.testimonial-showcase-video .video_wrap {
          width: 98%;
          padding-bottom: 56.25%; } }
      @media all and (max-width: 600px) {
        .videos.testimonial-showcase-video .video_wrap {
          width: 98%;
          padding-bottom: 56.25%; } }
      .videos.testimonial-showcase-video .video_wrap .video .video_title {
        position: absolute;
        top: 24px;
        left: 24px;
        right: 24px;
        text-align: center;
        color: #fff;
        font: 24px/36px "Oswald", sans-serif;
        text-transform: uppercase;
        letter-spacing: 0.1em;
        text-shadow: 0 0 12px rgba(0, 0, 0, 0.85); }
        @media all and (max-width: 1200px) {
          .videos.testimonial-showcase-video .video_wrap .video .video_title {
            top: 2vw;
            left: 2vw;
            right: 2vw;
            font: 2vw/3vw "Oswald", sans-serif; } }
        @media all and (max-width: 600px) {
          .videos.testimonial-showcase-video .video_wrap .video .video_title {
            top: 24px;
            left: 24px;
            right: 24px;
            font: 18px/24px "Oswald", sans-serif; } }

/*----------------------------------------------------------------------------*/
/*	FAQ SECTION
/*----------------------------------------------------------------------------*/
@media all and (max-width: 1224px) {
  .faqs {
    padding: 0 12px; } }

.faqs .faq-set {
  text-align: left;
  min-height: 160px;
  position: relative;
  margin-bottom: 24px; }
  .faqs .faq-set .faq-thumb-wrap {
    position: absolute;
    top: 0;
    left: 0;
    height: 160px;
    width: 200px;
    border: 3px solid #1ab1f9; }
    @media all and (max-width: 700px) {
      .faqs .faq-set .faq-thumb-wrap {
        height: 80px;
        width: 100px; } }
    .faqs .faq-set .faq-thumb-wrap .faq-thumb {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      box-shadow: inset 0px 0px 36px rgba(0, 0, 0, 0.5); }
  .faqs .faq-set .faq-letter {
    display: table-cell;
    font: 300 24px/30px "Open Sans", sans-serif;
    color: #1ab1f9;
    padding-right: 10px; }
  .faqs .faq-set .faq-detail {
    display: table-cell; }
  .faqs .faq-set .faq-question {
    padding-left: 220px;
    padding-right: 220px; }
    @media all and (max-width: 900px) {
      .faqs .faq-set .faq-question {
        padding-right: 0px; } }
    @media all and (max-width: 700px) {
      .faqs .faq-set .faq-question {
        padding-left: 110px; } }
  .faqs .faq-set .faq-answer {
    padding-left: 220px;
    padding-right: 220px; }
    @media all and (max-width: 900px) {
      .faqs .faq-set .faq-answer {
        padding-right: 0px; } }
    @media all and (max-width: 700px) {
      .faqs .faq-set .faq-answer {
        padding-left: 110px; } }
    .faqs .faq-set .faq-answer a {
      color: #1ab1f9; }
      .faqs .faq-set .faq-answer a:hover {
        text-decoration: underline; }
    .faqs .faq-set .faq-answer strong {
      font-weight: 700; }
    .faqs .faq-set .faq-answer em, .faqs .faq-set .faq-answer i {
      font-family: "Oswald", sans-serif;
      font-style: italic; }
    .faqs .faq-set .faq-answer ol, .faqs .faq-set .faq-answer ul {
      margin-left: 20px;
      margin-bottom: 18px; }
      .faqs .faq-set .faq-answer ol li, .faqs .faq-set .faq-answer ul li {
        margin-bottom: 6px; }
    .faqs .faq-set .faq-answer ol {
      list-style: decimal; }
      .faqs .faq-set .faq-answer ol ol {
        list-style: lower-roman; }
        .faqs .faq-set .faq-answer ol ol ol {
          list-style: lower-alpha; }
    .faqs .faq-set .faq-answer ul {
      list-style: square; }
      .faqs .faq-set .faq-answer ul ul {
        list-style: circle; }
        .faqs .faq-set .faq-answer ul ul ul {
          list-style: disc; }

/*----------------------------------------------------------------------------*/
/*	INFO REPEATER SECTION
/*----------------------------------------------------------------------------*/
.infos {
  max-width: 900px;
  margin: 0 auto; }
  @media all and (max-width: 1224px) {
    .infos {
      padding: 0 12px; } }
  .infos .info-set {
    text-align: left;
    position: relative;
    margin-bottom: 24px; }
    .infos .info-set .info-set-left {
      display: inline-block;
      vertical-align: top;
      width: 30%;
      padding: 0 12px;
      text-align: right; }
      .infos .info-set .info-set-left .info-title {
        font-weight: 700; }
      .infos .info-set .info-set-left .info-thumb-wrap {
        position: relative;
        height: 160px;
        width: 100%;
        max-width: 200px;
        border: 3px solid #1ab1f9;
        margin-right: 0;
        margin-left: auto; }
        @media all and (max-width: 700px) {
          .infos .info-set .info-set-left .info-thumb-wrap {
            height: 80px;
            max-width: 100px; } }
        .infos .info-set .info-set-left .info-thumb-wrap .info-thumb {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          box-shadow: inset 0px 0px 36px rgba(0, 0, 0, 0.5); }
    .infos .info-set .info-set-right {
      display: inline-block;
      vertical-align: top;
      width: 70%;
      padding: 0 12px; }
      .infos .info-set .info-set-right .info-content a {
        color: #1ab1f9; }
        .infos .info-set .info-set-right .info-content a:hover {
          text-decoration: underline; }
      .infos .info-set .info-set-right .info-content strong {
        font-weight: 700; }
      .infos .info-set .info-set-right .info-content em, .infos .info-set .info-set-right .info-content i {
        font-family: "Oswald", sans-serif;
        font-style: italic; }
      .infos .info-set .info-set-right .info-content ol, .infos .info-set .info-set-right .info-content ul {
        margin-left: 20px;
        margin-bottom: 18px; }
        .infos .info-set .info-set-right .info-content ol li, .infos .info-set .info-set-right .info-content ul li {
          margin-bottom: 6px; }
      .infos .info-set .info-set-right .info-content ol {
        list-style: decimal; }
        .infos .info-set .info-set-right .info-content ol ol {
          list-style: lower-roman; }
          .infos .info-set .info-set-right .info-content ol ol ol {
            list-style: lower-alpha; }
      .infos .info-set .info-set-right .info-content ul {
        list-style: square; }
        .infos .info-set .info-set-right .info-content ul ul {
          list-style: circle; }
          .infos .info-set .info-set-right .info-content ul ul ul {
            list-style: disc; }

/*----------------------------------------------------------------------------*/
/*	QUOTE SECTION
/*----------------------------------------------------------------------------*/
.quote_img_start {
  background: url("img/quote-long.png") center no-repeat;
  height: 43px;
  margin: 20px auto 0; }

.quote_img_end {
  background: url("img/quote-end.png") center no-repeat;
  height: 27px;
  margin: -27px auto 0; }

.quote_content {
  padding: 60px 0 75px;
  margin: 0 auto;
  box-sizing: content-box;
  overflow: hidden; }
  @media screen and (max-width: 700px) {
    .quote_content {
      padding: 40px 0 115px 0; } }
  .quote_content .quote_slide {
    padding: 0 16%; }
    @media screen and (max-width: 700px) {
      .quote_content .quote_slide {
        padding: 0 30px; } }
  .quote_content .quote_copy {
    font: 300 16px/25px "Open Sans", sans-serif;
    font-style: italic;
    letter-spacing: 0.02em;
    margin-bottom: 20px; }
  .quote_content .quote_name {
    font: 400 16px/25px "Oswald", sans-serif;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    margin-bottom: 3px;
    text-align: right; }

.quote_slides {
  position: relative; }
  .quote_slides .quote_slide {
    position: absolute;
    left: 100%;
    width: 100%; }
    .quote_slides .quote_slide.current {
      left: 0;
      -webkit-transition: all 300ms ease;
      transition: all 300ms ease; }
    .quote_slides .quote_slide.prev-left {
      left: -100%;
      -webkit-transition: all 300ms ease;
      transition: all 300ms ease; }
    .quote_slides .quote_slide.prev-right {
      left: 100%;
      -webkit-transition: all 300ms ease;
      transition: all 300ms ease; }
    .quote_slides .quote_slide.before-left {
      left: -100%; }
  .quote_slides .quote_prev, .quote_slides .quote_next {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 27px;
    background-repeat: no-repeat;
    background-size: 28px 48px;
    cursor: pointer; }
    @media screen and (max-width: 700px) {
      .quote_slides .quote_prev, .quote_slides .quote_next {
        bottom: 40px; } }
    .quote_slides .quote_prev:after, .quote_slides .quote_next:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-repeat: no-repeat;
      background-size: 28px 48px;
      opacity: 1;
      -webkit-transition: all 300ms ease;
      transition: all 300ms ease; }
      @media screen and (max-width: 700px) {
        .quote_slides .quote_prev:after, .quote_slides .quote_next:after {
          opacity: 0; } }
    .quote_slides .quote_prev:hover:after, .quote_slides .quote_next:hover:after {
      opacity: 0; }
  .quote_slides .quote_prev {
    right: 50%;
    background-image: url("img/arrow_left-hov.png");
    background-position: 12% center; }
    @media screen and (max-width: 700px) {
      .quote_slides .quote_prev {
        background-position: 80% 100%; } }
    .quote_slides .quote_prev:after {
      background-image: url("img/arrow_left.png");
      background-position: 12% center; }
      @media screen and (max-width: 700px) {
        .quote_slides .quote_prev:after {
          background-position: 80% 100%; } }
  .quote_slides .quote_next {
    left: 50%;
    background-image: url("img/arrow_right-hov.png");
    background-position: 88% center; }
    @media screen and (max-width: 700px) {
      .quote_slides .quote_next {
        background-position: 20% 100%; } }
    .quote_slides .quote_next:after {
      background-image: url("img/arrow_right.png");
      background-position: 88% center; }
      @media screen and (max-width: 700px) {
        .quote_slides .quote_next:after {
          background-position: 20% 100%; } }

/*----------------------------------------------------------------------------*/
/*	SHOP SECTION
/*----------------------------------------------------------------------------*/
.store-group {
  margin-bottom: 24px; }

.store-item {
  list-style: none;
  display: inline-block;
  width: 25%;
  padding: 0 12px;
  margin-bottom: 24px;
  box-sizing: border-box; }
  @media (max-width: 900px) {
    .store-item {
      width: 33.3333%; } }
  @media screen and (max-width: 600px) {
    .store-item {
      width: 50%; } }
  @media screen and (max-width: 400px) {
    .store-item {
      width: 100%; } }

/*----------------------------------------------------------------------------*/
/*	FLIP CARDS SECTION
/*----------------------------------------------------------------------------*/
.benefit-cards {
  max-width: 1000px;
  margin: 0 auto; }

.benefit {
  display: inline-block;
  vertical-align: top;
  width: 21%;
  height: 264px;
  margin: 2%;
  position: relative;
  text-align: center;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease; }
  @media screen and (max-width: 950px) {
    .benefit {
      width: 29.333%;
      margin: 2%; } }
  @media screen and (max-width: 750px) {
    .benefit {
      width: 46%;
      margin: 4% 2%; } }
  @media screen and (max-width: 450px) {
    .benefit {
      width: 98%;
      margin: 4% 1%; } }
  .benefit .card-front {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 264px;
    width: 98%;
    max-width: 240px;
    margin: 0 auto;
    border-radius: 0px;
    padding: 216px 0 0 0;
    vertical-align: top;
    color: #eee;
    font: 400 21px/24px "Oswald", sans-serif;
    letter-spacing: 0.025em;
    text-transform: uppercase;
    -webkit-transition: all 150ms ease 150ms;
    transition: all 150ms ease 150ms; }
    .benefit .card-front .benefit-icon {
      width: 200px;
      height: 200px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      background-color: #fff;
      border-radius: 20px;
      border: 2px solid #e8e8e8;
      position: absolute;
      top: 4px;
      left: 50%;
      box-sizing: border-box;
      -webkit-box-shadow: inset 0 0 30px rgba(0, 0, 0, 0.5);
      -moz-box-shadow: inset 0 0 30px rgba(0, 0, 0, 0.5);
      box-shadow: inset 0 0 30px rgba(0, 0, 0, 0.5);
      -webkit-transform: translateX(-50%);
      -moz-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      transform: translateX(-50%); }
    .benefit .card-front::before {
      width: 208px;
      height: 208px;
      border-radius: 25px;
      border: 4px solid #fff;
      position: absolute;
      top: 0px;
      left: 50%;
      box-sizing: border-box;
      -webkit-transform: translateX(-50%);
      -moz-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      transform: translateX(-50%); }
  .benefit .card-back {
    opacity: 0;
    border: 2px solid #3a3a3a;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 264px;
    width: 98%;
    max-width: 240px;
    margin: 0 auto;
    border-radius: 10px;
    padding: 12px;
    vertical-align: top;
    background-color: #fff;
    color: #000;
    letter-spacing: 0.025em;
    -webkit-transition: all 150ms ease 150ms;
    transition: all 150ms ease 150ms; }
    .benefit .card-back .card-back-title {
      font: 700 18px/21px "Oswald", sans-serif; }
    .benefit .card-back .card-back-desc {
      font: 300 13px/17px "Open Sans", sans-serif; }
    .benefit .card-back a {
      font: 400 14px/18px "Open Sans", sans-serif;
      display: block;
      letter-spacing: 0.05em;
      color: #0776ab;
      text-transform: uppercase;
      margin-top: 10px;
      text-decoration: none;
      -webkit-transition: all 200ms ease;
      transition: all 200ms ease; }
      .benefit .card-back a:hover {
        color: #000;
        text-decoration: underline; }

.flipcard {
  -webkit-perspective: 1000;
  -moz-perspective: 1000;
  -o-perspective: 1000;
  perspective: 1000; }

.flipcard:hover .flipper, .flipcard.hover .flipper {
  -webkit-transform: rotateY(-180deg);
  -moz-transform: rotateY(-180deg);
  -o-transform: rotateY(-180deg);
  transform: rotateY(-180deg); }
  .flipcard:hover .flipper .card-front, .flipcard.hover .flipper .card-front {
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 150ms ease 150ms;
    transition: all 150ms ease 150ms; }
  .flipcard:hover .flipper .card-back, .flipcard.hover .flipper .card-back {
    opacity: 1;
    z-index: 100; }

.flipper {
  position: relative;
  -webkit-transition: 0.6s;
  -moz-transition: 0.6s;
  -o-transition: 0.6s;
  transition: 0.6s;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  transform-style: preserve-3d; }

.card-front, .card-back {
  -webkit-backface-visibility: visible;
  -moz-backface-visibility: visible;
  -ms-backface-visibility: visible;
  -o-backface-visibility: visible;
  backface-visibility: visible; }

.card-front {
  z-index: 2;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg); }

.card-back {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg); }

/*----------------------------------------------------------------------------*/
/*	MAP SECTION
/*----------------------------------------------------------------------------*/
#mapWrapper {
  border: 1px double #a8a8a8; }

#googleMap {
  height: 300px; }

#googleInfo {
  font: 300 15px/24px "Open Sans", sans-serif;
  color: #eee;
  line-height: 23px;
  text-align: center;
  padding: 25px 5px 10px 5px;
  width: 200px; }

#googleInfo span {
  display: block;
  font: 400 15px/24px "Oswald", sans-serif; }

#googleInfo a {
  display: block;
  font: 400 15px/24px "Open Sans", sans-serif;
  text-transform: uppercase;
  text-decoration: none;
  color: #1ab1f9;
  margin-top: 10px; }

#googleMap img {
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  margin-bottom: 1%; }

#address-block {
  height: 40px;
  text-align: center;
  background-color: #0776ab;
  color: #fff;
  font: 300 18px/24px "Open Sans", sans-serif;
  padding: 8px 20px; }

section.map-section {
  padding: 0; }

/*----------------------------------------------------------------------------*/
/*	TESTIMONIAL SECTION
/*----------------------------------------------------------------------------*/
#testimonials {
  overflow: hidden;
  transition: all .2s ease; }
  #testimonials .test_img_start {
    background: url("img/quote-long.png") center no-repeat;
    height: 43px;
    margin: 20px auto 0; }
  #testimonials .test_img_end {
    background: url("img/quote-end.png") center no-repeat;
    height: 27px;
    margin: -27px auto 0; }
  #testimonials .content {
    padding: 60px 0 75px;
    max-width: 900px;
    margin: 0 auto;
    box-sizing: content-box;
    overflow: hidden; }
    @media screen and (max-width: 700px) {
      #testimonials .content {
        padding: 40px 0 115px 0; } }
    #testimonials .content .test_slide {
      padding: 0 16%; }
      @media screen and (max-width: 700px) {
        #testimonials .content .test_slide {
          padding: 0 30px; } }
    #testimonials .content .test_copy {
      font-style: italic;
      margin-bottom: 20px;
      font-weight: 300; }
    #testimonials .content .test_name {
      font: 400 16px/25px "Open Sans", sans-serif;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      margin-bottom: 3px;
      text-align: right; }

.test_slides {
  position: relative; }
  .test_slides .test_slide {
    position: absolute;
    left: 100%;
    width: 100%; }
    .test_slides .test_slide.current {
      left: 0;
      -webkit-transition: all 300ms ease;
      transition: all 300ms ease; }
    .test_slides .test_slide.prev-left {
      left: -100%;
      -webkit-transition: all 300ms ease;
      transition: all 300ms ease; }
    .test_slides .test_slide.prev-right {
      left: 100%;
      -webkit-transition: all 300ms ease;
      transition: all 300ms ease; }
    .test_slides .test_slide.before-left {
      left: -100%; }
  .test_slides .test_prev, .test_slides .test_next {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 27px;
    background-repeat: no-repeat;
    background-size: 28px 48px;
    cursor: pointer; }
    @media screen and (max-width: 700px) {
      .test_slides .test_prev, .test_slides .test_next {
        bottom: 40px; } }
    .test_slides .test_prev:after, .test_slides .test_next:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-repeat: no-repeat;
      background-size: 28px 48px;
      opacity: 1;
      -webkit-transition: all 300ms ease;
      transition: all 300ms ease; }
      @media screen and (max-width: 700px) {
        .test_slides .test_prev:after, .test_slides .test_next:after {
          opacity: 0; } }
    .test_slides .test_prev:hover:after, .test_slides .test_next:hover:after {
      opacity: 0; }
  .test_slides .test_prev {
    right: 50%;
    background-image: url("img/arrow_left-hov.png");
    background-position: 12% center; }
    @media screen and (max-width: 700px) {
      .test_slides .test_prev {
        background-position: 80% 100%; } }
    .test_slides .test_prev:after {
      background-image: url("img/arrow_left.png");
      background-position: 12% center; }
      @media screen and (max-width: 700px) {
        .test_slides .test_prev:after {
          background-position: 80% 100%; } }
  .test_slides .test_next {
    left: 50%;
    background-image: url("img/arrow_right-hov.png");
    background-position: 88% center; }
    @media screen and (max-width: 700px) {
      .test_slides .test_next {
        background-position: 20% 100%; } }
    .test_slides .test_next:after {
      background-image: url("img/arrow_right.png");
      background-position: 88% center; }
      @media screen and (max-width: 700px) {
        .test_slides .test_next:after {
          background-position: 20% 100%; } }

/*----------------------------------------------------------------------------*/
/*	LINK-LIST SECTION
/*----------------------------------------------------------------------------*/
.page-link-list-wrap {
  padding: 0 30px; }
  .page-link-list-wrap .link-list .link-list-title {
    text-align: center;
    font: 400 24px/36px "Oswald", sans-serif;
    margin-bottom: 12px; }
  .page-link-list-wrap .link-list ul {
    list-style: none;
    margin: 0;
    padding: 0;
    -webkit-column-count: 3;
    /* Chrome, Safari, Opera */
    -moz-column-count: 3;
    /* Firefox */
    column-count: 3; }
    @media screen and (max-width: 700px) {
      .page-link-list-wrap .link-list ul {
        -webkit-column-count: 2;
        /* Chrome, Safari, Opera */
        -moz-column-count: 2;
        /* Firefox */
        column-count: 2; } }
    @media screen and (max-width: 600px) {
      .page-link-list-wrap .link-list ul {
        -webkit-column-count: 1;
        /* Chrome, Safari, Opera */
        -moz-column-count: 1;
        /* Firefox */
        column-count: 1; } }
    .page-link-list-wrap .link-list ul li {
      margin: 0;
      padding: 0; }
      .page-link-list-wrap .link-list ul li a {
        font: 400 16px/36px "Oswald", sans-serif;
        display: block;
        padding: 0 12px;
        color: #eee;
        background-color: transparent;
        text-decoration: none;
        -webkit-transition: all 200ms ease;
        transition: all 200ms ease; }
        .page-link-list-wrap .link-list ul li a:hover {
          background-color: #fff;
          color: #1ab1f9; }

/*----------------------------------------------------------------------------*/
/*	FOOTER
/*----------------------------------------------------------------------------*/
footer {
  width: 100%;
  font-size: 12px;
  background-color: #000; }
  footer .footerContainer {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
    overflow: hidden;
    padding: 24px; }
    @media screen and (max-width: 1200px) {
      footer .footerContainer {
        padding: 24px 2vw; } }
  footer .footer-logo {
    width: 100%;
    max-width: 500px;
    height: 295px;
    margin: 30px auto 12px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain; }
  footer .footer-info {
    text-align: center;
    margin-bottom: 24px; }
    footer .footer-info a {
      font: 400 14px/24px "Open Sans", sans-serif;
      padding: 3px 12px;
      display: inline-block;
      text-decoration: none;
      color: #fff;
      -webkit-transition: all 200ms ease;
      transition: all 200ms ease; }
      footer .footer-info a:hover {
        color: #1ab1f9; }
  footer .footer-left {
    width: 50%;
    float: left;
    text-align: left;
    padding-top: 5px;
    color: #a8a8a8; }
    @media screen and (max-width: 700px) {
      footer .footer-left {
        width: 100%;
        float: none;
        text-align: center; } }
  footer .footer-right {
    width: 50%;
    float: right;
    text-align: right; }
    @media screen and (max-width: 700px) {
      footer .footer-right {
        width: 100%;
        float: none;
        text-align: center;
        margin-bottom: 12px; } }

/*----------------------------------------------------------------------------*/
/*	FORM STYLING
/*----------------------------------------------------------------------------*/
form ul {
  list-style: none;
  margin: 0; }

form label {
  font: 300 16px/21px "Open Sans", sans-serif;
  letter-spacing: .025em;
  color: #000;
  text-align: left; }

form input, form textarea {
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  vertical-align: top;
  border: 1px solid #c8c8c8;
  border-radius: 0;
  padding: 0 12px;
  background: white;
  font: 300 16px/40px "Open Sans", sans-serif;
  letter-spacing: .05em;
  color: #000;
  text-align: left;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  margin-bottom: 12px; }

form textarea {
  height: auto;
  font: 300 16px/21px "Open Sans", sans-serif;
  letter-spacing: .0em;
  padding: 12px 12px; }

form select {
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  vertical-align: top;
  border: 1px solid #c8c8c8;
  padding: 0 18px;
  background: white;
  background-image: url("img/icon-select-64.png");
  background-position: 95% center;
  background-repeat: no-repeat;
  background-size: 16px 16px;
  font: 300 16px/40px "Open Sans", sans-serif;
  letter-spacing: .05em;
  color: #a8a8a8;
  text-align: left;
  -webkit-transition: all .2s ease;
  transition: all .2s ease; }

form input[type="checkbox"] {
  height: 12px;
  width: 12px;
  border: 1px solid #c8c8c8;
  background-color: #fff;
  padding: 0; }

form .frm_checkbox label {
  padding-left: 21px;
  position: relative; }
  form .frm_checkbox label input {
    visibility: hidden;
    height: 0;
    width: 0;
    border: none;
    margin: 0; }
  form .frm_checkbox label::after {
    content: "";
    position: absolute;
    top: 5px;
    left: 2px;
    height: 12px;
    width: 12px;
    border: 1px solid #c8c8c8;
    background-color: transparent;
    -webkit-transition: all .2s ease;
    transition: all .2s ease; }
  form .frm_checkbox label.selected::after {
    background-color: #1ab1f9; }

form input[type=submit] {
  width: auto;
  border: 1px solid #1ab1f9;
  background: #1ab1f9;
  color: #fff;
  cursor: pointer;
  text-align: center;
  text-transform: uppercase; }
  form input[type=submit]:hover {
    border: 1px solid #1ab1f9;
    background: #fff;
    color: #1ab1f9; }

form ::-webkit-input-placeholder {
  color: #a8a8a8;
  letter-spacing: 0.05em; }

form :-moz-placeholder {
  color: #a8a8a8;
  letter-spacing: 0.05em; }

form ::-moz-placeholder {
  color: #a8a8a8;
  letter-spacing: 0.05em; }

form :-ms-input-placeholder {
  color: #a8a8a8;
  letter-spacing: 0.05em; }

form .placeholdersjs {
  color: #a8a8a8; }

form li.gfield {
  margin-bottom: 18px; }
  form li.gfield.hidden_label label {
    display: none; }

form li.gfield_error input, form li.gfield_error select, form li.gfield_error textarea {
  background: rgba(200, 0, 0, 0.1); }

form li.gfield_error .gfield_description.validation_message {
  font: 300 12px/16px "Open Sans", sans-serif;
  color: #a44; }

.gform_wrapper {
  max-width: 600px; }

.passform {
  max-width: 300px; }
  .passform input {
    margin-bottom: 12px; }

#booklet-form {
  padding: 9px 0 7px;
  max-width: 669px;
  margin: 0 auto 24px;
  position: relative; }
  #booklet-form .radios {
    color: #fff;
    font: 300 14px/21px "Open Sans", sans-serif;
    text-align: center;
    margin-bottom: 12px; }
    #booklet-form .radios input[type='radio'] {
      width: auto;
      height: auto;
      float: none;
      display: inline-block;
      margin: 0 12px;
      vertical-align: baseline; }
  #booklet-form input {
    height: 45px;
    width: 27.3%;
    border: 1px solid #e8e8e8;
    background: #e8e8e8;
    color: #eee;
    padding: 0 16px;
    box-sizing: border-box;
    float: left;
    margin-right: 2px;
    font: 300 16px/45px "Open Sans", sans-serif; }
    #booklet-form input:focus {
      background-color: #fff;
      color: #eee;
      outline: 0; }
    @media screen and (max-width: 600px) {
      #booklet-form input {
        width: 100%;
        margin-bottom: 6px; } }
  #booklet-form input[type='submit'] {
    width: 16.9%;
    border: 1px solid #1ab1f9;
    background-color: #1ab1f9;
    color: #fff;
    cursor: pointer;
    font: 300 16px/45px "Open Sans", sans-serif;
    letter-spacing: .05em;
    padding: 0;
    margin: 0;
    text-align: center;
    -webkit-transition: all 200ms ease;
    transition: all 200ms ease; }
    #booklet-form input[type='submit']:hover {
      background-color: #fff;
      color: #1ab1f9;
      -webkit-transition: all 200ms ease;
      transition: all 200ms ease; }
    @media screen and (max-width: 600px) {
      #booklet-form input[type='submit'] {
        width: 100px;
        margin-bottom: 6px; } }
  #booklet-form .thank {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0;
    border: 2px solid #0776ab;
    box-sizing: border-box;
    color: #8a8a8a;
    background-color: #fff;
    text-align: center;
    font-size: 14px;
    line-height: 41px;
    letter-spacing: 0.8px;
    -webkit-transition: visibility 0ms linear 200ms, opacity 200ms linear;
    transition: visibility 0ms linear 200ms, opacity 200ms linear; }
    @media screen and (max-width: 600px) {
      #booklet-form .thank div {
        background-color: #fff;
        padding-bottom: 118px;
        margin: -3px -3px; } }
    #booklet-form .thank .thank-close {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      height: 30px;
      width: 30px;
      background-color: #fff;
      color: #1ab1f9;
      box-sizing: border-box;
      line-height: 30px;
      font-size: 16px;
      letter-spacing: 0;
      text-transform: uppercase;
      text-decoration: none;
      -webkit-transition: all 200ms ease;
      transition: all 200ms ease; }
      #booklet-form .thank .thank-close:hover {
        background-color: #1ab1f9;
        color: #fff; }
  #booklet-form ::-webkit-input-placeholder {
    color: #a8a8a8;
    letter-spacing: .04em;
    text-transform: uppercase; }
  #booklet-form :-moz-placeholder {
    color: #a8a8a8;
    letter-spacing: .04em;
    text-transform: uppercase; }
  #booklet-form ::-moz-placeholder {
    color: #a8a8a8;
    letter-spacing: .04em;
    text-transform: uppercase; }
  #booklet-form :-ms-input-placeholder {
    color: #a8a8a8;
    letter-spacing: .04em;
    text-transform: uppercase; }
  #booklet-form input:focus::-webkit-input-placeholder {
    color: #8a8a8a; }
  #booklet-form input:focus:-moz-input-placeholder {
    color: #8a8a8a; }
  #booklet-form input:focus::-moz-input-placeholder {
    color: #8a8a8a; }
  #booklet-form input:focus::-ms-input-placeholder {
    color: #8a8a8a; }
  #booklet-form textarea:focus::-webkit-input-placeholder {
    color: #8a8a8a; }
  #booklet-form textarea:focus:-moz-input-placeholder {
    color: #8a8a8a; }
  #booklet-form textarea:focus::-moz-input-placeholder {
    color: #8a8a8a; }
  #booklet-form textarea:focus::-ms-input-placeholder {
    color: #8a8a8a; }
  #booklet-form .placeholdersjs {
    color: #c8c8c8; }

#newsletter-form.show-thank .thank {
  visibility: visible;
  opacity: 1;
  transition-delay: 0s; }

/*----------------------------------------------------------------------------*/
/*	POPUP STYLING
/*----------------------------------------------------------------------------*/
.popup-shadow {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
  background: rgba(0, 0, 0, 0.75);
  z-index: 7000; }

.popup-form {
  display: none;
  position: fixed;
  top: 5vh;
  left: 50%;
  max-height: 90vh;
  overflow-x: auto;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 100%;
  max-width: 700px;
  border: 2px solid #eee;
  background: #c8c8c8;
  z-index: 7030;
  box-sizing: border-box;
  padding: 60px 48px;
  text-align: center; }
  @media screen and (max-width: 700px) {
    .popup-form {
      padding: 60px 24px; } }
  @media screen and (max-width: 500px) {
    .popup-form {
      padding: 60px 6px; } }
  .popup-form .popup-form-close {
    position: absolute;
    top: 12px;
    right: 12px;
    cursor: pointer;
    height: 32px;
    width: 32px;
    background-image: url("img/icon-close-64.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain; }
  .popup-form .popup-form-logo {
    text-indent: -9999px;
    height: 100px;
    margin: 0 auto 36px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain; }
  .popup-form .popup-form-intro {
    border-top: 2px solid #a8a8a8;
    border-bottom: 2px solid #a8a8a8;
    padding: 12px 24px;
    margin: 0 24px 24px; }
    @media screen and (max-width: 600px) {
      .popup-form .popup-form-intro {
        padding: 12px 6px;
        margin: 0 6px 24px; } }
    .popup-form .popup-form-intro .popup-form-title {
      font: 400 36px/42px "Oswald", sans-serif;
      color: #eee;
      margin-bottom: 12px; }
      @media screen and (max-width: 600px) {
        .popup-form .popup-form-intro .popup-form-title {
          font: 400 24px/30px "Oswald", sans-serif;
          margin-bottom: 6px; } }
    .popup-form .popup-form-intro .popup-form-subtitle {
      font: 400 21px/27px "Oswald", sans-serif;
      color: #eee; }
      @media screen and (max-width: 600px) {
        .popup-form .popup-form-intro .popup-form-subtitle {
          font: 400 18px/24px "Oswald", sans-serif; } }
  .popup-form .popup-form-pre-content {
    padding: 12px 24px;
    margin: 0 0 24px; }
    @media screen and (max-width: 600px) {
      .popup-form .popup-form-pre-content {
        padding: 12px 6px;
        margin: 0 0 24px; } }
  .popup-form .popup-form-content {
    padding: 0 24px;
    margin: 0; }
    @media screen and (max-width: 600px) {
      .popup-form .popup-form-content {
        padding: 0 6px;
        margin: 0; } }
  .popup-form .popup-form-extro {
    padding: 12px 24px;
    margin: 24px 0 0; }
    @media screen and (max-width: 600px) {
      .popup-form .popup-form-extro {
        padding: 12px 6px;
        margin: 24px 0 0; } }
  .popup-form .popup-form-content, .popup-form .popup-form-pre-content {
    font: 300 15px/21px "Open Sans", sans-serif;
    color: #eee; }
    .popup-form .popup-form-content h1, .popup-form .popup-form-pre-content h1 {
      font: 400 26px/30px "Oswald", sans-serif;
      margin-bottom: 24px; }
    .popup-form .popup-form-content h2, .popup-form .popup-form-pre-content h2 {
      font: 400 22px/26px "Oswald", sans-serif;
      margin-bottom: 18px; }
    .popup-form .popup-form-content h3, .popup-form .popup-form-pre-content h3 {
      font: 400 18px/22px "Oswald", sans-serif;
      margin-bottom: 12px; }
    .popup-form .popup-form-content h4, .popup-form .popup-form-pre-content h4 {
      font: 600 22px/30px "Open Sans", sans-serif;
      text-transform: uppercase;
      color: #eee; }
    .popup-form .popup-form-content h5, .popup-form .popup-form-pre-content h5 {
      font: 600 18px/27px "Open Sans", sans-serif;
      text-transform: uppercase;
      color: #eee; }
    .popup-form .popup-form-content h6, .popup-form .popup-form-pre-content h6 {
      font: 600 14px/22px "Open Sans", sans-serif;
      text-transform: uppercase;
      color: #eee;
      margin-bottom: 6px; }
    .popup-form .popup-form-content a, .popup-form .popup-form-pre-content a {
      color: #eee; }
      .popup-form .popup-form-content a:hover, .popup-form .popup-form-pre-content a:hover {
        text-decoration: underline; }
    .popup-form .popup-form-content strong, .popup-form .popup-form-pre-content strong {
      font-weight: 700; }
    .popup-form .popup-form-content em, .popup-form .popup-form-content i, .popup-form .popup-form-pre-content em, .popup-form .popup-form-pre-content i {
      font-family: "Oswald", sans-serif;
      font-style: italic; }
    .popup-form .popup-form-content ol, .popup-form .popup-form-content ul, .popup-form .popup-form-pre-content ol, .popup-form .popup-form-pre-content ul {
      margin-left: 20px;
      margin-bottom: 18px; }
      .popup-form .popup-form-content ol li, .popup-form .popup-form-content ul li, .popup-form .popup-form-pre-content ol li, .popup-form .popup-form-pre-content ul li {
        margin-bottom: 6px; }
    .popup-form .popup-form-content ol, .popup-form .popup-form-pre-content ol {
      list-style: decimal; }
      .popup-form .popup-form-content ol ol, .popup-form .popup-form-pre-content ol ol {
        list-style: lower-roman; }
        .popup-form .popup-form-content ol ol ol, .popup-form .popup-form-pre-content ol ol ol {
          list-style: lower-alpha; }
    .popup-form .popup-form-content ul, .popup-form .popup-form-pre-content ul {
      list-style: square; }
      .popup-form .popup-form-content ul ul, .popup-form .popup-form-pre-content ul ul {
        list-style: circle; }
        .popup-form .popup-form-content ul ul ul, .popup-form .popup-form-pre-content ul ul ul {
          list-style: disc; }
    .popup-form .popup-form-content img, .popup-form .popup-form-pre-content img {
      max-width: 100%;
      height: auto; }
    .popup-form .popup-form-content iframe, .popup-form .popup-form-pre-content iframe {
      max-width: 100%; }
    .popup-form .popup-form-content .wp-caption, .popup-form .popup-form-pre-content .wp-caption {
      max-width: 100%; }

/*----------------------------------------------------------------------------*/
/*	MOBILE NAV
/*----------------------------------------------------------------------------*/
/* required mixins */
/* general */
/* tab styling */
/* menu items */
/* submenu items */
.home #mobileNav .tab {
  color: #fff; }
  .home #mobileNav .tab:before {
    border-top: #fff solid 2px;
    border-bottom: #fff solid 2px; }
  .home #mobileNav .tab:after {
    border-bottom: #fff solid 2px; }

#mobileNav {
  display: none;
  width: 240px;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #333;
  font-family: "Open Sans", sans-serif;
  text-transform: uppercase;
  z-index: 6000;
  -webkit-transform: translate(240px, 0);
  -ms-transform: translate(240px, 0);
  transform: translate(240px, 0);
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease; }
  #mobileNav .tab {
    box-sizing: border-box;
    position: absolute;
    top: 0px;
    left: -60px;
    height: 60px;
    width: 60px;
    background-color: transparent;
    color: #fff;
    padding-top: 15px;
    font: 100 10px/10px "Open Sans", sans-serif;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    text-align: center;
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
    -webkit-transition: all 200ms ease;
    transition: all 200ms ease; }
    #mobileNav .tab:before {
      content: '';
      position: absolute;
      bottom: 21px;
      left: 12px;
      right: 12px;
      height: 8px;
      box-sizing: border-box;
      border-top: #fff solid 2px;
      border-bottom: #fff solid 2px;
      -webkit-transition: all 200ms ease-in-out;
      transition: all 200ms ease-in-out; }
    #mobileNav .tab:after {
      content: '';
      position: absolute;
      bottom: 15px;
      left: 12px;
      right: 12px;
      box-sizing: border-box;
      border-bottom: #fff solid 2px;
      -webkit-transition: all 200ms ease-in-out;
      transition: all 200ms ease-in-out; }
  #mobileNav .main {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;
    padding: 24px 2px;
    width: 236px;
    overflow-x: hidden; }
  #mobileNav .group {
    margin-bottom: 50px;
    text-align: left; }
  #mobileNav .groupTitle {
    padding: 0px 18px;
    font: 300 12px/12px "Open Sans", sans-serif;
    letter-spacing: 0.05em;
    color: #1ab1f9;
    margin-bottom: 10px; }
  #mobileNav .groupMenu {
    list-style: none;
    padding-left: 0;
    margin-left: 4px; }
    #mobileNav .groupMenu li {
      border-bottom: 1px solid #1ab1f9; }
      #mobileNav .groupMenu li.mobile-address a {
        line-height: 20px; }
      #mobileNav .groupMenu li.current-menu-item a {
        color: #c8c8c8; }
      #mobileNav .groupMenu li.menu-item-has-children {
        position: relative; }
        #mobileNav .groupMenu li.menu-item-has-children::after {
          content: '>';
          color: #fff;
          line-height: 34px;
          text-align: center;
          height: 34px;
          width: 34px;
          position: absolute;
          top: 0;
          right: 0;
          background-color: rgba(255, 255, 255, 0.5); }
    #mobileNav .groupMenu a {
      text-decoration: none;
      display: block;
      padding: 5px 18px;
      font: 300 15px/30px "Open Sans", sans-serif;
      letter-spacing: 0.05em;
      color: #c8c8c8;
      -webkit-transition: all 200ms ease;
      transition: all 200ms ease; }
      #mobileNav .groupMenu a:hover {
        color: #c8c8c8; }
    #mobileNav .groupMenu .social-icon {
      display: inline-block;
      margin-left: 15px;
      margin-top: 9px;
      width: 32px;
      height: 32px;
      color: #000;
      background-color: #fff;
      border-radius: 50%;
      transition: all 200ms ease;
      fill: #fff;
      padding: 0;
      position: relative; }
      #mobileNav .groupMenu .social-icon::before {
        content: '';
        height: 26px;
        width: 26px;
        border-radius: 50%;
        background-color: #000;
        position: absolute;
        z-index: 0;
        top: 50%;
        left: 50%;
        -webkit-transform: translateX(-50%) translateY(-50%);
        -moz-transform: translateX(-50%) translateY(-50%);
        -ms-transform: translateX(-50%) translateY(-50%);
        transform: translateX(-50%) translateY(-50%);
        -webkit-transition: all 200ms ease;
        transition: all 200ms ease; }
      #mobileNav .groupMenu .social-icon svg {
        position: relative;
        z-index: 2; }
      #mobileNav .groupMenu .social-icon:hover {
        fill: #000; }
        #mobileNav .groupMenu .social-icon:hover::before {
          background-color: #fff; }
  #mobileNav .subGroup, #mobileNav ul.sub-menu {
    cursor: pointer;
    position: static; }
  #mobileNav .subGroupTitle {
    display: block;
    font: 300 15px/30px "Open Sans", sans-serif;
    letter-spacing: 0.05em;
    color: #c8c8c8;
    padding: 5px 18px; }
  #mobileNav .subGroupMenu, #mobileNav ul.sub-menu {
    padding: 50px 0px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: auto;
    list-style: none;
    text-transform: uppercase;
    max-height: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 2010;
    width: 200px;
    background-color: #0776ab;
    -webkit-transform: translate(200px, 0);
    -ms-transform: translate(200px, 0);
    transform: translate(200px, 0);
    -webkit-transition: all 200ms ease;
    transition: all 200ms ease; }
    #mobileNav .subGroupMenu li, #mobileNav ul.sub-menu li {
      border-bottom: 1px solid #c8c8c8;
      background-color: #013f5d;
      -webkit-transition: all 200ms ease;
      transition: all 200ms ease; }
      #mobileNav .subGroupMenu li:hover, #mobileNav ul.sub-menu li:hover {
        background-color: #8a8a8a; }
    #mobileNav .subGroupMenu a, #mobileNav ul.sub-menu a {
      text-decoration: none;
      display: block;
      padding: 5px 18px;
      font: 300 15px/30px "Open Sans", sans-serif;
      letter-spacing: 0.05em;
      color: #fff; }
  #mobileNav .subGroup.show .subGroupMenu, #mobileNav ul.sub-menu.show .subGroupMenu {
    display: block;
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0); }
  #mobileNav .subGroup.show:before, #mobileNav ul.sub-menu.show:before {
    content: '';
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }
  #mobileNav .phone {
    -webkit-hyphens: none; }
  @media screen and (max-width: 700px) {
    #mobileNav {
      display: block; } }

body.showMobileNav {
  overflow: hidden; }
  body.showMobileNav #mobileNav {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0); }
    body.showMobileNav #mobileNav .tab {
      background-color: #333;
      color: #1ab1f9; }
      body.showMobileNav #mobileNav .tab:before {
        bottom: 18px;
        height: 0px;
        border-top: none;
        border-bottom: #1ab1f9 solid 2px;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg); }
      body.showMobileNav #mobileNav .tab:after {
        bottom: 18px;
        border-bottom: #1ab1f9 solid 2px;
        -webkit-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        transform: rotate(-45deg); }
  body.showMobileNav:after {
    content: '';
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000; }

/*----------------------------------------------------------------------------*/
/*	SOCIAL
/*----------------------------------------------------------------------------*/
.menu-item-social {
  display: inline-block;
  vertical-align: top;
  margin-left: 4px; }

.social_link {
  width: 30px;
  height: 30px;
  display: inline-block;
  margin: 0 10px 0 0;
  position: relative;
  vertical-align: top; }

.social-link {
  color: #fff;
  text-decoration: none;
  text-shadow: none;
  margin-right: 10px;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease; }
  .social-link:last-of-type {
    margin-right: 0px; }
  .social-link .social-backdrop {
    display: inline-block;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background-color: #2d56a7;
    text-align: center;
    vertical-align: top;
    margin-top: 15px;
    -webkit-transition: all 200ms ease;
    transition: all 200ms ease; }
    .social-link .social-backdrop i {
      display: block;
      font-size: 18px;
      line-height: 30px;
      color: #fff;
      -webkit-transition: all 200ms ease;
      transition: all 200ms ease; }
  .social-link:hover {
    color: #fff;
    text-decoration: none;
    text-shadow: none; }
    .social-link:hover .social-backdrop {
      background-color: #fff; }
      .social-link:hover .social-backdrop i {
        color: #2d56a7; }

.footer-action-subscribe {
  line-height: 30px;
  text-decoration: none;
  color: #0776ab;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease; }
  .footer-action-subscribe:hover {
    text-shadow: none;
    text-decoration: none; }

/*----------------------------------------------------------------------------*/
/*	VENDOR
/*----------------------------------------------------------------------------*/
/*---------- Google Maps -----------------------------------------------------*/
.mapWrapper {
  border: 1px double #a8a8a8; }

.content-col-map .mapWrapper {
  border: none;
  background-color: rgba(255, 255, 255, 0.1); }

.googleMap {
  height: 300px; }

.googleInfo {
  font: 300 15px/24px "Open Sans", sans-serif;
  color: #333;
  line-height: 23px;
  text-align: center;
  padding: 25px 5px 10px 5px;
  width: 200px; }

.googleInfo span {
  display: block;
  font: 400 15px/24px "Open Sans", sans-serif;
  color: #222; }

.googleInfo a {
  display: block;
  font: 400 15px/24px "Open Sans", sans-serif;
  text-transform: uppercase;
  text-decoration: none;
  color: #8CD7FF;
  margin-top: 10px; }

.googleMap img {
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  margin-bottom: 1%; }

.address-block {
  height: 40px;
  text-align: center;
  background-color: #0776ab;
  color: #fff;
  font: 300 18px/24px "Open Sans", sans-serif;
  padding: 8px 20px; }

section.map-section {
  padding: 0; }

/*---------- Colorbox --------------------------------------------------------*/
/*
	Colorbox Core Style:
	The following CSS is consistent between example themes and should not be altered.
*/
#colorbox, #cboxOverlay, #cboxWrapper {
  position: absolute;
  top: 0;
  left: 0 !important;
  width: 100% !important;
  z-index: 9999;
  overflow: hidden; }

#cboxWrapper {
  max-width: none; }

#cboxOverlay {
  position: fixed;
  width: 100%;
  height: 100%; }

#cboxMiddleLeft, #cboxBottomLeft {
  clear: left; }

#cboxContent {
  position: relative;
  margin: 0 auto;
  float: none !important; }

#cboxLoadedContent {
  overflow: auto;
  -webkit-overflow-scrolling: touch; }

#cboxTitle {
  margin: 0; }

#cboxLoadingOverlay, #cboxLoadingGraphic {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

#cboxPrevious, #cboxNext, #cboxClose, #cboxSlideshow {
  cursor: pointer; }

.cboxPhoto {
  float: left;
  margin: auto;
  border: 0;
  display: block;
  max-width: none;
  -ms-interpolation-mode: bicubic; }

.cboxIframe {
  width: 100%;
  height: 100%;
  display: block;
  border: 0;
  padding: 0;
  margin: 0; }

#colorbox, #cboxContent, #cboxLoadedContent {
  box-sizing: content-box;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box; }

/*
	User Style:
	Change the following styles to modify the appearance of Colorbox.  They are
	ordered & tabbed in a way that represents the nesting of the generated HTML.
*/
#cboxOverlay {
  background: #000;
  opacity: 0.7 !important;
  filter: alpha(opacity=70); }

#colorbox {
  outline: 0; }

#cboxContent {
  background: #fff;
  overflow: hidden;
  border: 10px solid #231f20; }

.cboxIframe {
  background: #fff; }

#cboxError {
  padding: 50px;
  border: 1px solid #ccc; }

#cboxTitle {
  position: absolute;
  bottom: 4px;
  left: 0;
  text-align: center;
  width: 100%;
  color: #949494; }

#cboxCurrent {
  position: absolute;
  bottom: 4px;
  left: 58px;
  color: #949494; }

#cboxLoadingOverlay {
  background: url("img/cbox-loading_background.png") no-repeat center center; }

#cboxLoadingGraphic {
  background: url("img/cbox-loading.gif") no-repeat center center; }

#cboxPrevious, #cboxNext, #cboxSlideshow, #cboxClose {
  border: 0;
  padding: 0;
  margin: 0;
  overflow: visible;
  background: none; }

#cboxPrevious:active, #cboxNext:active, #cboxSlideshow:active, #cboxClose:active {
  outline: 0; }

#cboxSlideshow {
  position: absolute;
  bottom: 4px;
  right: 30px;
  color: #0092ef; }

#cboxPrevious {
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -35px;
  padding: 35px;
  background: url("img/cbox-left-arrow.png") no-repeat center;
  width: 35px;
  height: 35px;
  text-indent: -9999px; }

#cboxNext {
  position: absolute;
  top: 50%;
  right: 0px;
  margin-top: -35px;
  padding: 35px;
  background: url("img/cbox-right-arrow.png") no-repeat center;
  width: 35px;
  height: 35px;
  text-indent: -9999px; }

#cboxClose {
  position: absolute;
  top: 0;
  right: 0;
  background: url("img/cbox-close.png") no-repeat center;
  width: 44px;
  height: 43px;
  text-indent: -9999px; }

.cboxIE #cboxTopLeft, .cboxIE #cboxTopCenter,
.cboxIE #cboxTopRight, .cboxIE #cboxBottomLeft,
.cboxIE #cboxBottomCenter, .cboxIE #cboxBottomRight,
.cboxIE #cboxMiddleLeft, .cboxIE #cboxMiddleRight {
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#00FFFFFF,endColorstr=#00FFFFFF); }

#cboxOverlay, #cboxWrapper, #colorbox {
  position: absolute;
  top: 0;
  left: 0 !important;
  width: 100% !important;
  z-index: 9999;
  overflow: hidden; }

#cboxOverlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.7 !important;
  filter: alpha(opacity=70); }

#cboxContent {
  overflow: hidden;
  border: 10px solid #231f20;
  position: relative;
  margin: 0 auto;
  float: none !important; }

#cboxClose, #cboxNext, #cboxPrevious, #cboxSlideshow {
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
  overflow: visible;
  background: 0 0; }

#cboxClose {
  position: absolute;
  top: 0;
  right: 0;
  background: url("img/cbox-close.png") center no-repeat;
  width: 44px;
  height: 43px;
  text-indent: -9999px; }

#cboxNext, #cboxPrevious {
  text-indent: -9999px;
  position: absolute;
  top: 50%;
  margin-top: -35px;
  padding: 35px;
  width: 35px;
  height: 35px; }

#cboxPrevious {
  left: 0;
  background: url("img/cbox-left-arrow.png") center no-repeat; }

#cboxNext {
  right: 0;
  background: url("img/cbox-right-arrow.png") center no-repeat; }

#cboxLoadedContent iframe {
  width: 1px;
  min-width: 100%;
  min-height: 100%; }

#cboxTitle {
  background-color: rgba(0, 0, 0, 0.65);
  color: #fff;
  bottom: 0;
  font: 400 13px/21px "Open Sans", sans-serif; }

.slick-list {
  margin: 0 45px;
  overflow: visible; }

.slick-prev, .slick-next {
  cursor: pointer;
  background-color: #1ab1f9;
  height: 72px;
  width: 72px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  text-indent: -9999px;
  border: none;
  z-index: 20; }
  .slick-prev.slick-disabled, .slick-next.slick-disabled {
    opacity: 0.7;
    cursor: auto; }
  .slick-prev::after, .slick-next::after {
    font: 300 24px/20px "Open Sans", sans-serif;
    color: #fff;
    position: absolute;
    top: 53%;
    text-indent: 0;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%); }
  @media all and (max-width: 1200px) {
    .slick-prev, .slick-next {
      height: 6vw;
      width: 6vw; }
      .slick-prev::after, .slick-next::after {
        font: 300 2vw/1.66666vw "Open Sans", sans-serif; } }
  @media all and (max-width: 900px) {
    .slick-prev, .slick-next {
      height: 54px;
      width: 54px; }
      .slick-prev:after, .slick-next:after {
        font: 300 18px/15px "Open Sans", sans-serif; } }

.slick-prev {
  left: 0;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%); }
  .slick-prev::after {
    content: '–';
    right: 15px; }
    @media all and (max-width: 1200px) {
      .slick-prev::after {
        right: 1.25vw; } }

.slick-next {
  right: 0;
  -webkit-transform: translateX(50%) translateY(-50%);
  -moz-transform: translateX(50%) translateY(-50%);
  -ms-transform: translateX(50%) translateY(-50%);
  transform: translateX(50%) translateY(-50%); }
  .slick-next::after {
    content: '+';
    left: 15px; }
    @media all and (max-width: 1200px) {
      .slick-next::after {
        left: 1.25vw; } }

.slick-dots {
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  text-align: center;
  margin: 0; }
  .slick-dots li {
    display: inline-block; }
  .slick-dots button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 20px;
    height: 20px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
    position: relative; }
    .slick-dots button::before {
      content: '•';
      font-size: 20px;
      color: #fff;
      line-height: 22px; }
  .slick-dots .slick-active button::before {
    color: #1ab1f9;
    font-size: 22px;
    text-shadow: 0 0 3px rgba(255, 255, 255, 0.5); }

.slick-list.draggable {
  overflow: hidden; }

/*----------------------------------------------------------------------------*/
/*	BLOG
/*----------------------------------------------------------------------------*/
body.blog #content,
body.archive #content {
  border-bottom: 1px solid #0776ab; }

body.blog #content,
body.archive #content,
body.search #content,
body.single-post #content {
  padding-top: 0px; }

.blog-hero-wrap {
  width: 100%;
  height: 400px;
  position: relative;
  z-index: 0; }
  @media only screen and (max-width: 768px) {
    .blog-hero-wrap {
      height: 300px; } }
  .blog-hero-wrap .blog-hero-image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-position: center top;
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-attachment: fixed;
    z-index: 1; }
    @media only screen and (max-width: 1224px) {
      .blog-hero-wrap .blog-hero-image {
        background-size: auto 400px; } }
    @media only screen and (max-width: 768px) {
      .blog-hero-wrap .blog-hero-image {
        background-size: auto 300px; } }
  .blog-hero-wrap .blog-hero-logo {
    display: block;
    position: relative;
    width: 750px;
    height: 400px;
    margin: 0 auto;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 2; }
    @media only screen and (max-width: 768px) {
      .blog-hero-wrap .blog-hero-logo {
        width: 500px;
        height: 300px; } }
    @media only screen and (max-width: 500px) {
      .blog-hero-wrap .blog-hero-logo {
        width: 100%;
        height: 300px; } }

#blog_archive {
  width: 100%;
  float: left;
  position: relative;
  padding-bottom: 72px; }
  @media only screen and (max-width: 768px) {
    #blog_archive {
      width: 100%;
      float: none; } }
  #blog_archive #next {
    position: absolute;
    bottom: 12px;
    right: 12px;
    display: inline-block;
    line-height: 36px;
    padding: 0 20px;
    background: #1ab1f9;
    color: #fff;
    border: solid 2px #1ab1f9;
    border-radius: 4px;
    -webkit-transition: all 200ms ease;
    transition: all 200ms ease; }
    #blog_archive #next a {
      color: #fff;
      text-decoration: none;
      -webkit-transition: all 200ms ease;
      transition: all 200ms ease; }
    #blog_archive #next:hover {
      background: #fff;
      color: #1ab1f9; }
      #blog_archive #next:hover a {
        color: #1ab1f9; }
  #blog_archive .blog-posts-content {
    width: 33.33333%;
    display: inline-block;
    padding: 12px;
    box-sizing: border-box;
    vertical-align: top; }
    @media only screen and (max-width: 1000px) {
      #blog_archive .blog-posts-content {
        width: 50%; } }
    @media only screen and (max-width: 768px) {
      #blog_archive .blog-posts-content {
        width: 33.33333%; } }
    @media only screen and (max-width: 650px) {
      #blog_archive .blog-posts-content {
        width: 50%; } }
    @media only screen and (max-width: 450px) {
      #blog_archive .blog-posts-content {
        width: 100%; } }
    #blog_archive .blog-posts-content .blog-posts-content-inner {
      text-decoration: none;
      text-align: left;
      display: block;
      padding: 0;
      margin: 0;
      box-sizing: border-box;
      border-radius: 4px;
      background-color: #fff;
      box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.35); }
      #blog_archive .blog-posts-content .blog-posts-content-inner .blog-posts-image-outer {
        position: relative;
        height: 0;
        width: 100%;
        padding-bottom: 80%; }
        #blog_archive .blog-posts-content .blog-posts-content-inner .blog-posts-image-outer .blog-posts-image {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
          -moz-box-shadow: inset 0 0 40px rgba(0, 0, 0, 0.25);
          -webkit-box-shadow: inset 0 0 40px rgba(0, 0, 0, 0.25);
          box-shadow: inset 0 0 40px rgba(0, 0, 0, 0.25); }
      #blog_archive .blog-posts-content .blog-posts-content-inner .blog-posts-info {
        padding: 18px;
        color: #000; }
        #blog_archive .blog-posts-content .blog-posts-content-inner .blog-posts-info .blog-posts-meta {
          color: #a8a8a8;
          font-style: italic;
          font-weight: 300;
          font-size: 14px;
          margin-bottom: 0px; }
        #blog_archive .blog-posts-content .blog-posts-content-inner .blog-posts-info .blog-posts-title {
          font: 800 21px/24px "Oswald", sans-serif;
          padding-bottom: 9px;
          border-bottom: 1px solid #0776ab;
          margin-bottom: 9px;
          text-transform: uppercase; }
        #blog_archive .blog-posts-content .blog-posts-content-inner .blog-posts-info .blog-posts-excerpt {
          font: 300 15px/21px "Open Sans", sans-serif; }

#blog_aside {
  width: 100%;
  float: left;
  padding: 0px 24px 24px;
  text-align: left; }
  @media only screen and (max-width: 768px) {
    #blog_aside {
      width: 100%;
      float: none; } }
  #blog_aside .widget {
    padding-bottom: 24px;
    border-bottom: 1px solid #0776ab;
    margin-bottom: 24px; }
  #blog_aside .title {
    font: 800 18px/21px "Oswald", sans-serif;
    color: #000;
    text-transform: uppercase;
    margin-bottom: 18px;
    letter-spacing: 0.05em; }
  #blog_aside ul {
    list-style: none; }
    #blog_aside ul li {
      font: 300 14px/21px "Open Sans", sans-serif; }
      #blog_aside ul li a {
        text-decoration: none; }
        #blog_aside ul li a:hover {
          text-decoration: underline; }
      #blog_aside ul li .post-date {
        color: #0776ab;
        font: 300 14px/24px "Open Sans", sans-serif; }

#blog_post_outer {
  width: 100%;
  float: left; }
  @media only screen and (max-width: 768px) {
    #blog_post_outer {
      width: 100%;
      float: none; } }

#blog_post {
  font: 300 16px/24px "Open Sans", sans-serif;
  letter-spacing: 0;
  text-align: left;
  width: 97.22222222%;
  margin-left: 1.38888889%;
  margin-right: 1.38888889%;
  padding: 60px 60px;
  margin-bottom: 24px;
  border-radius: 4px;
  color: #000;
  background-color: #fff;
  box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.35); }
  @media only screen and (max-width: 1000px) {
    #blog_post {
      padding: 60px 24px; } }
  #blog_post img.alignright {
    float: right;
    margin: 0 0 1em 1em; }
  #blog_post img.alignleft {
    float: left;
    margin: 0 1em 1em 0; }
  #blog_post img.aligncenter {
    display: block;
    margin-left: auto;
    margin-right: auto; }
  #blog_post .alignright {
    float: right; }
  #blog_post .alignleft {
    float: left; }
  #blog_post .aligncenter {
    display: block;
    margin-left: auto;
    margin-right: auto; }
  #blog_post .blog-post-header h1 {
    display: block;
    color: #000; }
  #blog_post ol, #blog_post ul {
    margin-left: 20px;
    margin-bottom: 18px; }
    #blog_post ol li, #blog_post ul li {
      margin-bottom: 6px; }
  #blog_post p {
    margin-bottom: 18px; }
  #blog_post a {
    color: #1ab1f9; }
    #blog_post a:hover {
      color: #000; }
  #blog_post img {
    max-width: 100%;
    height: auto; }
  #blog_post iframe {
    max-width: 100%; }
  #blog_post .wp-caption {
    max-width: 100%; }
  #blog_post strong {
    font-weight: 700; }
  #blog_post em, #blog_post i {
    font-style: italic; }

.comment-box {
  font: 300 16px/24px "Open Sans", sans-serif;
  letter-spacing: 0;
  text-align: left;
  width: 97.22222222%;
  margin-left: 1.38888889%;
  margin-right: 1.38888889%;
  padding: 60px 60px;
  margin-bottom: 50px;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.35); }
  @media only screen and (max-width: 768px) {
    .comment-box {
      padding: 60px 6%; } }

.commentlist {
  width: 100%;
  box-sizing: border-box;
  font-size: 14px; }
  @media only screen and (max-width: 600px) {
    .commentlist {
      padding: 20px 2vw; } }
  .commentlist .comment .comment-body {
    position: relative;
    width: 100%;
    margin: 0;
    margin-bottom: 30px; }
    .commentlist .comment .comment-body .reply a.comment-reply-link {
      display: inline-block;
      text-decoration: none;
      padding: 4px 12px;
      font-size: 14px;
      font-weight: 300;
      letter-spacing: 0.05em;
      background: #0776ab;
      color: #fff;
      -webkit-transition: all 200ms ease;
      transition: all 200ms ease; }
      .commentlist .comment .comment-body .reply a.comment-reply-link:hover {
        background: #eee;
        color: #fff; }

@media all and (max-width: 600px) {
  #blog_post .blog-post-content img {
    max-width: 100%; } }

#blog_post .blog-post-content img.alignleft {
  max-width: 50%;
  margin: 0 .5em .5em 0; }
  @media only screen and (max-width: 700px) {
    #blog_post .blog-post-content img.alignleft {
      margin: 0 .25em .25em 0; } }

#blog_post .blog-post-content img.alignright {
  max-width: 50%;
  margin: 0 0 .5em .5em; }
  @media only screen and (max-width: 700px) {
    #blog_post .blog-post-content img.alignright {
      margin: 0 0 .25em .25em; } }

#respond {
  width: 100%;
  box-sizing: border-box;
  font-size: 14px; }
  @media only screen and (max-width: 600px) {
    #respond {
      padding: 20px 2vw; } }

#other_stories {
  border-top: 1px solid #a8a8a8;
  border-bottom: 1px solid #a8a8a8;
  padding: 20px 20px; }
  @media only screen and (max-width: 600px) {
    #other_stories {
      background-color: #c8c8c8; } }
  #other_stories .other-story {
    width: 31.3333%;
    margin: 0 1% 0;
    display: inline-block;
    position: relative;
    min-height: 66px;
    vertical-align: top;
    box-sizing: border-box; }
    @media only screen and (max-width: 600px) {
      #other_stories .other-story {
        width: 100%;
        margin: 3vw 1.5vw; } }
    #other_stories .other-story .other-image {
      position: absolute;
      top: 0;
      left: 0;
      height: 66px;
      width: 66px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      border: 2px solid #1ab1f9;
      border-radius: 3px;
      box-sizing: border-box; }
    #other_stories .other-story .other-title {
      width: 100%;
      padding-left: 78px;
      font-size: 18px;
      font-weight: 700;
      color: #000;
      box-sizing: border-box; }
      #other_stories .other-story .other-title:hover {
        text-decoration: underline; }

#content-auth {
  max-width: 900px;
  margin: 0 auto 0px; }
  #content-auth #main-auth {
    display: block;
    max-width: 900px;
    width: 97.22222222%;
    margin-left: 1.38888889%;
    margin-right: 1.38888889%;
    float: none;
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    position: relative; }
    #content-auth #main-auth #blog-post-author {
      margin-bottom: 60px; }
      #content-auth #main-auth #blog-post-author .profile-image {
        width: 25%;
        float: left;
        max-width: 200px;
        height: auto;
        margin-right: 20px;
        margin-bottom: 10px; }
        #content-auth #main-auth #blog-post-author .profile-image img {
          width: 100%;
          height: auto; }

#searchform .screen-reader-text {
  display: none; }

#searchform input[type='text'] {
  width: 65%;
  display: inline-block;
  vertical-align: top; }

#searchform input[type='submit'] {
  width: 30%;
  display: inline-block;
  vertical-align: top; }

.clearfix {
  clear: both; }

#blog_post ul.article-columnized-list {
  list-style: none;
  margin-left: 0;
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3; }
  @media only screen and (max-width: 900px) {
    #blog_post ul.article-columnized-list {
      -webkit-column-count: 2;
      -moz-column-count: 2;
      column-count: 2; } }
  @media only screen and (max-width: 600px) {
    #blog_post ul.article-columnized-list {
      -webkit-column-count: 1;
      -moz-column-count: 1;
      column-count: 1; } }

#blog_post ul.article-checkbox-list {
  list-style: none;
  margin-left: 0;
  -webkit-column-count: 2;
  -moz-column-count: 2;
  column-count: 2; }
  @media only screen and (max-width: 900px) {
    #blog_post ul.article-checkbox-list {
      -webkit-column-count: 1;
      -moz-column-count: 1;
      column-count: 1; } }
  #blog_post ul.article-checkbox-list li {
    position: relative;
    padding-left: 20px;
    font: 400 16px/18px "Oswald", sans-serif;
    margin-bottom: 18px; }
    #blog_post ul.article-checkbox-list li i.fa {
      position: absolute;
      left: 0px;
      font-style: normal;
      line-height: 18px; }

#blog_post ul.article-qna-list {
  list-style: none;
  margin-left: 0; }
  #blog_post ul.article-qna-list li {
    position: relative;
    padding-left: 20px; }
    #blog_post ul.article-qna-list li.qna-q {
      margin-bottom: 0px; }
      #blog_post ul.article-qna-list li.qna-q span {
        font: 400 16px/18px "Oswald", sans-serif; }
    #blog_post ul.article-qna-list li.qna-a {
      margin-bottom: 24px; }
    #blog_post ul.article-qna-list li i.letter {
      position: absolute;
      left: 0px;
      font-style: normal;
      line-height: 24px;
      font-size: 16px;
      color: #1ab1f9; }

#blog_post .quoteBlock {
  max-width: 600px;
  padding: 30px 30px 0;
  margin: 0 auto 30px;
  position: relative; }
  #blog_post .quoteBlock .quoteBlock-open, #blog_post .quoteBlock .quoteBlock-close {
    position: absolute;
    height: 30px;
    width: 50px;
    opacity: 0.25;
    font-size: 50px; }
  #blog_post .quoteBlock .quoteBlock-open {
    top: 0;
    left: 0; }
  #blog_post .quoteBlock .quoteBlock-close {
    bottom: 0;
    right: 0; }
  #blog_post .quoteBlock .quoteBlock-quote {
    font: italic 300 15px/21px "Open Sans", sans-serif;
    margin-bottom: 6px; }
  #blog_post .quoteBlock .quoteBlock-author {
    text-align: right;
    font: 700 14px/18px "Open Sans", sans-serif; }

#blog_post .benefit {
  width: 46%;
  height: 264px;
  margin: 2%; }
  @media screen and (max-width: 750px) {
    #blog_post .benefit {
      width: 46%;
      margin: 4% 2%; } }
  @media screen and (max-width: 450px) {
    #blog_post .benefit {
      width: 98%;
      margin: 4% 1%; } }

/*----------------------------------------------------------------------------*/
/*	AD STYLING
/*----------------------------------------------------------------------------*/
.main-content-wrap {
  max-width: 1200px;
  margin: 0 auto; }
  .main-content-wrap .main-content {
    display: flex; }
    @media only screen and (max-width: 768px) {
      .main-content-wrap .main-content {
        display: block; } }
  .main-content-wrap .place-right-wrap {
    flex-basis: 300px; }
    @media only screen and (max-width: 768px) {
      .main-content-wrap .place-right-wrap {
        flex-basis: auto;
        width: 300px;
        margin: 24px auto; } }
    .main-content-wrap .place-right-wrap .place-right-big {
      height: 600px;
      width: 300px;
      margin: 0px auto 24px;
      background-color: #eee; }
    .main-content-wrap .place-right-wrap .place-right-small {
      height: 250px;
      width: 300px;
      margin: 0px auto 24px;
      background-color: #eee; }

.place-top {
  height: 90px;
  width: 728px;
  margin: 24px auto;
  background-color: #eee; }
  @media all and (max-width: 728px) {
    .place-top {
      display: none; } }

.place-bottom {
  height: 90px;
  width: 728px;
  margin: 24px auto;
  background-color: #eee; }
  @media all and (max-width: 728px) {
    .place-bottom {
      display: none; } }
