/*----------------------------------------------------------------------------*/
/*	FORM STYLING
/*----------------------------------------------------------------------------*/
form {
	ul {
		list-style: none;
		margin: 0;
	}
	label {
		font: $fnt_body_weight 16px/21px $fnt_body;
		letter-spacing: .025em;
		color: $clr_black;
		text-align: left;
	}
	input, textarea {
		width: 100%;
		height: 40px;
		box-sizing: border-box;
		vertical-align: top;
		border: 1px solid $clr_grey2;
		border-radius: 0;
		padding: 0 12px;
		background: rgba(255,255,255,1);
		font: $fnt_body_weight 16px/40px $fnt_body;
		letter-spacing: .05em;
		color: $clr_black;
		text-align: left;
		-webkit-transition: all .2s ease;
		transition: all .2s ease;
		margin-bottom: 12px;
	}
	textarea {
		height: auto;
		font: $fnt_body_weight 16px/21px $fnt_body;
		letter-spacing: .0em;
		padding: 12px 12px;
	}
	select {
		width: 100%;
		height: 40px;
		box-sizing: border-box;
		vertical-align: top;
		border: 1px solid $clr_grey2;
		padding: 0 18px;
		background: rgba(255,255,255,1);
		background-image: url('img/icon-select-64.png');
		background-position: 95% center;
		background-repeat: no-repeat;
		background-size: 16px 16px;
		font: $fnt_body_weight 16px/40px $fnt_body;
		letter-spacing: .05em;
		color: $clr_grey3;
		text-align: left;
		-webkit-transition: all .2s ease;
		transition: all .2s ease;
	}
	input[type="checkbox"] {
		height: 12px;
		width: 12px;
		border: 1px solid $clr_grey2;
		background-color: $clr_white;
		padding: 0;
	}
	.frm_checkbox {
		label {
			padding-left: 21px;
			position: relative;
			input {
				visibility: hidden;
				height: 0;
				width: 0;
				border: none;
				margin: 0;
			}
			&::after {
				content: "";
				position: absolute;
				top: 5px;
				left: 2px;
				height: 12px;
				width: 12px;
				border: 1px solid $clr_grey2;
				background-color: transparent;
				-webkit-transition: all .2s ease;
				transition: all .2s ease;
			}
			&.selected::after {
				background-color: $clr_pri1;
			}
		}
	}
	input[type=submit] {
		width: auto;
		border: 1px solid $clr_pri1;
		background: $clr_pri1;
		color: $clr_white;
		cursor: pointer;
		text-align: center;
		text-transform: uppercase;
		&:hover {
			border: 1px solid $clr_pri1;
			background: $clr_white;
			color: $clr_pri1;
		}
	}
}
form ::-webkit-input-placeholder {
	color: $clr_grey3;
	letter-spacing: 0.05em;
}
form :-moz-placeholder {
	color: $clr_grey3;
	letter-spacing: 0.05em;
}
form ::-moz-placeholder {
	color: $clr_grey3;
	letter-spacing: 0.05em;
}
form :-ms-input-placeholder {
	color: $clr_grey3;
	letter-spacing: 0.05em;
}
form .placeholdersjs {
	color: $clr_grey3;
}
form li.gfield {
	margin-bottom: 18px;
	&.hidden_label label {
		display: none;
	}
}
form li.gfield_error {
	input, select, textarea {
		background: rgba(200,0,0,0.1);
	}
	.gfield_description.validation_message {
		font: $fnt_body_weight 12px/16px $fnt_body;
		color: #a44;
	}
}
.gform_wrapper {
	max-width: 600px;
}
.passform {
	max-width: 300px;
	input {
		margin-bottom: 12px;
	}
}





#booklet-form {
	padding:9px 0 7px;
	max-width:669px;
	margin:0 auto 24px;
	position: relative;
	.radios {
		color: $clr_white;
		font: $fnt_body_weight 14px/21px $fnt_body;
		text-align: center;
		margin-bottom: 12px;
		input[type='radio'] {
			width: auto;
			height: auto;
			float: none;
			display: inline-block;
			margin: 0 12px;
			vertical-align: baseline;
		}
	}
	input {
		height: 45px;
		width: 27.3%;
		border: 1px solid $clr_grey1;
		background: $clr_grey1;
		color: $clr_text;
		padding: 0 16px;
		box-sizing: border-box;
		float: left;
		margin-right: 2px;
		font: $fnt_body_weight 16px/45px $fnt_body;
		&:focus {
			background-color: $clr_white;
			color: $clr_text;
			outline: 0;
		}
		@media screen and (max-width: $brk_mobile) {
			width: 100%;
			margin-bottom: 6px;
		}
	}
	input[type='submit'] {
		width: 16.9%;
		border: 1px solid $clr_pri1;
		background-color: $clr_pri1;
		color: $clr_white;
		cursor: pointer;
		font: $fnt_body_weight 16px/45px $fnt_body;
		letter-spacing: .05em;
		padding: 0;
		margin: 0;
		text-align: center;
		-webkit-transition: all 200ms ease;
		transition: all 200ms ease;
		&:hover {
			background-color: $clr_white;
			color: $clr_pri1;
			-webkit-transition: all 200ms ease;
			transition: all 200ms ease;
		}
		@media screen and (max-width: $brk_mobile) {
			width: 100px;
			margin-bottom: 6px;
		}
	}
	.thank {
		visibility: hidden;
		opacity:0;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		padding: 0;
		border: 2px solid $clr_sec1;
		box-sizing: border-box;
		color: $clr_grey4;
		background-color: $clr_white;
		text-align: center;
		font-size: 14px;
		line-height: 41px;
		letter-spacing: 0.8px;
		-webkit-transition: visibility 0ms linear 200ms, opacity 200ms linear;
				transition: visibility 0ms linear 200ms, opacity 200ms linear;
		div {
			//padding-right: 135px;
			@media screen and (max-width: $brk_mobile) {
				background-color: $clr_white;
				padding-bottom: 118px;
				margin: -3px -3px;
			}
		}
		.thank-close {
			display: block;
			position: absolute;
			top: 0;
			right: 0;
			height: 30px;
			width: 30px;
			background-color: $clr_white;
			color: $clr_pri1;
			box-sizing: border-box;
			line-height: 30px;
			font-size: 16px;
			letter-spacing: 0;
			text-transform: uppercase;
			text-decoration: none;
			-webkit-transition: all 200ms ease;
			transition: all 200ms ease;
			&:hover {
				background-color: $clr_pri1;
				color: $clr_white;
			}
		}
	}
	@include input-placeholder($clr_grey3, uppercase);
	@include input-placeholder-focus($clr_grey4);
	@include textarea-placeholder-focus($clr_grey4);
	.placeholdersjs {color: $clr_grey2;}
}
#newsletter-form.show-thank .thank {
	visibility: visible;
	opacity:1;
	transition-delay:0s;
}
